
import { defineComponent } from "@vue/runtime-core"
import { renderForm, submitForm } from "@/api/formRenderer"
import { FormRenderer } from "@/models/render/FormRenderer"
import WSpinner from "@/components/bases/misc/WSpinner.vue"
import { LooseObject } from "@/models/LooseObject"
import { WDialog } from "@/components/bases/modals"

export default defineComponent({
  props: {
    isNewDoc: Boolean,
    templateId: String,
  },
  data() {
    return {
      showDialog: true,

      errorMessage: "Page not found!" as string,
      errorTitle: "404" as string,
      errorIcon: "cancel" as string,
      errorMode: "error" as string,
    }
  },
  components: { WDialog },
})
