import { sharedPanels } from './panels'

const panels = sharedPanels()

const defaults = [
  panels.panelTop, 
  panels.basicActions, 
  panels.panelDevices, 
  panels.panelToolbar, 
  panels.panelSwitcher, 
  panels.panelRight
]

export default {
  defaults: defaults
}
