<template>
  <div class="relative">
    <label v-if="label" :for="_id" class="block mb-1 text-sm font-medium"
      >{{ label }}
      <span v-if="optional" class="text-xs italic font-normal text-gray-500"
        >・ Optional</span
      ></label
    >
    <div
      class="flex items-center w-full pl-3 border rounded-md focus-within:border-primary-700 focus-within:ring-primary-700 focus-within:ring-1"
      :class="classes"
    >
      <span class="mr-1 select-none" v-if="prefix">{{ prefix }}</span>
      <input
        :id="_id"
        :type="inputType"
        :autocomplete="autocomplete ? 'on' : 'off'"
        :disabled="disabled"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        ref="input"
        class="flex-1 w-full pl-0 bg-transparent border-none focus:border-none focus:ring-0"
      />

      <w-icon-button
        v-if="type === 'password'"
        @click="switchPasswordVisibility"
        small
        class="absolute w-5 bg-white right-3 top-8"
      >
        {{ inputType === "password" ? "visibility" : "visibility_off" }}</w-icon-button
      >
    </div>
    <div v-if="errorMessage" class="mt-1 text-xs text-right text-primary-900">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>

import WIconButton from "@/components/bases/buttons/WIconButton.vue"

export default {
  emits: ["update:modelValue", "insertText"],

  props: {
    focus: Boolean,
    textInsert: String,
    modelValue: String,
    placeholder: String,
    prefix: String,
    errorMessage: String,
    id: String,
    label: String,
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    focus: function() {
      if (this.focus) {
        const input = this.$refs.input
        input.focus()
        let startPos = input.selectionStart;
        let value = input.value;
        const newValue = value.substring(0, startPos) + this.textInsert + value.substring(startPos, value.length)
        // input.value = newValue
        this.$emit("insertText", newValue)
      } 
    }
  },

  data() {
    return {
      inputType: this.type,
    }
  },

  methods: {
    switchPasswordVisibility() {
      this.inputType = this.inputType === "password" ? "text" : "password"
    },
  },

  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
    classes() {
      return {
        "bg-primaryGrapesJs-600 text-primaryGrapesJs-200": this.disabled,
        "bg-primaryGrapesJs-900 text-primaryGrapesJs-100": !this.disabled,
        "border-green-700": this.highlighted && !this.disabled,
        "border-green-900": this.highlighted && this.disabled,
        "border-primaryGrapesJs-100": !this.highlighted && !this.disabled,
        "border-primaryGrapesJs-200": !this.highlighted && this.disabled
      }
    },
  },

  components: { WIconButton },
}
</script>
<style scoped>
input::-webkit-textfield-decoration-container {
  visibility: hidden;
}
</style>
