
enum TriggerType {
  http,
  sftp,
  email
}

export default class RoutingKey {

  private _id: string | null
  get id(): string | null {
    return this._id
  }

  readonly flowId: string
  readonly flowEnvironmentId: string
  readonly apiKeyId: string | null
  readonly apiKeyKey: string | null

  readonly key: string | null
  readonly type: TriggerType
  description: string

  constructor(params: New<RoutingKey>) {
    this._id = null
    this.flowId = params.flowId!
    this.flowEnvironmentId = params.flowEnvironmentId!
    this.apiKeyId = params.apiKeyId ?? null
    this.apiKeyKey = params.apiKeyKey ?? null
    this.key = params.key ?? null
    this.type = params.type!
    this.description = params.description!
  }

  static fromResponse(data: any) {
    const routingKey = new RoutingKey({
      flowId: data.idflow,
      flowEnvironmentId: data.idflow_environment,
      apiKeyId: data.idapi_key,
      apiKeyKey: data.api_key.key,
      description: data.description,
      type: data.type,
      key: data.key
    })
    routingKey._id = data.idrouting_key
    return routingKey
  }

  oDataJson(): any {
    const json = {
      idflow: `flow(${this.flowId})`,
      idflow_environment: `flow_environment(${this.flowEnvironmentId})`,
      type: this.type,
      description: this.description
    } as any
    if (this.type == 0 && this.apiKeyId) {
      json.idapi_key = `api_key(${this.apiKeyId})`
    }
    if (this.key) {
      json.key = this.key
    }
    return json
  }
}