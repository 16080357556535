<template>
  <w-selector 
    v-if="operatorType && (operatorType == `numeric` || operatorType == `dateTime`)"
    v-model="modifierModel.operator" 
    class="w-16" 
    :items="modifierOperators" 
    itemKey="value" 
    itemText="name"
  ></w-selector>
  <w-text-field 
    v-model="modifierModel.value"
    class="w-48" 
    type="number"
    placeholder="Modifier value" 
  ></w-text-field>
  <w-selector 
    v-if="operatorType && operatorType == `dateTime`"
    v-model="modifierModel.unit" 
    class="w-24" 
    :items="modifierUnits" 
    itemKey="value" 
    itemText="name"
  ></w-selector>
</template>

<script>

import { WSelector, WTextField } from "@/components/bases/form"

export default {
  props: {
    modelValue: Object,
    operatorType: String
  },
  data() {
    return {
      modifierOperators: [
        { value: "plus", name: "+" },
        { value: "minus", name: "-" },
        { value: "times", name: "×" },
        { value: "divided_by", name: "÷" },
      ],
      modifierUnits: [
        { value: 86400, name: "days" },
        { value: 3600, name: "hours" },
        { value: 60, name: "minutes" },
        { value: 1, name: "seconds" },
      ],
    }
  },
  computed: {
    modifierModel: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) },
    },
  },
  components: { WSelector, WTextField }
}
</script>