<template>
  <div>
    <w-bar dense :indent="false">
      <template #left>
        <span 
        style="font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif" 
        class="text-xs font-medium text-primaryGrapesJs-100"
        >{{ title }}
        </span>
        <slot name="bar.left" />
      </template>
    </w-bar>
    <slot />
  </div>
</template>

<script>

import WBar from "./bars/WBar.vue"

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },

  components: {
    WBar,
  },
}
</script>
