
import { defineComponent } from "vue"

import WTableDialog from "../bases/modals/WTableDialog.vue"

import Flow from "../../models/flow/Flow"
import RoutingKey from "../../models/flow/RoutingKey"

import FlowTable from "../flow/FlowTable.vue"
import RoutingKeyTable from "../flow/RoutingKeyTable.vue"

import { WButton } from "@/components/bases/buttons"

export default defineComponent({
  emits: ['linkCreated'],
  data() {
    return {
      filters: { routingKeysType: 0 },
      currentStep: 0,
      routingKeys: null as null | RoutingKey[],
      getFlowsError: null as null | string,
      getRoutingKeysError: null as null | string,
      isLoading: false,
    }
  },
  methods: {
    async selectFlow(flow: Flow) {
      try {
        this.getFlowsError = null
        this.isLoading = true
        this.routingKeys = flow.routingKeys!.filter((each) => each.type == 0)
        this.currentStep = 1
      } catch (error: any) {
        this.getRoutingKeysError = error.message
      } finally {
        this.isLoading = false
      }
    },
    cancelRoutingKeySelection() {
      this.getRoutingKeysError = null
      this.currentStep = 0
    },
    async selectRoutingKey(routingKey: RoutingKey) {
      const endpoint = process.env.VUE_APP_ENDPOINT as string
      const link = endpoint + `/job?apikey=${routingKey.apiKeyKey}&routingkey=${routingKey.key}`
      this.$emit('linkCreated', link)
      this.currentStep = 0
    },
  },
  components: { WTableDialog, WButton, FlowTable, RoutingKeyTable },
})
