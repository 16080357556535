<template>
  <div class="flex">
    <w-vertical-divider :text="isElseIfBlock ? 'Else If' : 'If'"></w-vertical-divider>
    <div>
      <filter-section v-model="blockModel.filter" :variableOptions="variableOptions"></filter-section>
      <statement-row v-model="blockModel.statement" :variableOptions="variableOptions"></statement-row>
      <div class="flex py-4 space-x-2">
        <w-button small @click="addElseIf">+ Else If</w-button>
        <w-button v-if="blockModel.elseStatement == null && !blockModel.elseIfBlock" small @click="addElse">+ Else</w-button>
      </div>
    </div>
  </div>
  <div>
    <w-horizontal-divider class="before:border-primary-700 after:border-primary-700"></w-horizontal-divider>
    <div class="flex justify-end mt-2 place-items-center text-primaryGrapesJs-100">
      <w-icon-button v-if="blockModel.elseStatement != null" mode="destructive" small @click="removeElse">cancel</w-icon-button>
      <w-icon-button v-if="blockModel.elseIfBlock" mode="destructive" small @click="removeElseIf">cancel</w-icon-button>
    </div>
    <if-control-section isElseIfBlock v-if="blockModel.elseIfBlock" :block="blockModel.elseIfBlock" :variableOptions="variableOptions"></if-control-section>
    <else-row v-if="blockModel.elseStatement != null" v-model="blockModel.elseStatement"></else-row>
  </div>
</template>

<script>
import FilterSection from "./FilterSection.vue"
import StatementRow from "./StatementRow.vue"
import ElseRow from "./ElseRow.vue"
import WHorizontalDivider from "./WHorizontalDivider.vue"
import WVerticalDivider from "./WVerticalDivider.vue"
import WButton from "@/components/bases/buttons/WButton.vue"
import WIconButton from "@/components/bases/buttons/WIconButton.vue"
import IfControlBlock from "./models/IfControlBlock"

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    variableOptions: {
      type: Array,
      required: true,
    },
    isElseIfBlock: {
      type: Boolean,
    },
  },
  computed: {
    blockModel: {
      get () { return this.block },
      set (value) { this.$emit('update:block', value) },
    },
  },
  methods: {
    addElseIf() {
      this.blockModel.elseIfBlock = IfControlBlock.create((this.blockModel.elseIfBlock ? this.blockModel.elseIfBlock : null))
    },
    addElse() {
      this.blockModel.elseStatement = ""
    },
    removeElseIf() {
      this.blockModel.elseIfBlock.elseIfBlock 
      ? this.blockModel.elseIfBlock = this.blockModel.elseIfBlock.elseIfBlock
      : delete this.blockModel.elseIfBlock
    },
    removeElse() {
      delete this.blockModel.elseStatement
    }
  },
  components: { FilterSection, StatementRow, ElseRow, WHorizontalDivider, WVerticalDivider, WButton, WIconButton },
}
</script>
