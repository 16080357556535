<template>
  <button
    class="aspect-square focus:outline-none focus:text-primary-700"
    :class="classes"
    :disabled="disabled"
    @click.prevent="$emit('click')"
  >
    <w-icon :small="small" :outlined="outlined">
      <slot />
    </w-icon>
  </button>
</template>

<script>
import WIcon from "../WIcon.vue"

export default {
  emits: ["click"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    outlined: Boolean,
  },
  computed: {
    classes() {
      return {
        [`${this.small ? "h-7" : "h-10"}`]: true,
        "hover:text-white": !this.disabled,
        
        // "text-white bg-primary-900": this.mode == "primary",
        // "text-black bg-gray-100": this.mode == "secondary",
        // "hover:bg-gray-200": this.mode == "secondary" && !this.disabled,
        // "text-black": this.mode == "plain",
        "opacity-50": this.disabled,
        "cursor-default": this.disabled,
      }
    },
  },
  components: { WIcon },
}
</script>
