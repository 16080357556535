export class FormRenderer {
    title: string | null
    html: string | null
    
    constructor(params: New<FormRenderer>) {
        this.title = params.title ?? null
        this.html = params.html ?? null
    }
  
    static fromResponse(data: any): FormRenderer {
        const render = new FormRenderer({
            title: data.title,
            html: data.html,
        })
        return render
    }
}