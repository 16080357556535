
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    id: String,
    label: String,
    errorMessage: String,
    hint: String,
    plain: Boolean,
    optional: Boolean,
    disabled: Boolean,
  },
  computed: {
    classes() {
      return {
        "bg-primaryGrapesJs-600 text-primaryGrapesJs-200 cursor-default border border-primaryGrapesJs-200 rounded-lg": this.disabled,
        "bg-primaryGrapesJs-900 text-primaryGrapesJs-100 cursor-pointer border border-primaryGrapesJs-300 rounded-lg focus-within:border-primary-700": !this.disabled,
        "border-none focus-within:ring-0 focus-within:border-none focus-within:border-none": this.plain,
      }
    },
  },
})
