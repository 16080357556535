export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameForEachBase
  const label = options.labelForEachBase
  const extendName = "default"

  domComponents.addType(name, {
    extend: extendName,
    isComponent: (el) => el.tagName == "FOREACHBASE",
    model: {
      defaults: {
        name: label,
        // tagName: "div",
        variableText: null,
        forEachGroup: null,
        isNew: true,
        isEmpty: true,
      },
    },
  })
}
