<template>
  <div class="flex space-x-2 text-sm">
    <w-date-picker
      v-if="operatorType == `dateTime`"
      v-model="rhsOperandModel.value"
      placeholder="Date"
      zIndex="z-0"
      is-dark
      color="red"
    ></w-date-picker>
    <w-selector 
      v-if="operatorType == `boolean`"
      v-model="rhsOperandModel.value" 
      class="w-48" :items="booleanOptions" 
      itemKey="value" 
      itemText="name"
    ></w-selector>
    <w-text-field 
      v-if="operatorType == `text`" 
      class="w-64" 
      placeholder="text" 
      v-model="rhsOperandModel.value"
    ></w-text-field>
    <w-text-field 
      v-if="operatorType == `numeric`" 
      class="w-64" 
      placeholder="value" 
      v-model="rhsOperandModel.value"
    ></w-text-field>
    <div class="flex-grow"></div>
  </div>
</template>

<script>

import { WSelector, WTextField, WDatePicker } from "@/components/bases/form"

export default {
  props: {
    modelValue: Object,
    operatorType: String
  },
  data() {
    return {
      // operatorType: this.operand.type, // text, numeric, date, boolean      
      // operandValue: this.operand.value,

      booleanOptions: [
        { value: "1", name: "true" },
        { value: "0", name: "false" },
      ],
    }
  },
  computed: {
    rhsOperandModel: {
      get () { 
        console.log(this.modelValue)
        return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) },
    },
  },
  components: { WSelector, WTextField, WDatePicker }
}
</script>