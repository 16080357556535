<template>
  <w-horizontal-divider class="text-green-700 before:border-green-700 after:border-green-700" text="then"></w-horizontal-divider>
  <div class="flex flex-row space-x-2 place-items-center">
    <w-text-field 
      @insertText="insertDisplayText" 
      :focus="this.displayTextFocus" 
      :textInsert="displayTextInsert" 
      highlighted 
      class="flex-1 text-sm"
      v-model="statementModel"
    ></w-text-field>
    <div v-if="showAddVariable" class="flex flex-row space-x-2 place-items-center">
      <span class="text-sm text-gray-400">Variable:</span>
      <w-selector 
        v-model="selectedVariable"
        class="w-64 text-sm" 
        :items="variableOptions" 
        itemKey="path" 
        itemText="displayName"
      ></w-selector>
      <span class="text-sm text-gray-400">Case:</span>
      <w-selector 
        v-model="selectedCase"
        class="w-32 text-sm" 
        :items="caseOptions" 
        itemKey="value" 
        itemText="name"
      ></w-selector>
      <w-button @click="insertVariable">Insert</w-button>
    </div>
    <w-button @click="showAddVariable = !showAddVariable">{{ showAddVariable ? "<" : "> Add Variable"}}</w-button>
  </div>
</template>

<script>

import { WTextField, WSelector } from "@/components/bases/form"
import { WButton } from "@/components/bases/buttons"
import WHorizontalDivider from "./WHorizontalDivider.vue"

export default {
  props: {
    modelValue: String,
    variableOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    statementModel: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) },
    },
  },
  data() {
    return {
      showAddVariable: false,
      selectedVariable: null,

      variableTextToInsert: null,
      displayTextFocus: false,
      displayTextInsert: null,

      selectedCase: { name: "default", value: "" },
      caseOptions: [
        { name: "default", value: "" },
        { name: "UPPER", value: "| upcase " },
        { name: "lower", value: "| downcase " },
        { name: "Camel Case", value: "| camelcase " },
        { name: "Sentence case", value: "| capitalize " },
      ]
    }
  },
  methods: {
    insertVariable() {
      if (!this.selectedVariable) {
        return
      }
      this.displayTextInsert = `{{ ${this.selectedVariable.path} ${this.selectedCase.value} }}` // Convert to liquid format
      this.displayTextFocus = true
    },
    insertDisplayText(text) {
      this.statementModel = text
      this.displayTextFocus = false
      this.displayTextInsert = null
    },
  },
  components: { WTextField, WHorizontalDivider, WButton, WSelector }
}
</script>