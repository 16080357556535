export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameAdvancedTableBase
  const label = options.labelAdvancedTableBase

  domComponents.addType(name, {
    extend: "default",
    isComponent: (el) => el.tagName == "ADVANCEDTABLEBASE",
    model: {
      defaults: {
        name: label,
        isEmpty: true,
      },
    },
  })
}
