import loadTemplate from "./loadTemplate"
import openImport from "./openImport"
import openSave from "./openSave"
import openOpen from "./openOpen"
import openCreateBlank from "./openCreateBlank"
import openSetSampleData from "./openSetSampleData"
import advancedTableCommands from "./advancedTable"

export default (editor, options) => {
  editor.Commands.add("gjs-load-template", loadTemplate(editor, options))
  editor.Commands.add("gjs-open-import", openImport(editor, options))
  editor.Commands.add("gjs-open-save", openSave(editor, options))
  editor.Commands.add("gjs-open-open", openOpen(editor, options))
  editor.Commands.add("gjs-open-create-blank", openCreateBlank(editor, options))
  editor.Commands.add("gjs-open-set-sample-data", openSetSampleData(editor, options))
  advancedTableCommands(editor, options.advancedBlocksOpts)
}
