
import { defineComponent } from "vue"
import { getFlows } from "@/api/flow"
import Flow from "@/models/flow/Flow"
import WDataTable from "@/components/bases/tables/WDataTable.vue"
import { WTextField } from "@/components/bases/form"
import WSticker from "@/components/bases/misc/WSticker.vue"

export default defineComponent({
  emits: ["clickRow"],
  props: {
    filters: Object
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true, filterable: true, width: 4 },
        { text: "Active", value: "status", width: 1 },
        { text: "Flow ID", value: "id", sortable: true, width: 4 },
        { text: "Priority", value: "priority", width: 1 },
      ],
      flows: [] as Flow[],
      fetchErrorMessage: null as null | string,
    }
  },
  mounted() {
    this.fetchFlows()
  },
  methods: {
    rowClicked(item: Flow) {
      this.$emit('clickRow', item)
    },
    async fetchFlows() {
      try {
        this.flows = this.filters ? await getFlows(this.filters) : await getFlows()
      } catch (error: any) {
        this.fetchErrorMessage = error.message
      }
    },
    getPriorityColor(priority: string) {
      if (Number(priority) <= 3) return `green`
      else if (Number(priority) <= 6) return `orange`
      else if (Number(priority) <= 10) return `red`
      else return `blue`
    },
  },
  components: {
    WDataTable,
    WTextField,
    WSticker,
  },
})
