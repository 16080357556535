
import { defineComponent } from "vue"
import WTextSkeleton from "./WTextSkeleton.vue"

export default defineComponent({
  components: { WTextSkeleton },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      widths: ["w-4/12", "w-1/12", "w-2/12", "w-3/12", "w-2/12"],
    }
  },
})
