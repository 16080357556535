<template>
  <w-section title="Conditionals:">
    <div class="space-y-2">
      <w-button class="w-full" @click="openConditionsDialog">Open Conditionals</w-button>
      <div class="space-y-2">
        <w-button 
          v-for="(text, index) in ifControlBlock.displayTexts(ifControlBlock)" 
          mode="secondary" 
          :key="text" 
          @click="selectCondition(index)" 
          class="w-full text-[12px]" 
          :selected="conditionIsSelected(index)"
        >{{ text }}</w-button>
      </div>
    </div>
  </w-section>
  <div v-show="showConditionsDialog" class="fixed inset-0 bg-black bg-opacity-60"/>
  <w-dialog 
    class="bg-primaryGrapesJs-900" 
    v-model="showConditionsDialog" 
    title="Insert Conditional Text" 
    showCloseButton
    persistent
    @closed="dialogNeedsClose"
  >
    <conditionals-if-form 
      :variables="variablesArray"
      :block="ifControlBlock"
      :componentType="componentType"
      @applyIfCondition="applyIfCondition" 
      @cancel="cancelConditionals"
    ></conditionals-if-form>
  </w-dialog>
</template>

<script>
import WSection from "@/components/bases/WSection.vue"
import ConditionalsIfForm from "./ConditionalsIfForm.vue"
import { WDialog } from "@/components/bases/modals"
import { WButton } from "@/components/bases/buttons"

export default {
  emits: ["applyCondition", "selectCondition", "dialogCancelled", "dialogClosed"],

  props: {
    componentType: {
      type: String
    },
    ifControlBlock: {
      type: Object
    },
    selectedConditionIndex: {
      type: Number
    },
    showDialog: {
      type: Boolean
    },
    variables: {
      type: Array
    },
  },

  watch: {
    showDialog: function() {
      this.openConditionsDialog()
    },
    variables: function() {
      this.variablesArray = this.variables
    }
  },

  data() {
    return {
      showConditionsDialog: false,
      selectedButtonIndex: this.selectedConditionIndex,
      variablesArray: this.variables,
    }
  },

  computed: {
    dialogTitle() {
      return this.conditionalType == 'conditional-text' ? "Insert Conditional Text:" : "Conditional Table Component:"
    },
  },
  methods: {
    openConditionsDialog() {
      this.showConditionsDialog = true
    },
    applyIfCondition(ifControlBlock) {
      this.$emit("applyCondition", ifControlBlock)
      this.selectCondition(0)
      this.showConditionsDialog = false
    },
    selectCondition(index) {
      this.selectedButtonIndex = index
      this.$emit("selectCondition", this.selectedButtonIndex)
    },
    conditionIsSelected(index) {
      return index === this.selectedButtonIndex
    },
    cancelConditionals() {
      this.showConditionsDialog = false
      this.$emit("dialogCancelled")
    },
    dialogNeedsClose() {
      this.$emit("dialogClosed")
    }
  },

  components: {
    WSection,
    ConditionalsIfForm,
    WDialog,
    WButton,
  },
}
</script>
