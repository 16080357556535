export default function (editor, opt = {}) {
  const options = opt;
  let bm = editor.BlockManager;
  let blocks = options.blocks;
  let stylePrefix = options.stylePrefix;
  const flexGrid = options.flexGrid;
  const basicStyle = options.addBasicStyle;
  const clsRow = `${stylePrefix}row`;
  const clsCell = `${stylePrefix}cell`;
  const styleRow = flexGrid ? `
    .${clsRow} {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      padding: 10px;
    }
    @media (max-width: 768px) {
      .${clsRow} {
        flex-wrap: wrap;
      }
    }` : `
    .${clsRow} {
      display: table;
      padding: 10px;
      width: 100%;
    }`;
  const styleClm = flexGrid ? `
    .${clsCell} {
      min-height: 75px;
      flex-grow: 1;
      flex-basis: 100%;
    }` : `
    .${clsCell} {
      width: 8%;
      display: table-cell;
      height: 75px;
    }`;
  const styleClm30 = `
  .${stylePrefix}cell30 {
    width: 30%;
  }`;
  const styleClm70 = `
  .${stylePrefix}cell70 {
    width: 70%;
  }`;

  const step = 0.2;
  const minDim = 1;
  const currentUnit = 1;
  const resizerBtm = { tl: 0, tc: 0, tr: 0, cl: 0, cr:0, bl:0, br: 0, minDim };
  const resizerRight = { ...resizerBtm, cr: 1, bc: 0, currentUnit, minDim, step };

  // Flex elements do not react on width style change therefore I use
  // 'flex-basis' as keyWidth for the resizer on columns
  if (flexGrid) {
    resizerRight.keyWidth = 'flex-basis';
  }

  const rowAttr = {
    class: clsRow,
    'data-gjs-droppable': `.${clsCell}`,
    'data-gjs-resizable': resizerBtm,
    'data-gjs-name': 'Row',
  };

  const colAttr = {
    class: clsCell,
    'data-gjs-draggable': `.${clsRow}`,
    'data-gjs-resizable': resizerRight,
    'data-gjs-name': 'Cell',
  };

  if (flexGrid) {
    colAttr['data-gjs-unstylable'] = ['width'];
    colAttr['data-gjs-stylable-require'] = ['flex-basis'];
  }

  // Make row and column classes private
  const privateCls = [`.${clsRow}`, `.${clsCell}`];
  editor.on('selector:add', selector =>
    privateCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1))

  const attrsToString = attrs => {
    const result = [];

    for (let key in attrs) {
      let value = attrs[key];
      const toParse = value instanceof Array || value instanceof Object;
      value = toParse ? JSON.stringify(value) : value;
      result.push(`${key}=${toParse ? `'${value}'` : `"${value}"`}`);
    }

    return result.length ? ` ${result.join(' ')}` : '';
  }

  const toAdd = name => blocks.indexOf(name) >= 0;
  const attrsRow = attrsToString(rowAttr);
  const attrsCell = attrsToString(colAttr);

  toAdd(options.nameColumn1) && bm.add(options.nameColumn1, {
    label: options.labelColumn1,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-b1'},
    content: `<div ${attrsRow}>
        <div ${attrsCell}></div>
      </div>
      ${ basicStyle ?
        `<style>
          ${styleRow}
          ${styleClm}
        </style>`
        : ''}`
  });

  toAdd(options.nameColumn2) && bm.add(options.nameColumn2, {
    label: options.labelColumn2,
    attributes: {class:'gjs-fonts gjs-f-b2'},
    category: options.category,
    content: `<div ${attrsRow}>
        <div ${attrsCell}></div>
        <div ${attrsCell}></div>
      </div>
      ${ basicStyle ?
        `<style>
          ${styleRow}
          ${styleClm}
        </style>`
        : ''}`
  });

  toAdd(options.nameColumn3) && bm.add(options.nameColumn3, {
    label: options.labelColumn3,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-b3'},
    content: `<div ${attrsRow}>
        <div ${attrsCell}></div>
        <div ${attrsCell}></div>
        <div ${attrsCell}></div>
      </div>
      ${ basicStyle ?
        `<style>
          ${styleRow}
          ${styleClm}
        </style>`
        : ''}`
  });

  toAdd(options.nameColumn37) && bm.add(options.nameColumn37, {
    label: options.labelColumn37,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-b37'},
    content: `<div ${attrsRow}>
        <div ${attrsCell} style="${flexGrid ? 'flex-basis' : 'width'}: 30%;"></div>
        <div ${attrsCell} style="${flexGrid ? 'flex-basis' : 'width'}: 70%;"></div>
      </div>
      ${ basicStyle ?
        `<style>
          ${styleRow}
          ${styleClm}
          ${styleClm30}
          ${styleClm70}
        </style>`
        : ''}`
  });

  toAdd(options.nameText) && bm.add(options.nameText, {
    label: options.labelText,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-text'},
    content: {
      type: options.nameText,
      content:'Insert your text here',
      style: {padding: '10px' },
      activeOnRender: 1
    },
  });

  toAdd(options.nameTextSection) && bm.add(options.nameTextSection, {
    label: options.labelTextSection,
    category: options.category,
    attributes: { class: 'gjs-fonts gjs-f-h1p' },
    content: `<section class="bdg-sect">
      <h1 class="heading">Insert title here</h1>
      <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </section>`
  });

  toAdd(options.nameLink) && bm.add(options.nameLink, {
    label: options.labelLink,
    category: options.category,
    attributes: {class:'fa fa-link'},
    content: {
      type: options.nameLink,
      content:'Link',
      style: {color: '#d983a6'}
    },
  });

  toAdd(options.nameQuote) && bm.add(options.nameQuote, {
    label: options.labelQuote,
    category: options.category,
    attributes: { class: 'fa fa-quote-right' },
    content: `<blockquote class="quote">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit
      </blockquote>`
  });

  toAdd(options.nameImage) && bm.add(options.nameImage, {
    label: options.labelImage,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-image'},
    content: {
      style: {color: 'black'},
      type: options.nameImage,
      activeOnRender: 1
    },
  });

  toAdd(options.nameVideo) && bm.add(options.nameVideo, {
    label: options.labelVideo,
    category: options.category,
    attributes: {class:'fa fa-youtube-play'},
    content: {
      type: options.nameVideo,
      src: 'img/video2.webm',
      style: {
        height: '350px',
        width: '615px',
      }
    },
  });

  toAdd(options.nameButton) && bm.add(options.nameButton, {
    label: options.labelButton,
    category: options.category,
    content: '<a class="button">Button</a>',
    attributes: {class:'gjs-fonts gjs-f-button'}
  });

  toAdd(options.nameDivider) && bm.add(options.nameDivider, {
    label: options.labelDivider,
    category: options.category,
    content: `<table style="width: 100%; margin-top: 10px; margin-bottom: 10px;">
      <tr>
        <td class="divider"></td>
      </tr>
    </table>
    <style>
    .divider {
      background-color: rgba(0, 0, 0, 0.1);
      height: 1px;
    }
    </style>`,
    attributes: {class:'gjs-fonts gjs-f-divider'}
  });

  let gridItem =
      `<table class="grid-item-card">
        <tr>
          <td class="grid-item-card-cell">
            <img class="grid-item-image" src="http://placehold.it/250x150/78c5d6/fff/" alt="Image"/>
            <table class="grid-item-card-body">
              <tr>
                <td class="grid-item-card-content">
                  <h1 class="card-title">Title here</h1>
                  <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>`;

  toAdd(options.nameGridItems) && bm.add(options.nameGridItems, {
    label: options.labelGridItems,
    category: options.category,
    content: `<table class="grid-item-row">
      <tr>
        <td class="grid-item-cell2-l">${gridItem}</td>
        <td class="grid-item-cell2-r">${gridItem}</td>
      </tr>
    </table>`,
    attributes: {class:'fa fa-th'}
  });

  let listItem =
  `<table class="list-item">
    <tr>
      <td class="list-item-cell">
        <table class="list-item-content">
          <tr class="list-item-row">
            <td class="list-cell-left">
              <img class="list-item-image" src="http://placehold.it/150x150/78c5d6/fff/" alt="Image"/>
            </td>
            <td class="list-cell-right">
              <h1 class="card-title">Title here</h1>
              <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>`;

  toAdd(options.nameListItems) && bm.add(options.nameListItems, {
    label: options.labelListItems,
    category: options.category,
    content: listItem + listItem,
    attributes: {class:'fa fa-th-list'}
  });


  toAdd(options.nameMap) && bm.add(options.nameMap, {
    label: options.labelMap,
    category: options.category,
    attributes: {class:'fa fa-map-o'},
    content: {
      type: options.nameMap,
      style: {height: '350px'}
    },
  });
}
