export default class RhsOperand {
  value

  constructor(value) {
    this.value = value
  }

  static decode(rhsOperand, operatorType) {
    switch (operatorType) {
      case "dateTime":
        return new RhsOperand(new Date(rhsOperand.value))
      case "numeric" :
        return new RhsOperand(rhsOperand.value)
      default:
        return new RhsOperand(rhsOperand.value)
    }
  }
}