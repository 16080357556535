import Condition from "./Condition"

export default class Filter {
  filters // array of Filter objects | null
  conditions // array of Condition objects | null
  logicalOperator // and / or | null

  constructor(filters, conditions, logicalOperator) {
    this.filters = filters
    this.conditions = conditions
    this.logicalOperator = logicalOperator
  }  

  static create(logicalOperator, withConditions) {
    return new Filter([], withConditions ? withConditions : [Condition.create()], logicalOperator)
  }

  static decode(filter) {
    return new Filter(
      filter.filters.map(filter => this.decode(filter)), 
      filter.conditions.map(condition => Condition.decode(condition)), 
      filter.logicalOperator
    )
  }

  displayText(filterBlock) {
    const filters = filterBlock.filters.map(filter => "(" + filter.displayText(filter) + ")")
    const conditions = filterBlock.conditions.map(condition => condition.displayText(condition))
    return [...filters, ...conditions].join(" " + filterBlock.logicalOperator + " ")
  }

  needsTestVariables(filter) {
    return (filter.logicalOperator || filter.filters)
  }

}
