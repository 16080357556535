
export function apiError(error: any, errorMessages: Record<number, string>): ApiError {
    if (!error.response) {
      if (!navigator.onLine) {
        return ApiError.noNetworkConnection()
      }
      return new ApiError(false, "Something went wrong", null, null, null)
    }
    const apiError = ApiError.fromResponse(error.response)
    const message = errorMessages[apiError.serverCode!] ?? errorMessages[apiError.httpCode!]
    if (message) {
      apiError.message = message
    }
    return apiError
}

class ApiError implements Translatable {
constructor(
    public readonly noNetworkConnection: boolean,
    public message: string,
    public readonly httpCode: number | null,
    public readonly serverCode: number | null,
    public readonly serverMessage: string | null
) {}

get default(): string {
    return this.message
}

get key(): string {
    return `ERROR_${this.serverCode}`
}

static noNetworkConnection(): ApiError {
    return new ApiError(true, "No network connection", null, null, null)
}

static fromResponse(response: any): ApiError {
    return new ApiError(
    false,
    response.data.message,
    response.status,
    response.data.error_code,
    response.data.message
    )
}
}