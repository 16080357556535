import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "text-xs uppercase" }
const _hoisted_3 = {
  key: 0,
  class: "material-icons-round text-[16px] font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
        return (_openBlock(), _createElementBlock("th", {
          key: header.value,
          class: _normalizeClass(["h-12 px-4 py-2 border-b select-none border-primaryGrapesJs-400", _ctx.classes(header)]),
          onClick: _withModifiers(($event: any) => (_ctx.clicked(header)), ["prevent"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex items-center space-x-1", { 'flex-row-reverse': header.right }])
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(header.text), 1),
            (header.value == _ctx.sort?.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.sort.direction == "asc" ? "expand_less" : "expand_more"), 1))
              : _createCommentVNode("", true)
          ], 2)
        ], 10, _hoisted_1))
      }), 128))
    ])
  ]))
}