import Axios from "axios"
import { getRequestToken } from "./auth"

const client = Axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
})

const _unauthedRoutes = ["login", "register", "resetPassword", "changePasswordAfterReset"]

// TODO: _authedRoutes

client.interceptors.request.use(
  async (config) => {
    if (config.url === "renewToken") {
      return config
    }

    const isUnauthedRoute = _unauthedRoutes.includes(config.url)
    const token = await getRequestToken()

    if (!isUnauthedRoute && !token) {
      return Promise.reject("Login required")
    }
    if (isUnauthedRoute && token) {
      return Promise.reject("User is already logged in")
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

export default client
