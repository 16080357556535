export function sharedPanels() {
  return {
    panelTop: {
      id: "panel-top",
      el: ".panel__top",
    },

    basicActions: {
      id: "basic-actions",
      el: ".panel__basic-actions",
      buttons: [
        {
          id: "create-blank-template",
          className: "fa fa-file-o",
          command: "open-create-blank",
          attributes: { title: "Create New Blank Template" },
        },
        {
          id: "open-save-as",
          className: "fa fa-floppy-o",
          command: "open-save-as",
          attributes: { title: "Save As" },
        },
        {
          id: "open-open-template",
          className: "fa fa-folder-open-o",
          command: "open-open-template",
          attributes: { title: "Open" },
        },
        {
          id: "import",
          className: "fa fa-download",
          command: "import-template",
          context: "import-template", // For grouping context of buttons from the same panel
          attributes: { title: "Import template" },
        },
        {
          id: "export",
          className: "fa fa-code",
          command: "export-template",
          context: "export-template", // For grouping context of buttons from the same panel
          attributes: { title: "View code" },
        },
        {
          id: "open-set-sample-data",
          className: "fa fa-database",
          command: "open-set-sample-data",
          attributes: { title: "Set sample data" },
        },
        {
          id: "clear",
          className: "fa fa-trash",
          command: "clear-canvas",
          attributes: { title: "Clear canvas" },
        },
      ],
    },
    panelDevices: {
      id: "panel-devices",
      el: ".panel__devices",
      buttons: [
        {
          id: "device-desktop",
          label: '<i class="fa fa-television"></i>',
          command: "set-device-desktop",
          active: true,
          togglable: false,
          attributes: { title: "Desktop" },
        },
        {
          id: "device-tablet",
          label: '<i class="fa fa-tablet"></i>',
          command: "set-device-tablet",
          togglable: false,
          attributes: { title: "Tablet" },
        },
        {
          id: "device-mobile",
          label: '<i class="fa fa-mobile"></i>',
          command: "set-device-mobile",
          togglable: false,
          attributes: { title: "Mobile" },
        },
      ],
    },
    panelToolbar: {
      id: "panel-toolbar",
      el: ".panel__toolbar",
      buttons: [
        {
          id: "render",
          className: "fa fa-file",
          command: "open-render", // Customised built-in command
          attributes: { title: "Render document" },
        },
        {
          id: "visibility",
          className: "fa fa-eye",
          active: true,
          context: 'sw-visibility',
          command: "sw-visibility", // Customised built-in command
          attributes: { title: "View components" },
        },
        {
          id: "fullscreen",
          className: "fa fa-arrows-alt",
          command: "core:fullscreen", // Built-in command
          attributes: { title: "Full screen" },
        },
        {
          id: "undo",
          className: "fa fa-undo",
          command: "core:undo",
          attributes: { title: "Undo" },
        },
        {
          id: "redo",
          className: "fa fa-repeat",
          command: "core:redo",
          attributes: { title: "Redo" },
        },
      ],
    },
    panelSwitcher: {
      id: "panel-switcher",
      el: ".panel__switcher",
      buttons: [
        {
          id: "show-styles",
          active: 0,
          className: "fa fa-paint-brush",
          command: "show-styles",
          togglable: false,
          attributes: { title: "Open Style Manager" },
        },
        {
          id: "show-layers",
          active: 0,
          className: "fa fa-bars",
          command: "show-layers",
          // Once activated disable the possibility to turn it off
          togglable: false,
          attributes: { title: "Open Layers Manager" },
        },
        {
          id: "show-blocks",
          active: 1,
          className: "fa fa-th-large",
          command: "show-blocks",
          togglable: false,
          attributes: { title: "Open Blocks" },
        },
      ],
    },
    panelRight: {
      id: "panel-right",
      el: ".panel__right",
      // Make the panel resizable
      // resizable: {
      //   maxDim: 350,
      //   minDim: 200,
      //   tc: 0, // Top handler
      //   cl: 1, // Left handler
      //   cr: 0, // Right handler
      //   bc: 0, // Bottom handler
      //   // Being a flex child we need to change `flex-basis` property
      //   // instead of the `width` (default)
      //   keyWidth: "flex-basis",
      // },
    },
  }
}
