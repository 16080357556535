<template>
  <router-view></router-view>
</template>

<script>

export default {
  methods: {
    goToFormBuilder() {
      this.$router.push("form-builder")
    },
    goToDocumentBuilder() {
      this.$router.push("document-builder")
    },
    goToEmailBuilder() {
      this.$router.push("email-builder")
    }
  },

  components: {},
}
</script>