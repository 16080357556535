import client from "./_client"
import { apiError } from "@/api/ApiError"
import { FormRenderer }  from "@/models/render/FormRenderer"

export const renderForm = async function(id: string, data: any): Promise<FormRenderer> {
  try {
    const response = await client.post(`renderForm`,
      {
        "idform": id,
        "data": data
      }
    )
    return FormRenderer.fromResponse(response.data)
  } catch (error: any) {
    throw apiError(error, {})
  }
}

export const submitForm = async function(id: string, data: any): Promise<void> {
  try {
    const response = await client.post(`submitForm`,
      {
        "idform": id,
        "data": data
      }
    )
  } catch (error: any) {
    throw apiError(error, {})
  }
}
