export default (editor, options) => {
  const bm = editor.BlockManager;
  const toAdd = name => options.blocks.indexOf(name) >= 0;

  toAdd(options.nameConditionalText) && bm.add(options.nameConditionalText, {
    label: options.labelConditionalText,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-text'},
    content: {
     type: options.nameConditionalText,
     style: { padding: '10px' },
     activeOnRender: 1
    },
  });

  toAdd(options.nameForEachContainer) && bm.add(options.nameForEachContainer, {
    label: options.labelForEachContainer,
    category: options.category,
    attributes: {class:'gjs-fonts gjs-f-b1'},
    content: {
     type: options.nameForEachContainer,
     style: { height: '100px' },
     activeOnRender: 1
    },
  });

  toAdd(options.nameAdvancedTable) && bm.add(options.nameAdvancedTable, {
    label: options.labelAdvancedTable,
    category: options.category,
    attributes: {class:'fa fa-table'},
    content: {
      type: options.nameAdvancedTable,
      activeOnRender: 1
    },
  });

}
