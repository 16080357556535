export default (domComponents, options) => {
  const cellsResizable = options.cellsResizable;

  domComponents.addType(options.componentCell, {
    isComponent: el => el.tagName === 'TD',
    model: {
      defaults: {
        name: 'Cell',
        tagName: 'td',
        draggable: false,
        removable: false,
        resizable: cellsResizable,
        classes: [],
      }
    },
    view: {
      events: {
        dblclick: "onDblClick",
      },

      onDblClick() {
        if (this.model.get('components').length === 0) {
          this.model.components().add({ type: 'text', content: 'Text', style: { padding: '10px' } });
        }
      },
    }
  });
};
