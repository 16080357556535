
  import { defineComponent } from "vue"

  import WTableDialog from "../bases/modals/WTableDialog.vue"
  import FlowTable from "./FlowTable.vue"
  import Flow from "../../models/flow/Flow"

  export default defineComponent({
    emits: ['rowClicked'],
    data() {
      return {
        importingId: null as null | string,
        isImporting: false,
        importErrorMessage: null as null | string,
      }
    },
    methods: {
      rowClicked(item: Flow) {
        this.$emit('rowClicked', item.exampleInput)
      },
    },
    components: { WTableDialog, FlowTable }
  })

