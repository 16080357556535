
import { defineComponent, PropType } from "vue"

import { DataTableHeader } from "./DataTableHeaderAndGroup"
import WDataTableHeader from "./WDataTableHeader.vue"
import WDataTableFooter from "./WDataTableFooter.vue"
import WDataTableEmptyRow from "./WDataTableEmptyRow.vue"
import WDataTableSkeleton from "../skeleton/WDataTableSkeleton.vue"

export default defineComponent({
  emits: ["clickRow"],
  props: {
    headers: {
      type: Array as PropType<Array<DataTableHeader>>,
      required: true,
    },
    items: Array,
    itemsPerPage: {
      // TODO:- This needs to be restricted so that it can only take numbers divisible by 5 (0 is "All")
      type: Number,
      default: 5,
    },
    dense: Boolean,
    search: String,
    loading: Boolean,
  },
  data() {
    return {
      currentPage: 0,
      rowsPerPage: this.itemsPerPage,
      sortedHeader: null as null | { value: string; direction: "asc" | "desc" },
    }
  },
  watch: {
    rowsPerPage() {
      this.firstPage()
    },
  },
  computed: {
    filterableHeaderValues() {
      return this.headers!.filter((each) => each.filterable).map((each) => each.value)
    },
    filteredItems(): unknown[] {
      if (!this.search || this.search.length == 0 || !this.items) {
        return this.items ?? []
      }
      const search = this.search.toLowerCase()
      return this.items.filter((each: any) =>
        this.filterableHeaderValues.some((header) =>
          each[header]?.toString().toLowerCase().includes(search)
        )
      )
    },
    sortedItems() {
      this.firstPage()
      if (!this.sortedHeader) {
        return this.filteredItems
      }
      const sorted = this.sortedHeader!.value
      return this.filteredItems!.sort((a: any, b: any) => {
        let compared: number
        if (a[sorted] == null) {
          compared = -1
        } else if (b[sorted] == null) {
          compared = 1
        } else {
          compared = a[sorted] <= b[sorted] ? -1 : 1
        }
        return this.sortedHeader!.direction == "asc" ? compared : -compared
      })
    },
    displayedItems() {
      return this.sortedItems.slice(this.fromIndex, this.toIndex + 1)
    },
    fromIndex(): number {
      return this.currentPage * this.rowsPerPage
    },
    toIndex(): number {
      const lastIndex = this.filteredItems.length - 1
      if (this.rowsPerPage == 0) {
        return lastIndex
      }
      const toIndex = this.currentPage * this.rowsPerPage + this.rowsPerPage - 1
      return Math.min(toIndex, lastIndex)
    },
  },
  methods: {
    firstPage() {
      this.currentPage = 0
    },
  },
  components: {
    WDataTableHeader,
    WDataTableFooter,
    WDataTableEmptyRow,
    WDataTableSkeleton,
  },
})
