import { getTemplateLites, deleteTemplate } from "@/api/template"
import { CrossOriginMessage, sendMessageToReferrer } from "@/communication/crossOriginCommunication"
// import router from "@/router"

export default (editor) => {
  var templateLite = null
  var documentType = null

  async function fetchTemplateLites(documentType) {
    return await getTemplateLites(documentType)
  }

  var templates = []
  var selectedTemplate = null
  var selectedRow = null

  const stylePrefix = editor.getConfig("stylePrefix")
  const modal = editor.Modal
  const container = document.createElement("div")
  container.className = "flex flex-col space-y-4"

  // Init table and container
  const tableContainer = document.createElement("div")
  tableContainer.className = "w-full overflow-auto h-96"
  const table = document.createElement("table")
  table.className = "w-full"

  const headerRow = document.createElement("tr")

  const nameHeader = document.createElement("th")
  nameHeader.className = "pr-4 text-left"
  const nameText = document.createTextNode("Name")
  nameHeader.appendChild(nameText)
  headerRow.appendChild(nameHeader)

  const descriptionHeader = document.createElement("th")
  descriptionHeader.className = "pr-4 text-left"
  const descriptionText = document.createTextNode("Description")
  descriptionHeader.appendChild(descriptionText)
  headerRow.appendChild(descriptionHeader)

  table.appendChild(headerRow)

  const tableBody = document.createElement("tbody")
  table.appendChild(tableBody)

  // Init left buttons container
  const leftButtonsContainer = document.createElement("div")
  leftButtonsContainer.className = "flex flex-row space-x-2"

  // Init center buttons container
  const centerButtonsContainer = document.createElement("div")
  centerButtonsContainer.className = "flex-1"

  // Init right buttons container
  const rightButtonsContainer = document.createElement("div")
  rightButtonsContainer.className = "flex flex-row space-x-2"

  // Init buttons container
  const buttonsContainer = document.createElement("div")
  buttonsContainer.className = "flex flex-row space-x-2"

  // Init load button
  const loadButton = document.createElement("button")
  loadButton.innerHTML = "Load"
  loadButton.className = stylePrefix + "btn-prim " + stylePrefix + "btn-export"
  loadButton.onclick = () => {
    if (!selectedTemplate) {
      confirm("Please select a template")
    } else {
      openSelectedTemplate(selectedTemplate)
    }
  }

  // Init delete button
  const deleteButton = document.createElement("button")
  deleteButton.innerHTML = "Delete"
  deleteButton.className = stylePrefix + "btn-prim " + stylePrefix + "btn-export"
  deleteButton.onclick = () => {
    if (!selectedTemplate) {
      confirm("Please select a template")
    } else {
      if ( templateLite && selectedTemplate.name == templateLite.name) {
        confirm("This template is currently in use. Please open a new (or a different) template to delete this template")
      } else if (confirm("Are you sure you want to delete this template?")) {
        deleteTemplate(selectedTemplate.id)
          .then(() => {
            const index = templates.findIndex((element) => element == selectedTemplate)
            templates.splice(index, 1)
          })
          .then(() => tableBody.removeChild(selectedRow))
          .then(() => {
            if (templates.length == 0) {
              addNoDataRow()
            }
            sendMessageToReferrer(selectedTemplate.type, CrossOriginMessage.deleted)
          })
      }
    }
  }

  // Init create new template button
  const createNewButton = document.createElement("button")
  createNewButton.innerHTML = "Create New"
  createNewButton.className = stylePrefix + "btn-prim " + stylePrefix + "btn-export"
  createNewButton.onclick = () => {
    editor.Commands.get("gjs-open-create-blank").run(templateLite?.type ?? documentType)
    reset()
    editor.Modal.close()
  }

  // Helper Methods
  function reset() {
    selectedTemplate = null
    templates = []
    selectedRow = null
    tableBody.innerHTML = ""
  }

  async function goToTemplateUrl(templateLite) {
    const currentUrl = window.location.pathname.replace(/\/$/, '')
    const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1)
    let url = `${process.env.VUE_APP_BUILDER_URL}/${templateLite.type}-builder/${templateLite.id}`
    
    switch (lastSegment) {
      case `${templateLite.type}-builder`:
        window.open(url, '_self');
        break;
      case `new`:
        window.open(url, '_blank');
        break;
      default:
        window.open(url, '_blank')
        break;
    }
  }

  async function openSelectedTemplate(templateLite) {
    goToTemplateUrl(templateLite)
    reset()
    editor.Modal.close()
  }

  function addNoDataRow() {
    const noDataRow = document.createElement("tr")
    const tdNoData = document.createElement("td")
    tdNoData.colSpan = 2
    const noDataText = document.createTextNode("There are no saved templates to display.")
    tdNoData.appendChild(noDataText)
    noDataRow.appendChild(tdNoData)
    tableBody.appendChild(noDataRow)
  }

  function addLoadingMessage() {
    const loadingRow = document.createElement("tr")
    const tdLoading = document.createElement("td")
    tdLoading.colSpan = 2
    const loadingText = document.createTextNode("Loading...")
    tdLoading.appendChild(loadingText)
    loadingRow.appendChild(tdLoading)
    tableBody.appendChild(loadingRow)
  }

  // Add command
  return {
    run(thisTemplateLite, docType) {
      templateLite = thisTemplateLite
      documentType = docType
      reset()
      tableContainer.appendChild(table)
      container.appendChild(tableContainer)

      leftButtonsContainer.appendChild(loadButton)
      leftButtonsContainer.appendChild(deleteButton)
      rightButtonsContainer.appendChild(createNewButton)
      buttonsContainer.appendChild(leftButtonsContainer)
      buttonsContainer.appendChild(centerButtonsContainer)
      buttonsContainer.appendChild(rightButtonsContainer)
      container.appendChild(buttonsContainer)

      modal.setTitle("Open Template")
      modal.setContent(container)
      modal.open().getModel()

      addLoadingMessage()

      fetchTemplateLites(thisTemplateLite?.type ?? documentType).then((result) => {
        tableBody.innerHTML = ""
        templates = result

        if (templates.length == 0) {
          addNoDataRow()
        }

        templates.forEach((template) => {
          var tableRow = document.createElement("tr")
          tableRow.className = "cursor-pointer"

          var tdName = document.createElement("td")
          tdName.className = "pr-4 align-top select-none"
          var tdDescription = document.createElement("td")
          tdDescription.className = "pr-4 align-top select-none"

          var nameText = document.createTextNode(template.name)
          var descriptionText = document.createTextNode(template.description)

          tdName.appendChild(nameText)
          tdDescription.appendChild(descriptionText)
          tableRow.appendChild(tdName)
          tableRow.appendChild(tdDescription)

          tableRow.onclick = () => {
            console.log(template.id)
            selectedTemplate = template
            selectedRow = tableRow

            for (let row of tableBody.rows) {
              row.className = "cursor-pointer"
            }
            selectedRow.className = "bg-gray-600 cursor-pointer"
          }

          tableRow.ondblclick = () => {
            openSelectedTemplate(template)
          }

          tableBody.appendChild(tableRow)
        })
      })
    },
  }
}
