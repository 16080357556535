// import Step from "./Step"
// import FlowEnvironment from "./FlowEnvironment"
import RoutingKey from "./RoutingKey"

export default class Flow {
  private _id: string | null
  get id(): string | null {
    return this._id
  }
  name: string

  status: boolean
  exampleInput: string | null

  private _priority: number | null
  get priority(): number | null {
    return this._priority
  }

  // flowEnvironments: FlowEnvironment[] | undefined
  routingKeys: RoutingKey[] | undefined
  // steps: Step[] | undefined

  // readonly capturing: boolean

  constructor(params: New<Flow>) {
    this._id = null
    this.name = params.name!
    this.exampleInput = params.exampleInput ?? null
    this.status = params.status ?? true
    this._priority = null
    // this.flowEnvironments = params.flowEnvironments ?? []
    this.routingKeys = []
    // this.steps = []
  }

  static fromResponse(data: any): Flow {
    const flow = new Flow({
      name: data.name,
      exampleInput: data.example_input,
      status: data.status == 1,
    })
    flow._id = data.idflow
    flow._priority = data.priority
    // flow.flowEnvironments =
    //   data.flow_environments?.map((each: any) => FlowEnvironment.fromResponse(each))
    flow.routingKeys = data.routing_keys?.map((each: any) => RoutingKey.fromResponse(each))
    // flow.steps = data.steps?.map((each: any) => Step.fromResponse(each))
    return flow
  }

  oDataJson(): any {
    const json = {
      name: this.name,
      example_input: this.exampleInput,
      status: this.status ? 1 : 0,
    } as any
    // Note: (API) Empty objects or arrays cannot be passed
    // if (this.flowEnvironments && this.flowEnvironments.length > 0) {
    //   json.flow_environments = this.flowEnvironments.map((each) => each.oDataJson())
    // }
    return json
  }
}
