<template>
  <div class="relative">
    <label v-if="label" :for="_id" class="block mb-1 text-sm font-medium text-gray-700"
      >{{ label }}
      <span v-if="optional" class="text-xs italic font-normal text-gray-500"
        >・ Optional</span
      ></label
    >
    <div
      :class="classes"
      class="w-full border border-gray-300 rounded-lg focus-within:border-primary-900 focus-within:ring-primary-900 focus-within:ring-1"
    >
      <textarea
        :placeholder="placeholder"
        :id="_id"
        :disabled="disabled"
        :grows="grows"
        :value="modelValue"
        @input="resizeHeight"
        ref="textarea"
        class="w-full border-none rounded-lg resize-none focus:border-none focus:ring-0"
        :class="classes"
        :rows="rows"
      ></textarea>
    </div>
    <div v-if="errorMessage" class="mt-1 text-xs text-right text-primary-900">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: String,
    errorMessage: String,
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    placeholder: {
      type: String,
    },
    rows: {
      type: Number,
      default: 3,
    },
    grows: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
    classes() {
      return {
        "bg-gray-100": this.disabled,
        "bg-white": !this.disabled,
      }
    },
  },
  methods: {
    resizeHeight() {
      const element = this.$refs.textarea
      element.style.height = "auto"
      if (this.grows == false) {
        element.style.height = element.scrollHeight
      } else {
        element.style.height = element.scrollHeight + "px"
      }
      this.$emit("update:modelValue", element.value)
    },
  },
  mounted() {
    this.resizeHeight()
  },
}
</script>
