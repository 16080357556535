
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    small: Boolean,
    xSmall: Boolean,
    loadingColor: Object,
  },
})
