<template>
  <svg
    style="border-top-color: transparent"
    class="inline-block w-20 h-20 border-4 border-black rounded-full animate-spin"
    :class="classes"
    :small="small"
  ></svg>
</template>

<script>

export default {
  props: {
    label: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "w-[20px]": this.small,
        "h-[20px]": this.small,
        "border-[2px]": this.small,
      };
    },
  },
};
</script>
