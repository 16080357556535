<template>
  <TransitionRoot appear :show="modelValue" as="template">
    <Dialog as="div">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="grid min-h-screen mx-4 place-items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-600/60" @click.stop="needsClose" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div class="p-6 transform bg-white shadow-xl rounded-2xl min-w-[300px]">
              <div v-if="title" class="flex justify-between mb-4">
                <div class="text-xl text-primaryGrapesJs-100">{{ title }}</div>
                <w-icon-button
                  v-if="showCloseButton"
                  small
                  class="text-primaryGrapesJs-100"
                  @click="closeClicked"
                  >close</w-icon-button
                >
              </div>
              <slot />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay } from "@headlessui/vue"
import WIconButton from "../buttons/WIconButton.vue"

export default {
  emits: ["closed", "update:modelValue"],
  props: {
    modelValue: Boolean,
    persistent: {
      type: Boolean,
      default: false,
    },
    title: String,
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    needsClose() {
      if (!this.persistent) {
        this.$emit("update:modelValue", false)
      }
    },
    closeClicked() {
      this.$emit("closed")
      this.$emit("update:modelValue", false)
    },
  },

  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    WIconButton,
  },
}
</script>
