<template>
  <div class="flex mt-2 space-x-2 text-sm">
    <w-selector 
      v-model="conditionModel.lhsOperand" 
      class="w-48" 
      :items="variableOptions" 
      itemKey="path" 
      itemText="displayName"
    ></w-selector>
    <w-grouped-selector 
      v-model="conditionModel.operator" 
      class="w-40" 
      :groups="operatorGroups"
      itemKey="value" 
      itemText="name"
    ></w-grouped-selector>
    <condition-rhs-operand 
      v-if="conditionModel.operator && conditionModel.operator.type != 'basic'" 
      v-model="conditionModel.rhsOperand"
      :operatorType="conditionModel.operator.type"
    ></condition-rhs-operand>
    <w-button 
      v-if="!conditionModel.modifier && conditionModel.rhsOperand 
      && (conditionModel.operator.type == 'numeric' || conditionModel.operator.type == 'dateTime')" 
      small @click="addModifier"
    >+ Modifier</w-button>
    <condition-modifier 
      v-if="conditionModel.modifier" 
      v-model="conditionModel.modifier" 
      :operatorType="conditionModel.operator.type"
    ></condition-modifier>
    <w-button 
      v-if="conditionModel.modifier"
      small @click="removeModifier"
    >- Modifier</w-button>
  </div>
</template>

<script>

import ConditionRhsOperand from "./ConditionRhsOperand.vue"
import ConditionModifier from "./ConditionModifier.vue"
import { WSelector, WGroupedSelector } from "@/components/bases/form"
import WButton from "@/components/bases/buttons/WButton.vue"

export default {
  props: {
    modelValue: Object,
    variableOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      operatorGroups: [
        {
          groupName: "Basic operators",
          items: [
            { value: "", name: "exists", type: "basic", unless: false },
            { value: " == nil", name: "does not exist", type: "basic", unless: true }, // TODO if it doesn't work - maybe use 'unless' in liquid?
          ],
        },
        {
          groupName: "Text operators",
          items: [
            { value: " ==", name: "is equal to", type: "text", unless: false },
            { value: " !=", name: "is not equal to", type: "text", unless: false },
            { value: " contains", name: "contains", type: "text", unless: false },
            // { value: " contains", name: "does not contain", type: "text", unless: true }, // TODO if it doesn't work - maybe use 'unless' in liquid?
            // { value: " ==", name: "starts with", type: "text", unless: false }, // TODO
            // { value: " !=", name: "does not start with", type: "text", unless: true }, // TODO
            // { value: " ==", name: "ends with", type: "text", unless: false }, // TODO
            // { value: " !=", name: "does not end with", type: "text", unless: true }, // TODO
            // { value: " ==", name: "matches pattern", type: "text", unless: false }, // TODO
            // { value: " !=", name: "does not match pattern", type: "text", unless: true }, // TODO
          ],
        },
        {
          groupName: "Numeric operators",
          items: [
            { value: " ==", name: "is equal to", type: "numeric" },
            { value: " !=", name: "is not equal to", type: "numeric" },
            { value: " <", name: "is less than", type: "numeric" },
            { value: "  >", name: "is greater than", type: "numeric" },
            { value: " <=", name: "is less than or equal to", type: "numeric" },
            { value: " >=", name: "is greater than or equal to", type: "numeric" },
          ],
        },
        {
          groupName: "Datetime operators",
          items: [
            { value: " ==", name: "is equal to", type: "dateTime" },
            { value: " !=", name: "is not equal to", type: "dateTime" },
            { value: " <", name: "is earlier than", type: "dateTime" },
            { value: "  >", name: "is later than", type: "dateTime" },
            { value: " <=", name: "is earlier than or equal to", type: "dateTime" },
            { value: " >=", name: "is later than or equal to", type: "dateTime" },
          ],
        },
        {
          groupName: "Boolean operators",
          items: [
            { value: " ==", name: "is equal to", type: "boolean" },
            { value: " !=", name: "is not equal to", type: "boolean" },
          ],
        },
      ]
    }
  },
  computed: {
    conditionModel: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) },
    },
  },
  methods: {
    addModifier() {
      this.conditionModel.modifier = {
        operator: null,
        value: null,
        unit: { value: null, name: null },
      }
    },
    removeModifier() {
      this.conditionModel.modifier = null
    }
  },
  components: { ConditionRhsOperand, ConditionModifier, WSelector, WGroupedSelector, WButton }
}
</script>