import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-primaryGrapesJs-100"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_data_table_skeleton = _resolveComponent("w-data-table-skeleton")!
  const _component_w_data_table_header = _resolveComponent("w-data-table-header")!
  const _component_w_data_table_empty_row = _resolveComponent("w-data-table-empty-row")!
  const _component_w_data_table_footer = _resolveComponent("w-data-table-footer")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_w_data_table_skeleton, { key: 0 }))
    : (_openBlock(), _createElementBlock("table", _hoisted_1, [
        _createVNode(_component_w_data_table_header, {
          headers: _ctx.headers,
          sort: _ctx.sortedHeader,
          "onUpdate:sort": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortedHeader) = $event))
        }, null, 8, ["headers", "sort"]),
        _createElementVNode("tbody", null, [
          (_ctx.filteredItems.length == 0)
            ? (_openBlock(), _createBlock(_component_w_data_table_empty_row, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.items && _ctx.items.length ? "No matching records found" : "No data available"), 1)
                ]),
                _: 1
              }))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.displayedItems, (row) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: row,
                  class: _normalizeClass(["cursor-pointer hover:bg-primaryGrapesJs-500", _ctx.dense ? 'h-10' : 'h-14']),
                  onClick: ($event: any) => (_ctx.$emit('clickRow', row))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: header.value,
                      class: _normalizeClass(["px-4 border-b border-primaryGrapesJs-400", { 'text-right': header.right }])
                    }, [
                      _renderSlot(_ctx.$slots, `item.${header.value}`, { item: row }, () => [
                        _createTextVNode(_toDisplayString(header.isDate ? row[header.value].toLocaleString() : row[header.value]), 1)
                      ])
                    ], 2))
                  }), 128))
                ], 10, _hoisted_2))
              }), 128))
        ]),
        _createVNode(_component_w_data_table_footer, {
          rows: _ctx.rowsPerPage,
          "onUpdate:rows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rowsPerPage) = $event)),
          page: _ctx.currentPage,
          "onUpdate:page": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
          count: _ctx.filteredItems.length
        }, null, 8, ["rows", "page", "count"])
      ]))
}