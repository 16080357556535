import client from "./_client"
import Flow from "@/models/flow/Flow"
import RoutingKey from "@/models/flow/RoutingKey"

async function getFlows(filters) {
  try {
    const _filters = []
    if (filters?.routingKeysType != null) {
      _filters.push(`?$expand=routing_keys($expand=api_key)&$filter=routing_keys/any(k:k/type eq ${filters.routingKeysType})`)
    }
    let url = "flow"
    if (_filters.length > 0) {
      url += _filters.join(",")
    }
    const response = await client.get(url)
    return response.data.records.map((each) => Flow.fromResponse(each))
  } catch (error) {
    console.log(error)
  }
}

async function getFlow(id, expands) {
  try {
    const _expands = []
    if (expands?.steps) {
      _expands.push("steps")
    }
    if (expands?.flowEnvironments) {
      _expands.push("flow_environments")
    }
    if (expands?.routingKeys) {
      _expands.push("routing_keys($expand=api_key)")
    }
    let url = `flow(${id})`
    if (_expands.length > 0) {
      url += "?$expand=" + _expands.join(",")
    }
    const response = await client.get(url)
    return Flow.fromResponse(response.data)
  } catch (error) {
    console.log(error)
  }
}

async function getRoutingKey(id) {
  try {
    const response = await client.get(`routing_key(${id})?$expand=api_key`)
    return RoutingKey.fromResponse(response.data)
  } catch (error) {
    console.log(error)
  }
}


export { getFlows, getFlow, getRoutingKey }
