import { unique } from "../../../../helpers/miscellaneousHelpers"
import { getValue } from './data'

function getLocalForEachGroups(data, component) {
  if (!data || !component) return []

  if (component.isChildOf('for-each-base')) {
    const forEachContainers = component.parents().filter((parent) => 
      parent.isInstanceOf("for-each-base")
    ).reverse()

    const paths = forEachContainers.map((container) => {
      const forEachGroups = container.attributes.forEachGroups
      const forEachGroupPaths = forEachGroups.map((forEachGroup) => {
        return forEachGroup.selectedGroup.path
      })
      return forEachGroupPaths.flat()
    })
    const parentFullPath = paths.flat().join(`[0].`)
    const firstInstance = getFirstInstance(data, parentFullPath)

    const lastContainerForEachGroups = forEachContainers[forEachContainers.length - 1].attributes.forEachGroups
    const parentVariableText = lastContainerForEachGroups[lastContainerForEachGroups.length - 1].variableText    
    
    return getForEachGroups(firstInstance, "", parentFullPath, parentVariableText)
  } else {
    return getForEachGroups(data, "")
  }
}

function getFirstInstance(data, parentFullPath) {
  return getValue(data, parentFullPath)[0]
}

function getForEachGroups(data, prefix, parentFullPath, variableText) {
  var arr = Object.entries(data).reduce((acc, [key, value]) => {
    const temp_displayName = variableText ? `${variableText}.${key}` : key
    const temp_path = prefix ? `${prefix}.${key}` : key
    const temp_fullPath = parentFullPath ? `${parentFullPath}.${key}` : ( prefix ? `${prefix}.${key}` : key)
    if (Array.isArray(value)) {
      value.forEach(() => acc.push({ displayName: temp_displayName, path: temp_path, fullPath: temp_fullPath }))
    } else if (!(typeof value === "string" || typeof value === "number")) {
      const newPrefix = variableText ? `${variableText}.${key}` : key
      acc.push(getForEachGroups(value, key, temp_fullPath, newPrefix))
    }
    return acc.flat()
  }, [])
  return unique(arr)
}

function setForEachProperties(component, forEachGroups) {
  if (component.isInstanceOf("for-each-base")) {
    forEachGroups.forEach((group, index) => {
      const iteratorKey = `foreachiterator${index + 1}`
      component.addAttributes({ [iteratorKey]: "for " + group.variableText + " in " + group.selectedGroup.displayName })
    })

    component.addAttributes({ foreach: forEachGroups.length })
    component.set("forEachGroups", forEachGroups)
    component.set("isNew", false)
    component.getView().render()
  }
}

function removeForEachIfNew(component) {
  if (component.isInstanceOf("for-each-base")) {
    if (component.attributes.isNew) {
      const coll = component.collection;
      coll.remove(component);
    }
  }
}

export { getLocalForEachGroups, getFirstInstance, getForEachGroups, setForEachProperties, removeForEachIfNew }