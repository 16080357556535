<template>
  <span class="grid place-items-center" :class="classes">
    <slot />
  </span>
</template>

<script>

export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        'material-icons-round': !this.outlined,
        'material-icons-outlined': this.outlined,
        'text-[16px]': this.small
      }
    },
  },
}
</script>
