<template>
  <form class="space-y-4">
    <div v-if="title">
      <div class="text-xl">{{ title }}</div>
      <div v-if="subTitle" class="text-sm text-gray-500">{{ subTitle }}</div>
    </div>
    <div v-if="loading" class="grid h-16 place-items-center">
      <w-spinner small />
    </div>
    <div v-else>
      <div :class="slotClasses">
        <slot />
      </div>
      <div class="pt-8">
        <slot name="actions" />
      </div>
    </div>
  </form>
</template>

<script>
import WSpinner from "./WSpinner.vue"

export default {
  components: { WSpinner },
  props: {
    title: String,
    subTitle: String,
    dense: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    slotClasses() {
      return {
        "space-y-4": !this.dense,
      }
    },
  },
}
</script>
