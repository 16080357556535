// Add other fields from Template to this if required, just not html, css, js, component or style as the point of this class
// is to pass around the details of the template only (e.g. name, description, id)

export default class TemplateLite {
  _id
  get id(){
    return this._id
  }

  name
  description
  title
  type

  constructor(params) {
    this._id = null
    this.name = params.name
    this.description = params.description
    this.title = params.title
    this.type = params.type
  }

  static fromResponse(data) {
    const templateLite = new TemplateLite({
      name: data.name,
      description: data.description,
      title: data.title,
      type: data.type
    })
    templateLite._id = data.idtemplate
    return templateLite
  }

  static fromTemplate(template) {
    const templateLite = new TemplateLite({
      name: template.name,
      description: template.description,
      title: template.title,
      type: template.type
    })
    templateLite._id = template.id
    return templateLite
  }

  // oDataJson() {
  //   return {
  //     name: this.name,
  //     description: this.description,
  //   }
  // }
}
