<template>
  <Listbox :modelValue="_modelValue" @update:modelValue="modelValueUpdated" :class="classes">
    <div class="relative">
      <label v-if="label" :for="_id" class="block mb-1 text-sm font-medium">{{ label }} </label>
      <div class="relative w-full">
        <ListboxButton class="w-full py-2 pl-4 pr-0.5 truncate border rounded-lg focus:outline-none focus:border-primary-700" :class="classes" :disabled="disabled">
          <div class="flex flex-row items-center justify-items-center">
            <span class="flex w-full truncate">{{ (_modelValue?.[itemText]) ?? null }}</span>
            <i class="grid w-10 text-gray-500 pointer-events-none material-icons-round place-items-center">unfold_more</i>
          </div>
        </ListboxButton>
      </div>
      <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
        <ListboxOptions class="absolute py-1 mt-1 overflow-auto bg-primaryGrapesJs-500 border border-primaryGrapesJs-300 rounded-lg shadow-lg z-[8] min-w-full max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div v-if="groups.length === 0" class="relative px-4 py-2 text-gray-700 cursor-default select-none">Nothing found.</div>
          <w-grouped-selector-group 
            v-for="group in groups" 
            :groupName="group.groupName"
            :items="group.items"
            :itemKey="itemKey"
            :itemText="itemText"
            :key="group.items"
          ></w-grouped-selector-group>
        </ListboxOptions>
      </TransitionRoot>
    </div>
  </Listbox>
</template>

<script>

import { Listbox, ListboxButton, ListboxOptions, TransitionRoot } from "@headlessui/vue"
import WGroupedSelectorGroup from "./WGroupedSelectorGroup.vue"

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: null,
      required: true,
    },
    returnKey: {
      type: Boolean,
      required: false,
    },
    id: String,
    label: String,
    groups: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "key",
    },
    itemText: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
    _modelValue() {
      return !this.returnKey 
      ? this.modelValue 
      : this.groups.flatMap(group => group.items).find((item) => item[this.itemKey] === this.modelValue)
    },
    classes() {
      return {
        "bg-primaryGrapesJs-600 text-primaryGrapesJs-200 cursor-default border-primaryGrapesJs-200 rounded-lg": this.disabled,
        "bg-primaryGrapesJs-900 text-primaryGrapesJs-100 cursor-pointer border-primaryGrapesJs-300 rounded-lg": !this.disabled,
      }
    }
  },
  methods: {
    modelValueUpdated(item) {
      const value = !this.returnKey ? item : item[this.itemKey]
      this.$emit("update:modelValue", value)
    },
  },

  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    TransitionRoot,
    WGroupedSelectorGroup,
  },
}
</script>
<style scoped>
.border-style:focus-within {
  border: rgb(220 38 38) solid 1px;
  box-shadow: 0px 0px 0px 1px rgb(220 38 38);
}
[type="text"]:focus {
  border: transparent;
  --tw-ring-color: #0000;
}
</style>
