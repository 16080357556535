import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import VCalendar from 'v-calendar'

import "@/styles/tailwind.css"
import "@/styles/general.css"
import "@/styles/icons.css"
import "@/styles/fonts.css"
import 'v-calendar/dist/style.css';

createApp(App)
.use(router)
.use(VCalendar)
.mount('#app')

