import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/pages/HomePage.vue'
import FormBuilderPage from '@/components/pages/FormBuilderPage.vue'
import DocumentBuilderPage from '@/components/pages/DocumentBuilderPage.vue'
import EmailBuilderPage from '@/components/pages/EmailBuilderPage.vue'
import FormRendererPage from '@/components/pages/FormRendererPage.vue'
import PageNotFound from '@/components/pages/PageNotFound.vue'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
      redirect: "/document-builder/",
      children: [
        {
          path: "/form-builder",
          component: FormBuilderPage,
        },
        {
          path: "/form-builder/new",
          component: FormBuilderPage,
          props: { isNewDoc: true }
        },
        {
          path: "/form-builder/:templateId",
          component: FormBuilderPage,
          props: true
        },
        // {
        //   path: "/document-builder",
        //   redirect: "/document-builder/",
        // },
        {
          name: "documentBuilder",
          path: "/document-builder",
          component: DocumentBuilderPage,
        },
        {
          name: "documentBuilderNew",
          path: "/document-builder/new",
          component: DocumentBuilderPage,
          props: { isNewDoc: true }
        },
        {
          name: "documentBuilderId",
          path: "/document-builder/:templateId",
          component: DocumentBuilderPage,
          props: true
        },
        {
          path: "/email-builder",
          component: EmailBuilderPage,
        },
        {
          path: "/email-builder/new",
          component: EmailBuilderPage,
          props: { isNewDoc: true }
        },
        {
          path: "/email-builder/:templateId",
          component: EmailBuilderPage,
          props: true
        },
      ],
    },
    {
      path: "/form-renderer/:id",
      name: "renderer",
      component: FormRendererPage,
    },
    {
      path: "/:pathMatch(.*)*", 
      name: "404",
      component: PageNotFound
    }
  ]
})

export default router