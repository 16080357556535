import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "renderer-wrapper",
  class: "absolute flex items-center justify-center w-full min-h-full"
}
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_dialog = _resolveComponent("w-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_w_dialog, {
      class: "overflow-y-auto",
      title: "",
      showCloseButton: "",
      persistent: true,
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass(["grid place-items-center material-icons-round !text-[128px]", _ctx.errorMode == 'success' ? 'text-green-600 ' : 'text-red-600 '])
          }, _toDisplayString(_ctx.errorIcon), 3)
        ]),
        _createElementVNode("h1", {
          class: _normalizeClass(["w-96 text-center text-[32px]", _ctx.errorMode == 'success' ? 'text-green-600 ' : 'text-red-600 '])
        }, _toDisplayString(_ctx.errorTitle), 3),
        _createElementVNode("div", {
          class: "mt-4 text-center w-96",
          innerHTML: _ctx.errorMessage
        }, null, 8, _hoisted_3)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}