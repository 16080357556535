<template>
  <div class="relative px-4 py-2 font-medium text-gray-300 cursor-default select-none">{{ groupName }}</div>
  <ListboxOption v-for="item in items" as="template" :key="item[itemKey]" :value="item" v-slot="{ selected, active }">
    <li
      class="relative py-2 pl-10 pr-4 cursor-pointer select-none"
      :class="{
        'text-primary-900 bg-primary-100': active,
      }"
    >
      <span class="block truncate">
        {{ item[itemText] }}
      </span>
      <i v-if="selected" class="absolute inset-y-0 left-0 grid w-10 material-icons-round text-primary-900 place-items-center"> check </i>
    </li>
  </ListboxOption>
</template>

<script>

import { ListboxOption } from "@headlessui/vue"

export default {
  emits: ["update:modelValue"],
  props: {
    groupName: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "key",
    },
    itemText: {
      type: String,
      default: "text",
    },
  },

  components: {
    ListboxOption,
  },
}
</script>
