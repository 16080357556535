
import { defineComponent, PropType } from "vue"

import WInputWrapper from "../WInputWrapper.vue"
import { WSpinner, WIcon, WChip } from "@/components/bases/misc"
import { WTextField } from "@/components/bases/form"
import WPopover from "@/components/modals/WPopover.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: String,
    zIndex: String,
    placeholder: String,
    hint: String,
    errorMessage: String,
    placement: {
      type: String,
      default: "bottom",
    },
    mode: {
      type: String,
      default: "dateTime",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
    masks:{
      type: Object as PropType<{input: string}>,
    }
  },
  watch:{
    date:{
      handler(){
        this.$emit("update:modelValue", this.date)
      }
    }
  },
  data() {
    return {
      search: null as string | null,
      rect: undefined as any,
      selectedItems: [] as Array<any>,
      date: new Date() as Date,
    }
  },
  mounted() {
    // this.setRect()

    // this.selectedItems = this.returnKey
    //     ? this.items.filter((eachItem) => this.modelValue.some((eachModel:any)=> eachItem[this.returnKey!] === eachModel )! )!
    //     : this.modelValue
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },

    dateString(): string{
      if(!this.date) return "";
      if(this.mode === 'dateTime')
        return this.date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        })
      else
        return this.date.toLocaleDateString(undefined, {
          hour: "numeric",
          minute: "numeric"
        })
    },


  },
  methods: {
    // setRect() {
    //   const combobox = this.$refs.combobox as any
    //   this.rect = combobox.$el.getBoundingClientRect()
    // },

  },
  components: {
    // WPopover,
    // PopoverPanel,
    WTextField,
    // WButton,
  },
})
