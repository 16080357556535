export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameAdvancedForEachTable
  const label = options.labelAdvancedForEachTable
  const extendName = options.nameForEachBase

  domComponents.addType(name, {
    extend: extendName,
    isComponent: el => el.tagName === 'ADVANCEDTABLE',
    model: {
      defaults: {
        name: label,
        tagName: 'table',
        resizable: options.tblResizable,
        style: { height: '100px', width: '100%'},
        classes: [name],
        // droppable: options.componentCell + ',' + options.componentCellHeader,
        // draggable: false,
        // classes: [],
      }
    },
    view: {
      init() {
        const doc = editor.Canvas.getDocument()
        if (!doc) { return }

        const stl = doc?.querySelector(`style#${name}`);
        if (!stl) {
          doc.body.insertAdjacentHTML('beforeend', `
          <style id=${name}>
            .${name}:empty:after {
              content: "${label}";
              height: 100%;
              font-size: large;
              color: gray;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          </style>
          `);
        } 
      }, 
      events: {
        dblclick: "onDblClick",
      },
      onDblClick() {
        editor.Commands.get("open-for-each").run()
      },
    }
  });
};