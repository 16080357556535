export default (editor, config) => {

  var setData = function() {console.log("setData function hasn't been replaced")} // 1. Declared first here, then set inside `run()`
  var importClicked = function() { console.log("importClicked function hasn't been replaced") }

  const stylePrefix = editor.getConfig("stylePrefix")
  const modal = editor.Modal
  const codeViewer = editor.CodeManager.getViewer("CodeMirror").clone()
  const container = document.createElement("div")
  const importLabel = config.modalImportLabel
  let viewerEditor = codeViewer.editor

  // Init left buttons container
  const leftButtonsContainer = document.createElement("div")
  leftButtonsContainer.className = "flex flex-row pt-2 space-x-2"

  // Init set data button
  const btnSet = document.createElement("button")
  btnSet.innerHTML = config.modalBtnImport || "Set Data"
  btnSet.className = stylePrefix + "btn-prim " + stylePrefix + "btn-import"
  btnSet.onclick = () => {
    if (confirm("Are you sure you want to set the sample data? Existing for-each containers and conditional text blocks may no longer function properly")) {
      let code = codeViewer.editor.getValue()
      try {
        code ? setData(JSON.parse(code)) : setData(null) // 3. Then gets called here, thus setting this.data within Document Builder
        editor.Modal.close()
      } catch {
        confirm("Please enter a valid JSON string")
      }
    }
  }

  // Init import from flow button
  const btnImport = document.createElement("button")
  btnImport.innerHTML = "Import from Flow"
  btnImport.className = stylePrefix + "btn-prim " + stylePrefix + "btn-import"
  btnImport.onclick = () => {
    importClicked()
    editor.Modal.close()
  }

  // Init code viewer
  codeViewer.set({
    codeName: 'htmlmixed',
    readOnly: 0,
    theme: 'hopscotch',
    autoBeautify: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    lineWrapping: true,
    styleActiveLine: true,
    smartIndent: true,
    indentWithTabs: true
  })

  return {
    run(setThisData, thisData, thisImportClicked) {
      setData = setThisData // 2. This sets the function declared above to the `setThisData` function in DocumentBuilder
      importClicked = thisImportClicked
      if (!viewerEditor) {
        const txtarea = document.createElement("textarea")

        if (importLabel) {
          const labelEl = document.createElement("div")
          labelEl.className = `${stylePrefix}import-label`
          labelEl.innerHTML = importLabel
          container.appendChild(labelEl)
        }

        container.appendChild(txtarea)
        leftButtonsContainer.appendChild(btnSet)
        leftButtonsContainer.appendChild(btnImport)
        container.appendChild(leftButtonsContainer)
        codeViewer.init(txtarea)
        viewerEditor = codeViewer.editor
      }

      modal.setTitle("Set Sample Data (paste JSON below)")
      modal.setContent(container)
      viewerEditor.autoFormatRange({ line:0, ch:0 }, { line: viewerEditor.lineCount() });
      const content = thisData ? JSON.stringify(thisData, null, 2) : "Replace this with your sample data" // To make this look nicer somehow - create method to spread string over several lines?
      codeViewer.setContent(content)
      modal
        .open()
        .getModel()
      viewerEditor.refresh()
    },

    stop() {
      modal.close()
    },
  }
}