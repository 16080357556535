export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameForEachContainer
  const label = options.labelForEachContainer
  const extendName = options.nameForEachBase

  domComponents.addType(name, {
    extend: extendName,
    isComponent: (el) => el.tagName == "FOREACH",
    model: {
      defaults: {
        name: label,
        tagName: "div",
        resizable: options.forEachContainerResizable,
        // style: { height: '100px', width: '100%'},
        classes: [name],
      },
    },
    view: {
      init() {
        const doc = editor.Canvas.getDocument()
        if (!doc) { return }

        const stl = doc?.querySelector(`style#${name}`);
        if (!stl) {
          doc.body.insertAdjacentHTML('beforeend', `
          <style id=${name}>
            .${name}:empty:after {
              content: "${label}";
              height: 100%;
              font-size: large;
              color: gray;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          </style>
          `);
        } 
      },
      events: {
        dblclick: "onDblClick",
      },
      onDblClick() {
        editor.Commands.get("open-for-each").run()
      },
    },
  })
}
