<template>
  <div class="flex justify-between" :class="classes">
    <div class="flex items-center space-x-4">
      <slot name="left" />
    </div>
    <div class="flex items-center space-x-4">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    indent: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes() {
      return {
        'px-4': this.indent,
        'py-4': !this.dense,
        'py-2': this.dense
      }
    }
  }
}
</script>
