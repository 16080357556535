<template>
  <w-section v-if="!variableOptions || variableOptions.length < 1">
    <div class="text-xs italic text-primaryGrapesJs-100">No sample data has been provided. Please set sample data before adding conditional text components.</div>
  </w-section>
  <w-form v-else class="min-w-[400px]">
    <w-section>
      <if-control-section 
        v-if="componentType == 'conditional-text'" 
        :block="ifControlBlock" 
        :variableOptions="variableOptions"
      ></if-control-section>
      <table-if-control-section 
        v-if="componentType == 'advanced-table-conditional-base'" 
        :block="ifControlBlock" 
        :variableOptions="variableOptions"
      ></table-if-control-section>
    </w-section>
    <template #actions>
      <div class="flex flex-row">
        <div class="flex flex-1"></div>
        <div class="space-y-6">
          <div class="flex flex-row space-x-4">
            <div class="flex flex-1"></div>
            <w-button mode="secondary" @click="$emit('cancel')">Cancel</w-button>
            <w-button @click="applyIfCondition">Apply</w-button>
          </div>
        </div>
      </div>
    </template>
  </w-form>
</template>

<script>
import { WForm } from "@/components/bases/form"
import { WButton } from "@/components/bases/buttons"
import WSection from "@/components/bases/WSection.vue"
import IfControlSection from "./IfControlSection.vue"
import TableIfControlSection from "./TableIfControlSection.vue"

export default {
  emits: ["cancel", "applyIfCondition"],

  props: {
    block: {
      type: Object,
    },
    variables: {
      type: Array,
    },
    componentType: {
      type: String
    }
  },

  watch: {
    variables: function () {
      this.variableOptions = this.variables
    }
  },

  data() {
    return {
      ifControlBlock: this.block,
      variableOptions: this.variables,
    }
  },

  methods: {
    applyIfCondition() {
      this.$emit("applyIfCondition", this.ifControlBlock)
    },
  },

  components: {
    WSection,
    WButton,
    WForm,
    IfControlSection,
    TableIfControlSection
  },
}
</script>
