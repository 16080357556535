import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid place-items-center material-icons-round text-[20px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_icon = _resolveComponent("w-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center p-3 space-x-2 rounded-lg", _ctx.classType])
  }, [
    (_ctx.iconType)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.iconType), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.$attrs.onClose)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_w_icon, { small: "" }, {
            default: _withCtx(() => [
              _createTextVNode("close")
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}