
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    large: Boolean,
    short: Boolean,
  },
  computed: {
    classes() {
      return {
        "w-32": this.short,
        "h-6 w-full": this.large,
        "h-4": !this.large,
      }
    },
  },
})
