import RhsOperand from "./RhsOperand"

export default class Condition {
  lhsOperand // Type = Operand
  operator // Type = Operator
  rhsOperand // Type = Operand
  modifier // Type = Modifier

  constructor(lhsOperand, operator, rhsOperand, modifier) {
    this.lhsOperand = lhsOperand
    this.operator = operator
    this.rhsOperand = rhsOperand
    this.modifier = modifier
  }

  static create() {
    return new Condition(
      { displayName: null, path: null }, 
      { value: null, name: null, type: null }, 
      new RhsOperand(null, null), 
      null
    )
  }

  static decode(condition) {
    return new Condition(
      condition.lhsOperand, 
      condition.operator, 
      RhsOperand.decode(condition.rhsOperand, condition.operator.type), 
      condition.modifier
    )
  }

  needsVariables(condition) {
    return (condition.modifier || condition.operator.type == "dateTime")
  }

  lhsCode(condition) {
    if (condition.operator.type == "dateTime") {
      return `${condition.lhsOperand.path} | date: '%s'`
    }
    return condition.lhsOperand.path
  }

  rhsCode(condition) {
    if (condition.operator.type == "numeric") {
      if (condition.modifier) {
        const modifier = condition.modifier
        const modifierCode = `| ${modifier.operator.value}: ${parseInt(modifier.value)}`
        return `${condition.rhsOperand.value} ${modifierCode}`
      } else {
        return condition.rhsOperand.value
      }
    }
    if (condition.operator.type == "dateTime") {
      const date = condition.rhsOperand.value.toISOString()
      if (condition.modifier) {
        const modifier = condition.modifier
        const modifierCode = ` | ${modifier.operator.value}: ${parseInt(modifier.value) * modifier.unit.value}`
        return `'${date}' | date: '%s'${modifierCode}`
      } else {
        return `'${date}' | date: '%s'`
      }
    }
    if (condition.operator.type == "text") {
      return "&quot;" + condition.rhsOperand.value + "&quot;"
    }

    return condition.rhsOperand.value
  }

  innerCode(condition, firstVariable, secondVariable, testVariable) {
    if (testVariable) { return testVariable }
    if (firstVariable && secondVariable) { return firstVariable + condition.operator.value + " " + secondVariable }
    switch (condition.operator.type) {
      case "basic": return condition.lhsOperand.path + condition.operator.value
      case "text": return condition.lhsOperand.path + condition.operator.value + " &quot;" + condition.rhsOperand.value + "&quot;"
      case "numeric": return condition.lhsOperand.path + condition.operator.value + " " + condition.rhsOperand.value
      // case "dateTime": return firstVariable + condition.operator.value + " " + secondVariable // Shouldn't be any need for this
      case "boolean": 
      return condition.lhsOperand.path + condition.operator.value + " " + condition.rhsOperand.value.value
      default:
        console.log("operator type not dealt with")
        break;
    }
  }

  displayText(condition) {
    return condition.lhsOperand.displayName + " " + condition.operator.name 
    + (condition.rhsOperand.value ?  " " 
    + (condition.operator.type == "boolean" ? condition.rhsOperand.value.name : condition.rhsOperand.value) : "")
    + (condition.modifier ? " " + condition.modifier.operator.name + " " + condition.modifier.value : "") 
    + (condition.modifier && condition.modifier.unit ? " " + condition.modifier.unit.name : "")
    
  } 

}
