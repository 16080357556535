export function onComponentAdd(editor, component) {
  setEmptyStateOfParent(component, "for-each-base")
  setEmptyStateOfParent(component, "separates-base")

  if (component.attributes.type === "advanced-table" && component.components().length == 0) {
    editor.runCommand("open-advanced-table-select-modal", { model: component })
  }
}

export function onComponentRemove(editor, component) {
  setEmptyStateOfParent(component, "for-each-base")
  setEmptyStateOfParent(component, "separates-base")
}

function setEmptyStateOfParent(component, parentName) {
  if (component.isChildOf(parentName)) {
    const parent = component.parents().find((parent) => parent.isInstanceOf(parentName))
    if (parent.components().length > 0) {
      parent.set("isEmpty", false)
    } else {
      parent.set("isEmpty", true)
    }
  }
}