import grapesjs from "grapesjs"

import basicBlocks from "../../shared/plugins/blocks/basicBlocks"
import advancedBlocks from "../../shared/plugins/blocks/advancedBlocks"

import pluginExportZip from "../../shared/plugins/export"
import pluginTable from "../../shared/plugins/table/"

import commands from "../../shared/plugins/commands"
import components from "../../shared/plugins/components"

export default grapesjs.plugins.add("gjs-documentbuilder-plugins", (editor, opts = {}) => {
  let config = opts

  let defaults = {
    basicBlocksOpts: {
      blocks: [
        "column1", "column2", "column3", "column3-7", "text", "text-section", "link", "quote", 
        "image", "video", "button", "divider", "grid-items", "list-items", "map",
      ],
      // flexGrid: 1,
      stylePrefix: "gjs-",
      addBasicStyle: true,
      category: "Basic",
      labelColumn1: "1 Column", nameColumn1: "column1",
      labelColumn2: "2 Columns", nameColumn2: "column2", 
      labelColumn3: "3 Columns", nameColumn3: "column3",
      labelColumn37: "2 Columns 3/7", nameColumn37: "column3-7",
      labelText: "Text", nameText: "text",
      labelTextSection: "Text Section", nameTextSection: "text-section",
      labelLink: "Link", nameLink: "link",
      labelQuote: "Quote", nameQuote: "quote",
      labelImage: "Image", nameImage: "image",
      labelVideo: "Video", nameVideo: "video",
      labelButton: "Button", nameButton: "button",
      labelDivider: "Divider", nameDivider: "divider",
      labelGridItems: "Grid Items", nameGridItems: "grid-items",
      labelListItems: "List Items", nameListItems: "list-items",
      labelMap: "Map", nameMap: "map",
      ...opts,
    },

    advancedBlocksOpts: {
      blocks: [
        "conditional-text", "for-each-container", "advanced-table"
      ],
      category: "Advanced",
      tblResizable: true,
      cellsResizable: true,

      labelConditionalText: 'Conditional Text', nameConditionalText: 'conditional-text',

      labelForEachBase: 'For-each Base', nameForEachBase: 'for-each-base',
      labelForEachContainer: 'For-each Container', nameForEachContainer: 'for-each-container',
      
      labelAdvancedTableBase: 'Advanced Table Base', nameAdvancedTableBase: 'advanced-table-base',
      labelAdvancedTableConditionalBase: 'Advanced Table Conditional Base', nameAdvancedTableConditionalBase: 'advanced-table-conditional-base',
      labelAdvancedTable: 'Advanced Table', nameAdvancedTable: 'advanced-table',
      labelAdvancedConditionalTable: 'Advanced Conditional Table', nameAdvancedConditionalTable: 'advanced-conditional-table',
      labelAdvancedForEachTable: 'Advanced For-each Table', nameAdvancedForEachTable: 'advanced-for-each-table',
      labelAdvancedTableThead: 'Advanced Table Header', nameAdvancedTableThead: 'advanced-table-thead',
      labelAdvancedTableTbody: 'Advanced Table Body', nameAdvancedTableTbody: 'advanced-table-tbody',
      labelAdvancedTableConditionalTbody: 'Advanced Table Conditional Body', nameAdvancedTableConditionalTbody: 'advanced-table-conditional-tbody',
      labelAdvancedTableForEachTbody: 'Advanced Table For-each Body', nameAdvancedTableForEachTbody: 'advanced-table-for-each-tbody',
      labelAdvancedTableTfoot: 'Advanced Table Footer', nameAdvancedTableTfoot: 'advanced-table-tfoot',
      labelAdvancedTableRow: 'Advanced Table Row', nameAdvancedTableRow: 'advanced-table-row',
      labelAdvancedTableConditionalRow: 'Advanced Table Conditional Row', nameAdvancedTableConditionalRow: 'advanced-table-conditional-row',
      labelAdvancedTableForEachRow: 'Advanced Table For-each Row', nameAdvancedTableForEachRow: 'advanced-table-for-each-row',
      labelAdvancedTableCellHeader: 'Advanced Table Cell Header', nameAdvancedTableCellHeader: 'advanced-table-cell-header',
      labelAdvancedTableCell: 'Advanced Table Cell', nameAdvancedTableCell: 'advanced-table-cell',
    },

    exportOpts: {
      filenamePfx: "wiresk_template",
      root: {
        "index.html": (editor) => editor.getHtml(),
        "style.css": (editor) => editor.getCss(),
      },
    },

    tableOpts: {},
  }

  // Load defaults
  for (let name in defaults) {
    if (!(name in config)) config[name] = defaults[name]
  }

  const { basicBlocksOpts, advancedBlocksOpts, exportOpts, tableOpts } = config

  // Load plugins
  basicBlocksOpts && basicBlocks(editor, basicBlocksOpts)
  exportOpts && pluginExportZip(editor, exportOpts)
  tableOpts && pluginTable(editor, tableOpts)
  advancedBlocksOpts && advancedBlocks(editor, advancedBlocksOpts)

  commands(editor, config)
  components(editor, config)
})
