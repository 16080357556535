
import { defineComponent } from "vue"
import { WIconButton } from "@/components/bases/buttons"
import { WSelector } from "@/components/bases/form"

export default defineComponent({
  emits: ["update:rows", "update:page"],
  props: {
    rows: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rowsPerPageOptions: [
        { value: 5, name: "5" },
        { value: 10, name: "10" },
        { value: 25, name: "25" },
        { value: 50, name: "50" },
        { value: 0, name: "All" },
      ],
    }
  },
  computed: {
    displayedItemsText() {
      const from = this.page * this.rows
      return `${from + 1}-${this.toIndex + 1} of ${this.count}`
    },
    toIndex() {
      const last = this.count - 1
      return this.rows == 0 ? last : Math.min(this.page * this.rows + this.rows - 1, last)
    },
  },
  components: { WIconButton, WSelector },
})
