<template>
  <div class="flex">
    <w-vertical-divider text="Else"></w-vertical-divider>
    <w-text-field highlighted class="w-full pt-2 pr-2" v-model="textModel"></w-text-field>
  </div>
</template>

<script>

import { WTextField } from "@/components/bases/form"
import WVerticalDivider from "./WVerticalDivider.vue"

export default {
  props: {
    modelValue: String
  },
  computed: {
    textModel: {
      get () { return this.modelValue },
      set (value) { this.$emit('update:modelValue', value) },
    },
  },
  components: { WTextField, WVerticalDivider }
}
</script>