<template>
  <Combobox :modelValue="_modelValue" @update:modelValue="modelValueUpdated">
    <div class="relative">
      <label v-if="label" :for="_id" class="block mb-1 text-sm font-medium text-gray-700"
        >{{ label }}
        <span v-if="optional" class="text-xs italic font-normal text-gray-500 capitalize"
          >・ optional</span
        ></label
      >
      <div
        :class="classes"
        class="relative flex items-center w-full pl-3 border border-gray-300 rounded-lg border-style"
      >
        <ComboboxInput
          :id="_id"
          :placeholder="placeholder"
          :disabled="disabled"
          :displayValue="(item) => item[itemText]"
          @change="query = $event.target.value"
          :class="classes"
          class="w-full pl-0 border-none rounded-lg focus-visible:border-none focus-visible:ring-0 :focus-visible"
        />
        <div class="absolute right-0 w-10 text-center top-2">
          <ComboboxButton :disabled="disabled" v-if="loading == false">
            <i
              class="grid w-10 text-gray-500 pointer-events-none material-icons-round place-items-center"
              >unfold_more</i
            >
          </ComboboxButton>
          <w-spinner v-else class="border-gray-500" small />
        </div>
      </div>
      <div v-if="errorMessage" class="mt-1 text-xs text-right text-primary-900">
        {{ errorMessage }}
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="absolute py-1 mt-1 overflow-auto bg-white rounded-lg shadow-lg z-[8] min-w-full max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div
            v-if="filteredItems.length === 0 && query !== ''"
            class="relative px-4 py-2 text-gray-700 cursor-default select-none"
          >
            Nothing found.
          </div>

          <ComboboxOption
            v-for="item in filteredItems"
            as="template"
            :key="item[itemKey ?? returnKey ?? 'key']"
            :value="item"
            v-slot="{ selected, active }"
          >
            <li
              class="relative py-2 pl-10 pr-4 cursor-pointer select-none"
              :class="{
                'text-primary-900 bg-primary-100': active,
              }"
            >
              <span class="block truncate">
                {{ item[itemText] }}
              </span>
              <span
                v-if="itemSubText && item[itemSubText]"
                class="block text-sm text-gray-500 truncate"
              >
                {{ item[itemSubText] }}
              </span>
              <i
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center justify-around w-10 material-icons-round text-primary-900 place-items-center"
              >
                check
              </i>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script>

import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from "@headlessui/vue"
import WSpinner from "./WSpinner.vue"

export default {
  emits: ["update:modelValue"],
  props: {
    placeholder: String,
    errorMessage: String,
    modelValue: {
      type: null,
      required: true,
    },
    returnKey: String,
    id: String,
    label: String,
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: String,
    itemText: {
      type: String,
      default: "text",
    },
    itemSubText: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: "",
    }
  },

  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },
    _modelValue() {
      return this.returnKey
        ? this.items.find((each) => each[this.returnKey] === this.modelValue)
        : this.modelValue
    },
    filteredItems() {
      if (this.query === "") {
        return this.items
      }

      const query = this.query.toLowerCase().replace(/\s+/g, "")
      return this.items.filter((each) => {
        return (
          (each[this.itemText] &&
            each[this.itemText].toLowerCase().replace(/\s+/g, "").includes(query)) ||
          (this.itemSubText &&
            each[this.itemSubText] &&
            each[this.itemSubText].toLowerCase().replace(/\s+/g, "").includes(query))
        )
      })
    },

    classes() {
      return {
        "bg-gray-100": this.disabled,
        "bg-white": !this.disabled,
      }
    },
  },
  methods: {
    modelValueUpdated(item) {
      const value = this.returnKey ? item[this.returnKey] : item
      this.$emit("update:modelValue", value)
    },
  },

  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    WSpinner,
  },
}
</script>
<style scoped>
.border-style:focus-within {
  border: rgb(220 38 38) solid 1px;
  box-shadow: 0px 0px 0px 1px rgb(220 38 38);
}
[type="text"]:focus {
  border: transparent;
  --tw-ring-color: #0000;
}
</style>
