
import { defineComponent, PropType } from "vue"
import WInputWrapper from "../WInputWrapper.vue"

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    id: String,
    label: String,
    zIndex: String,
    placeholder: String,
    hint: String,
    errorMessage: String,
    placement: {
      type: String,
      default: "bottom",
    },
    mode: {
      type: String,
      default: "time",
    },
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    optional: Boolean,
    masks:{
      type: Object as PropType<{input: string}>,
    }
  },
  watch:{
    date:{
      handler(){
        this.$emit("update:modelValue", this.date)
      }
    }
  },
  data() {
    return {
      search: null as string | null,
      rect: undefined as any,
      selectedItems: [] as Array<any>,
      date: new Date() as Date,
    }
  },
  computed: {
    _id() {
      return this.id ?? this.label?.toLowerCase().replace(/ /g, "")
    },

    dateString(): string{
    if(!this.date) return "";
    return this.date.toLocaleDateString(undefined, {
        hour: "numeric",
        minute: "numeric"
    })
    },


  },
  methods: {
  },
  components: {
    WInputWrapper
  },
})
