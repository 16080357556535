import FileSaver from 'file-saver';
import JSZip from 'jszip';
import * as clipboardy from 'clipboardy';

const plugin = (editor, opts = {}) => {
  const pfx = editor.getConfig('stylePrefix');
  const exportCommandName = 'gjs-export-zip';
  const copyCommandName = 'gjs-copy-html-and-css';

  const btnCopy = document.createElement('button');
  const btnExp = document.createElement('button');

  const config = {
    addBtns: true,
    btnLabel: 'Export to ZIP',
    filenamePfx: 'grapesjs_template',
    filename: undefined,
    done: () => undefined,
    onError: console.error,
    root: {
      css: {
        'style.css': (editor) => editor.getCss(),
      },
      'index.html': (editor) =>
        `<!doctype html>
        <html lang="en">
          <head>
            <meta charset="utf-8">
            <link rel="stylesheet" href="./css/style.css">
          </head>
          <body>${editor.getHtml()}</body>
        </html>`,
    },
    isBinary: undefined,
    ...opts,
  };

  // Add command
  editor.Commands.add(exportCommandName, {
    run(editor, s, opts = {}) {
      const zip = new JSZip();
      const onError = opts.onError || config.onError;
      const root = opts.root || config.root;

      this.createDirectory(zip, root)
        .then(async () => {
          const content = await zip.generateAsync({ type: 'blob' });
          const filenameFn = opts.filename || config.filename;
          const done = opts.done || config.done;
          const filenamePfx = opts.filenamePfx || config.filenamePfx;
          const filename = filenameFn ? filenameFn(editor) : `${filenamePfx}_${Date.now()}.zip`;
          FileSaver.saveAs(content, filename);
          done?.();
        })
        .catch(onError);
    },

    createFile(zip, name, content) {
      const opts = {};
      const ext = name.split('.')[1];
      const isBinary = config.isBinary ?
        config.isBinary(content, name) :
        !(ext && ['html', 'css'].indexOf(ext) >= 0) &&
        // eslint-disable-next-line
        !/^[\x00-\x7F]*$/.test(content);

      if (isBinary) {
        opts.binary = true;
      }

      editor.log(['Create file', { name, content, opts }], { ns: 'plugin-export' });
      zip.file(name, content, opts);
    },

    async createDirectory(zip, root) {
      root = typeof root === 'function' ? await root(editor) : root;

      for (const name in root) {
        // eslint-disable-next-line
        if (root.hasOwnProperty(name)) {
          let content = root[name];
          content = typeof content === 'function' ? await content(editor) : content;
          const typeOf = typeof content;

          if (typeOf === 'string') {
            this.createFile(zip, name, content);
          } else if (typeOf === 'object') {
            const dirRoot = zip.folder(name);
            await this.createDirectory(dirRoot, content);
          }
        }
      }
    },
  });

  editor.Commands.add(copyCommandName, {
    run(editor, s, opts = {}) {
      const html = editor.getHtml()
      const css = editor.getCss()

      clipboardy.write(html + `<style>` + css + `</style>`)
      .then(btnCopy.innerHTML = "Copied!")
      .then(setTimeout(() => { btnCopy.innerHTML = "Copy" }, 2000))
      

    },
  });

  editor.onReady(() => {

    // Add export button inside export dialog
    if (config.addBtns) {
      btnExp.innerHTML = config.btnLabel;
      btnExp.className = `${pfx}btn-prim`;

      btnCopy.innerHTML = "Copy"
      btnCopy.className = `${pfx}btn-prim`;

      editor.on('run:export-template', () => {
        const buttonsContainer = document.createElement("div")
        buttonsContainer.className = "flex flex-row space-x-2"

        buttonsContainer.appendChild(btnExp);
        btnExp.onclick = () => {
          editor.runCommand(exportCommandName);
        };

        buttonsContainer.appendChild(btnCopy);
        btnCopy.onclick = () => {
          editor.runCommand(copyCommandName);
        };
        const el = editor.Modal.getContentEl();
        el?.appendChild(buttonsContainer);
      });
    }

  })
};

export default plugin;