// Query selector
const query = document.querySelector.bind(document)
const queryAll = document.querySelectorAll.bind(document)

// Document ready
function documentReady(eventHandler) {
  if (document.readyState !== 'loading') {
    eventHandler();
  } else {
    document.addEventListener('DOMContentLoaded', eventHandler);
  }
}

// Add event
function addEvent(parent, evt, selector, handler) {
  parent.addEventListener(evt, function(event) {
    if (event.target.matches(selector + ', ' + selector + ' *')) {
      handler.apply(event.target.closest(selector), arguments);
    }
  }, false);
}

// Remove duplicates from array
function unique(array) {
  return array.reduce((unique, o) => {
    if (!unique.some((obj) => obj.displayName === o.displayName && obj.path === o.path && obj.fullPath === o.fullPath)) {
      unique.push(o)
    }
    return unique
  }, [])
}

// Refresh selected component
export function refreshEditorSelected(editor) {
  let selected = editor.getSelected();
  editor.selectRemove(selected);
  setTimeout(function() { editor.select(selected); }, 50);
}

export { query, queryAll, documentReady, addEvent, unique }
