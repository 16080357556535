export default (editor, options) => {
  const domComponents = editor.DomComponents
  const cellsResizable = options.cellsResizable;

  const name = options.nameAdvancedTableCell
  const label = options.labelAdvancedTableCell
  const extendName = options.nameAdvancedTableBase

  domComponents.addType(name, {
    extend: extendName,
    isComponent: el => el.tagName === 'ADVANCEDTABLECELL',
    model: {
      defaults: {
        name: label,
        tagName: 'td',
        resizable: cellsResizable,
        classes: [name],
        // draggable: false,
        // removable: false,
      }
    },
    view: {
      init() {
        const doc = editor.Canvas.getDocument()
        if (!doc) { return }

        const stl = doc?.querySelector(`style#${name}`);
        if (!stl) {
          doc.body.insertAdjacentHTML('beforeend', `
          <style id=${name}>
            .${name}:empty:after {
              content: "${label}";
              height: 100%;
              font-size: large;
              color: gray;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          </style>
          `);
          } 
      },
      events: {
        dblclick: "onDblClick",
      },

      onDblClick() {
        if (this.model.get('components').length === 0) {
          this.model.components().add({ type: 'text', content: 'Text'});
        }
      },
    },
  });
};
