export default (editor, config) => {
  const stylePrefix = editor.getConfig("stylePrefix")
  const modal = editor.Modal
  const codeViewer = editor.CodeManager.getViewer("CodeMirror").clone()
  const container = document.createElement("div")
  let viewerEditor = codeViewer.editor

  // Init left buttons container
  const leftButtonsContainer = document.createElement("div")
  leftButtonsContainer.className = "flex flex-row pt-2 space-x-2"

  // Init import button
  const btnImp = document.createElement("button")
  btnImp.innerHTML = config.modalBtnImport || "Import"
  btnImp.className = stylePrefix + "btn-prim " + stylePrefix + "btn-import"
  btnImp.onclick = () => {
    let code = codeViewer.editor.getValue()
    editor.DomComponents.getWrapper().set("content", "")
    editor.setComponents(code)
    editor.Modal.close()
  }

  // Init import from zip button
  const btnImpZip = document.createElement("button")
  btnImpZip.innerHTML = config.modalBtnImport || "Import from Zip"
  btnImpZip.className = stylePrefix + "btn-prim " + stylePrefix + "btn-import"
  btnImpZip.onclick = () => {
    console.log("Import clicked")
    editor.Modal.close()
  }

  // Init code viewer
  codeViewer.set({
    ...{
      codeName: "htmlmixed",
      theme: "hopscotch",
      readOnly: 0,
    },
    ...config.importViewerOptions,
  })

  return {
    run(editor) {
      if (!viewerEditor) {
        const txtarea = document.createElement("textarea")

        container.appendChild(txtarea)
        leftButtonsContainer.appendChild(btnImp)
        leftButtonsContainer.appendChild(btnImpZip)
        container.appendChild(leftButtonsContainer)
        codeViewer.init(txtarea)
        viewerEditor = codeViewer.editor
      }

      modal.setTitle("Import from HTML / CSS (paste HTML / CSS below)")
      modal.setContent(container)
      const html = config.preHtml ?? "" + editor.getHtml() ?? "" + config.postHtml ?? ""
      const css = config.preCss ?? "" + editor.getCss() ?? "" + config.postCss ?? ""
      const content = `${html} <style>${css}</style>`
      codeViewer.setContent(content || "")
      modal
        .open()
        .getModel()
        // .once("change:open", () => editor.stopCommand(this.id))
      viewerEditor.refresh()
    },

    stop() {
      modal.close()
    },
  }
}
