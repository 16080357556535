import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flow_table = _resolveComponent("flow-table")!
  const _component_routing_key_table = _resolveComponent("routing-key-table")!
  const _component_w_button = _resolveComponent("w-button")!
  const _component_w_table_dialog = _resolveComponent("w-table-dialog")!

  return (_openBlock(), _createBlock(_component_w_table_dialog, {
    persistent: "",
    title: "Import Sample Data from Flow",
    errorMessage: _ctx.getFlowsError,
    showCloseButton: ""
  }, {
    table: _withCtx(() => [
      (_ctx.currentStep === 0)
        ? (_openBlock(), _createBlock(_component_flow_table, {
            key: 0,
            filters: _ctx.filters,
            onClickRow: _ctx.selectFlow
          }, null, 8, ["filters", "onClickRow"]))
        : (_openBlock(), _createBlock(_component_routing_key_table, {
            key: 1,
            keys: _ctx.routingKeys,
            onClickRow: _ctx.selectRoutingKey
          }, null, 8, ["keys", "onClickRow"]))
    ]),
    actions: _withCtx(() => [
      (_ctx.currentStep === 1)
        ? (_openBlock(), _createBlock(_component_w_button, {
            key: 0,
            onClick: _ctx.cancelRoutingKeySelection,
            outlined: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("Previous ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["errorMessage"]))
}