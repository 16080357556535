export default class RenderDocument {

  body: string | null
  subject: string | null
  
  constructor(params: New<RenderDocument>) {
    this.body = params.body ?? null
    this.subject = params.subject ?? null
  }

  static fromResponse(data: any): RenderDocument {
    const render = new RenderDocument({
      body: data.body,
      subject: data.subject,
    })
    return render
  }
}