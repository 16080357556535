export default `
.gjs-dashed [data-gjs-type="advanced-for-each-table"] {outline-color: rgb(16, 161, 157); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="advanced-table-for-each-tbody"] {outline-color: rgb(16, 161, 157); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="advanced-table-for-each-row"] {outline-color: rgb(16, 161, 157); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="for-each-container"] {outline-color: rgb(16, 161, 157); outline-width: 2px; outline-style: dashed;} 

.gjs-dashed [data-gjs-type="conditional-text"] {outline-color: rgb(84, 3, 117); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="advanced-conditional-table"] {outline-color: rgb(84, 3, 117); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="advanced-table-conditional-tbody"] {outline-color: rgb(84, 3, 117); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="advanced-table-conditional-row"] {outline-color: rgb(84, 3, 117); outline-width: 2px; outline-style: dashed;} 

.gjs-dashed [data-gjs-type="advanced-table-thead"] {outline-color: rgb(255, 112, 0); outline-width: 2px; outline-style: dashed;} 
.gjs-dashed [data-gjs-type="advanced-table-tfoot"] {outline-color: rgb(255, 191, 0); outline-width: 2px; outline-style: dashed;} 
`