import onLoadTable from "./onLoadAdvancedTable"

export function onLoad(editor, isNewDoc, docId, docType, templateLite, resetThisTemplateLite, setThisData, setThisTemplateLiteWithTemplate) {
  onLoadTable(editor)
  editor.DomComponents.getWrapper().set("content", "")
  editor.DomComponents.clear()
  editor.setComponents()
  editor.setStyle()
  if (docId) {
    editor.Commands.get("gjs-load-template").run(docId, setThisData, setThisTemplateLiteWithTemplate)
  } else if (isNewDoc) {
    resetThisTemplateLite()
  } else {
    editor.Commands.get("gjs-open-open").run(templateLite, docType)
  }
}
