export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameAdvancedTableConditionalBase
  const label = options.labelAdvancedTableConditionalBase

  domComponents.addType(name, {
    extend: "default",
    isComponent: (el) => el.tagName == "TABLECONDITIONALBASE",
    model: {
      defaults: {
        name: label,
        revertComponent: null,
        conditionObjects: [],
        conditionCode: null,
        selectedConditionIndex: -1,
        isNew: true,
      },
    },
  })
}
