<template>
  <w-section v-if="!localForEachGroups || localForEachGroups.length < 1"> 
    <div class="text-xs italic text-primaryGrapesJs-100">
      Either there is no further data to iterate on, or sample data has not been set.
    </div>
  </w-section>
  <w-form v-else class="min-w-[400px]">
    <w-section>
      <for-each-form-row
        v-for="(componentGroup, index) in temporaryComponentGroups"
        :group="componentGroup"
        :componentGroupOptions="componentGroup.forEachGroups"
        :key="componentGroup.displayName"
        @insertCondition="insertCondition"
        @removeCondition="removeCondition(index)"
      ></for-each-form-row>
      <for-each-form-row 
        new 
        :componentGroupOptions="newComponentGroup.forEachGroups" 
        @insertCondition="insertCondition" 
      ></for-each-form-row>
    </w-section>
    <template #actions>
      <div class="flex flex-row">
        <div class="flex flex-row space-x-4">
          <w-button mode="secondary" @click="$emit('cancel')">Cancel</w-button>
          <w-button @click="applyForEachCondition">Apply</w-button>
        </div>
        <div class="flex flex-1"></div>
      </div>
    </template>
  </w-form>
</template>

<script>
import { WForm } from "@/components/bases/form"
import { WButton } from "@/components/bases/buttons"
import WSection from "@/components/bases/WSection.vue"
import ForEachFormRow from "./ForEachFormRow.vue"
import { getFirstInstance, getForEachGroups } from "../../methods/forEach"

export default {
  emits: ["cancel", "applyForEachCondition"],

  props: {
    data: {
      type: Object
    },
    localGroups: {
      type: Array
    },
    componentGroups: {
      type: Array
    },
  },

  data() {
    return {
      temporaryComponentGroups: this.componentGroups,
      finalComponentGroups: [],

      forEachData: this.data,
      localForEachGroups: this.localGroups,
    }
  },

  computed: {
    newComponentGroup() {
      const lastGroup = this.temporaryComponentGroups[this.temporaryComponentGroups.length - 1]
      
      const forEachGroups = lastGroup 
      ? getForEachGroups(getFirstInstance(this.forEachData, lastGroup.selectedGroup.fullPath), "", lastGroup.selectedGroup.fullPath, lastGroup.variableText) 
      : this.localForEachGroups

      return {
        variableText: "",
        selectedGroup: forEachGroups[0],
        forEachGroups: forEachGroups
      }
    }
  },

  methods: {
    insertCondition(componentGroup) {
      this.temporaryComponentGroups.push(componentGroup)
    },
    removeCondition(index) {
      this.temporaryComponentGroups.splice(index, 1)
    },
    applyForEachCondition() {
      this.finalComponentGroups = this.temporaryComponentGroups.slice()
       if (this.finalComponentGroups.length < 1) {
        confirm("Please enter at least one condition")
        return
      }
      this.$emit("applyForEachCondition", this.finalComponentGroups)
    },
  },

  components: {
    WSection,
    WButton,
    WForm,
    ForEachFormRow
  },
}
</script>
