
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    color: {
      type: String,
      required: true,
    },
    inverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      switch (this.color) {
        case "red":
          return  this.inverse ? `bg-red-600 text-white` :`text-red-900 bg-red-200`
        case "blue":
          return  this.inverse ? `bg-blue-600 text-white` :`text-blue-900 bg-blue-200`
        case "green":
          return this.inverse ? `bg-green-600 text-white` : `text-green-900 bg-green-200`
        case "gray":
          return  this.inverse ? `bg-gray-600 text-white` :`text-gray-900 bg-gray-200`
        case "orange":
          return  this.inverse ? `bg-orange-600 text-white` :`text-orange-900 bg-orange-200`
        case "amber":
          return  this.inverse ? `bg-amber-600 text-white` :`text-amber-900 bg-amber-200`
        case "yellow":
          return  this.inverse ? `bg-yellow-600 text-white` :`text-yellow-900 bg-yellow-200`
        case "lime":
          return  this.inverse ? `bg-lime-600 text-white` :`text-lime-900 bg-lime-200`
        case "teal":
          return  this.inverse ? `bg-teal-600 text-white` :`text-teal-900 bg-teal-200`
        case "cyan":
          return  this.inverse ? `bg-cyan-600 text-white` :`text-cyan-900 bg-cyan-200`
        case "emerald":
          return  this.inverse ? `bg-emerald-600 text-white` :`text-emerald-900 bg-emerald-200`
        case "sky":
          return  this.inverse ? `bg-sky-600 text-white` :`text-sky-900 bg-sky-200`
        case "indigo":
          return  this.inverse ? `bg-indigo-600 text-white` :`text-indigo-900 bg-indigo-200`
        case "violet":
          return  this.inverse ? `bg-violet-600 text-white` :`text-violet-900 bg-violet-200`
        case "purple":
          return  this.inverse ? `bg-purple-600 text-white` :`text-purple-900 bg-purple-200`
        case "fuschia":
          return  this.inverse ? `bg-fuschia-600 text-white` :`text-fuschia-900 bg-fuschia-200`
        case "pink":
          return  this.inverse ? `bg-pink-600 text-white` :`text-pink-900 bg-pink-200`
        case "rose":
          return  this.inverse ? `bg-rose-600 text-white` :`text-rose-900 bg-rose-200`
        default:
          return  this.inverse ? `bg-gray-600 text-white` :`text-gray-900 bg-gray-200`
      }
    },
  },
})
