export default () => {

  async function goToNewUrl(type) {
    let url = `${process.env.VUE_APP_BUILDER_URL}/${type}-builder/new`
    window.open(url, '_blank');
  }

  // Add command
  return {
    run(type) {
      goToNewUrl(type)        
    },
  }
}
