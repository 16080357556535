import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-primaryGrapesJs-900" }
const _hoisted_2 = { class: "w-1/6 m-4" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_text_field = _resolveComponent("w-text-field")!
  const _component_w_sticker = _resolveComponent("w-sticker")!
  const _component_w_data_table = _resolveComponent("w-data-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_w_text_field, {
        id: "searchfield",
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        placeholder: "Search"
      }, {
        leading: _withCtx(({ focus }) => [
          _createElementVNode("span", {
            class: "pl-3 grid place-items-center material-icons-round text-[18px] text-gray-500 select-none cursor-text",
            onClick: ($event: any) => (focus())
          }, "search", 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(_component_w_data_table, {
      headers: _ctx.headers,
      items: _ctx.flows,
      search: _ctx.search,
      loading: !_ctx.flows && !_ctx.fetchErrorMessage,
      class: "m-4",
      onClickRow: _ctx.rowClicked
    }, {
      [`item.priority`]: _withCtx(({ item }) => [
        _createVNode(_component_w_sticker, {
          color: _ctx.getPriorityColor(item.priority),
          inverse: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.priority), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        _createVNode(_component_w_sticker, {
          color: item.status ? 'green' : 'rose',
          inverse: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.status ? "Active" : "Inactive"), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ]),
      _: 2
    }, 1032, ["headers", "items", "search", "loading", "onClickRow"])
  ]))
}