import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flow_table = _resolveComponent("flow-table")!
  const _component_w_table_dialog = _resolveComponent("w-table-dialog")!

  return (_openBlock(), _createBlock(_component_w_table_dialog, {
    persistent: "",
    title: "Import Sample Data from Flow",
    errorMessage: _ctx.importErrorMessage,
    showCloseButton: ""
  }, {
    table: _withCtx(() => [
      _createVNode(_component_flow_table, { onClickRow: _ctx.rowClicked }, null, 8, ["onClickRow"])
    ]),
    _: 1
  }, 8, ["errorMessage"]))
}