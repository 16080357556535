
  import { defineComponent, PropType } from "vue"

  export default defineComponent({
    emits: ["update:modelValue"],
    props: {
      id: String,
      label: String,
      zIndex: String,
      placeholder: String,
      hint: String,
      errorMessage: String,
      placement: {
        type: String,
        default: "right",
      },
      plain: Boolean,
      loading: Boolean,
      disabled: Boolean,
      optional: Boolean,
    },
    watch:{
        range:{
            handler(){
              this.$emit("update:modelValue", this.range)
            },

            deep: true
        }
    },
    data() {
      return {
        search: null as string | null,
        rect: undefined as any,
        selectedItems: [] as Array<any>,

        range: {
            start: {} as Date,
            end: {} as Date,
        } as {start: Date, end: Date},

        masks: {
            input: 'YYYY-MM-DD h:mm A' as string,
        } as {input: string},
      }
    },
    
    
  })
  