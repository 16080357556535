<template>
  <w-button 
    :selected="selectedCase == 'default'" small mode="secondary" class=" text-primaryGrapesJs-100" 
    @click="caseButtonClicked('default')"
    >Default
    </w-button>
    <w-button 
    :selected="selectedCase == 'upper'" small mode="secondary" class="text-primaryGrapesJs-100" 
    @click="caseButtonClicked('upper')"
    >UPPER
    </w-button>
    <w-button 
    :selected="selectedCase == 'lower'" small mode="secondary" class="text-primaryGrapesJs-100" 
    @click="caseButtonClicked('lower')"
    >lower
    </w-button>
    <w-button :selected="selectedCase == 'camel'" small mode="secondary" class="text-primaryGrapesJs-100" 
    @click="caseButtonClicked('camel')"
    >Camel Case
    </w-button>
    <w-button :selected="selectedCase == 'sentence'" small mode="secondary" class="text-primaryGrapesJs-100" 
    @click="caseButtonClicked('sentence')"
    >Sentence case
    </w-button>
</template>

<script>
import { WButton } from "@/components/bases/buttons"

export default {
  emits: ["caseSelected"],
  data() {
    return {
      selectedCase: "default"
    }
  },
  
   computed: {
    textCase() {
      switch (this.selectedCase) {
        case "default": return ""
        case "upper": return "| upcase "
        case "lower": return "| downcase "
        case "camel": return "| camelcase "
        case "sentence": return "| capitalize "
        default: console.log("No such text case")
          return ""
      }
    }
  },

  methods: {
    caseButtonClicked(id) {
      this.selectedCase = id
      this.$emit("caseSelected", this.textCase)
    }
  },
  components: { WButton }
}
</script>