
import { defineComponent, PropType } from "vue"
import RoutingKey from "@/models/flow/RoutingKey"
import WDataTable from "@/components/bases/tables/WDataTable.vue"
import { WTextField } from "@/components/bases/form"
import WSticker from "@/components/bases/misc/WSticker.vue"

export default defineComponent({
  emits: ["clickRow"],
  props: {
    keys: {
      type: Array as PropType<Array<RoutingKey>>,
      required: true
    }
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Description", value: "description", sortable: true, filterable: true, width: 4 },
        { text: "Flow ID", value: "flowId", sortable: true, width: 4 },
        { text: "Status", value: "status", width: 1 },
        { text: "Type", value: "type", width: 1 },

      ],
      fetchErrorMessage: null as null | string,
    }
  },
  methods: {
    async rowClicked(item: RoutingKey) {
      this.$emit('clickRow', item)
    },
  },
  components: {
    WDataTable,
    WTextField,
    WSticker,
  },
})
