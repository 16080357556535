import client from "./_client"
import Template from "@/models/template/Template"
import TemplateLite from "../models/template/TemplateLite"

async function getTemplates(type) {
  try {
    if (!type) {
      const response = await client.get(`template`)
      return response.data.records.map((each) => Template.fromResponse(each))
    } else {
      const response = await client.get(`template?$filter=type eq '${type}'`)
      return response.data.records.map((each) => Template.fromResponse(each))
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function getTemplateLites(type) {
  try {
    if (!type) {
      const response = await client.get(`template?$select=idtemplate,name,description,title,type`)
      return response.data.records.map((each) => TemplateLite.fromResponse(each))
    } else {
      const response = await client.get(`template?$select=idtemplate,name,description,title,type,status&$filter=type eq '${type}'`)
      return response.data.records.map((each) => TemplateLite.fromResponse(each))
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function getTemplate(id) {
  try {
    const response = await client.get(`template(${id})`)
    return Template.fromResponse(response.data)
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function createTemplate(newTemplate) {
  try {
    const headers = { Prefer: "return=representation" }
    const response = await client.post("template", (new Template(newTemplate)).oDataJson(), { headers }, "$select=idtemplate,name,description,title")
    const body = response.data
    return TemplateLite.fromResponse(body)
  } catch (error) {
    console.log(error)
    throw error
  }
}

async function updateTemplate(id, templateChanges) {
  try {
    const headers = { Prefer: "return=representation" }
    const response = await client.patch(`template(${id})`, templateChanges, { headers }, "$select=idtemplate,name,description,title")
    const body = response.data
    return TemplateLite.fromResponse(body)

  } catch (error) {
    console.log(error)
    throw error
  }
}

async function deleteTemplate(id) {
  try {
    await client.delete(`template(${id})`)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export { getTemplates, getTemplateLites, getTemplate, createTemplate, updateTemplate, deleteTemplate }
