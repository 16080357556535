import { query, queryAll } from './miscellaneousHelpers'

export function addTable(editor, tableType) {
  const component = editor.getSelected();
  const coll = component.collection;
  const at = coll.indexOf(component);
  coll.remove(component);

console.log(tableType)
  
  const newComponent = coll.add({
    type: tableType
  }, { at });
  
  editor.select(newComponent)

  editor.Modal.close();
}

// Advanced Table Helpers

export const getAdvancedTableToolbar = (component) => {
  const commandId = "advanced-table-show-table-operations"
  const toolbarIcon = '<svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMin slice"><path fill="currentColor" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>'
  const toolbar = component.get("toolbar")
  const commandExists = toolbar.some((item) => item.command === commandId)

  if (!commandExists && component.get("tagName") !== "body") {
    let tool = { label: toolbarIcon, attributes: { class: 'advanced-table-operations', title: 'Table operations' }, command: commandId }
    toolbar.splice(-4, 0, tool)
  }

  return toolbar;
}

export function updateAdvancedTableToolbarSubmenu (editor, tableHeader, tableBody, tableConditionalBody, tableForEachBody, tableFooter) {
  let selected = editor.getSelected();
  const htmlString = `
    <ul id="toolbar-submenu-advanced-table" class="advanced-table-toolbar-submenu ` + (getPosition(query('.gjs-toolbar')).left > 150 ? 'advanced-table-toolbar-submenu-right' : '') + `" style="display: none;">
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-header" ` + (selected.findType(tableHeader).length > 0 ? 'style="display: none;"' : '') +  `><i class="fa fa-plus" aria-hidden="true"></i> Add table header</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-body" ><i class="fa fa-plus" aria-hidden="true"></i> Add table body</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-conditional-body" ><i class="fa fa-plus" aria-hidden="true"></i> Add conditional body</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-for-each-body" ><i class="fa fa-plus" aria-hidden="true"></i> Add for-each body</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-footer" ` + (selected.findType(tableFooter).length > 0 ? 'style="display: none;"' : '') +  `><i class="fa fa-plus" aria-hidden="true"></i> Add table footer</li>
    </ul>
  `
  query('.advanced-table-operations').parentElement.insertAdjacentHTML('beforeend', htmlString)
  slideDown(query('ul#toolbar-submenu-advanced-table'))
}

export function addHeader(tableComponent, tableHeader){
  const newComponent = tableComponent.components().add({
    type: tableHeader,
  }, { at: 0 });
  return newComponent
}

export function addBody(tableComponent, tableBody, tableFooter){
  const numberOfComponents = tableComponent.components().length
  const index = tableComponent.findType(tableFooter).length > 0 ? numberOfComponents - 1 : numberOfComponents
  const newComponent = tableComponent.components().add({
    type: tableBody
  }, { at: index });
  return newComponent
}

export function addConditionalBody(tableComponent, conditionalBody, tableFooter){
  const numberOfComponents = tableComponent.components().length
  const index = tableComponent.findType(tableFooter).length > 0 ? numberOfComponents - 1 : numberOfComponents
  const newComponent = tableComponent.components().add({
    type: conditionalBody
  }, { at: index });
  return newComponent
}

export function addForEachBody(tableComponent, forEachBody, tableFooter){
  const numberOfComponents = tableComponent.components().length
  const index = tableComponent.findType(tableFooter).length > 0 ? numberOfComponents - 1 : numberOfComponents
  const newComponent = tableComponent.components().add({
    type: forEachBody
  }, { at: index });
  return newComponent
}

export function addFooter(tableComponent, tableFooter){
  const numberOfComponents = tableComponent.components().length
  const newComponent = tableComponent.components().add({
    type: tableFooter
  }, { at: numberOfComponents });
  return newComponent
}

// Advanced Table Header, Body and Footer Helpers

export const getAdvancedTableBodyToolbar = (component) => {
  const commandId = "advanced-table-show-body-operations"
  const toolbarIcon = '<svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMin slice"><path fill="currentColor" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>'
  const toolbar = component.get("toolbar")
  const commandExists = toolbar.some((item) => item.command === commandId)

  if (!commandExists && component.get("tagName") !== "body") {
    let tool = { label: toolbarIcon, attributes: { class: 'advanced-table-body-operations', title: 'Add row operations' }, command: commandId }
    toolbar.splice(-4, 0, tool)
  }

  return toolbar;
}

export function updateAdvancedTableBodyToolbarSubmenu (editor, tableRow, tableForEachRow) {
  let selected = editor.getSelected();
  const htmlString = `
    <ul id="toolbar-submenu-advanced-table-body" class="advanced-table-toolbar-submenu ` + (getPosition(query('.gjs-toolbar')).left > 150 ? 'advanced-table-toolbar-submenu-right' : '') + `" style="display: none;">
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-rows" ><i class="fa fa-plus" aria-hidden="true"></i> Add table rows...</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-conditional-row" ><i class="fa fa-plus" aria-hidden="true"></i> Add conditional row</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-for-each-row" ><i class="fa fa-plus" aria-hidden="true"></i> Add for-each row</li>
    </ul>
  `
  query('.advanced-table-body-operations').parentElement.insertAdjacentHTML('beforeend', htmlString)
  slideDown(query('ul#toolbar-submenu-advanced-table-body'))
}

export function addConditionalRow(tableComponent, conditionalRow){
  const newComponent = tableComponent.components().add({
    type: conditionalRow,
  });
  return newComponent
}

export function addForEachRow(tableComponent, forEachRow){
  const newComponent = tableComponent.components().add({
    type: forEachRow,
  });
  return newComponent
}

export function insertColumn(tableComponent, addAtIndex, componentCell, componentCellHeader, updateProps = false){
  tableComponent.components().forEach((component, index) => {
    if(index === 0 && tableComponent.props().hasHeaders) {
      component.components().add({ type: componentCellHeader }, {at: addAtIndex});
    } else {
      component.components().add({ type: componentCell }, {at: addAtIndex});
    }
  });

  if(updateProps){
    tableComponent.set({nColumns: Number(tableComponent.props().nColumns) + 1})
  }
}

export function insertRow(tableComponent, addAtIndex, componentRow, componentCell, updateProps = false){
  tableComponent.components().add({
    type: componentRow,
    components: [...Array(tableComponent.components().at(0).components().length).keys()].map(() => ({ type: componentCell }))
  }, {
    at: addAtIndex
  });

  if(updateProps){
    tableComponent.set({nRows: Number(tableComponent.props().nRows) + 1})
  }
}

export function removeColumn(tableComponent, removeAtIndex, updateProps = false) {
  tableComponent.components().forEach(component => {
    component.components().at(removeAtIndex).remove();
  });
  if(updateProps){
    tableComponent.set({nColumns: Number(tableComponent.props().nColumns) - 1})
  }
}

export function removeRow(tableComponent, removeAtIndex, updateProps = false) {
  tableComponent.components().at(removeAtIndex).remove()
  if(updateProps){
    tableComponent.set({nRows: Number(tableComponent.props().nRows) - 1})
  }
}

export function toggleHeaderRow(tableComponent, componentRow, componentCellHeader, updateProps = false){
  let toggleOn = updateProps == false? tableComponent.props().hasHeaders: !tableComponent.props().hasHeaders;
  if(toggleOn) {
    let headers = [];
    for (let index = 0; index < tableComponent.props().nColumns; index++) {
      headers.push({ type: componentCellHeader });
    }
    tableComponent.components().add({ type: componentRow, components: headers }, { at: 0 });
  } else {
    tableComponent.components().at(0).remove()
  }
  if(updateProps){
    tableComponent.set({hasHeaders: toggleOn})
  }
}

export function highlightCellsWithSize(table) {
  table.components().forEach(row => {
    row.components().forEach(cell => {
      let cellStyle = cell.getStyle();
      if (cellStyle && (cellStyle.width || cellStyle.height)) {
        if (cell.getClasses().includes('advanced-table-cell-highlight')) {
          cell.removeClass('advanced-table-cell-highlight');
        } else {
          cell.addClass('advanced-table-cell-highlight');
        }
      }
    });
  });
}

export function clearCellsWithSize(table) {
  table.components().forEach(row => {
    row.components().forEach(cell => {
      let cellStyle = cell.getStyle();
      if (cellStyle) {
        if (cellStyle.width) {
          cell.removeStyle('width');
        }
        if (cellStyle.height) {
          cell.removeStyle('height');
        }
        if (cell.getClasses().includes('advanced-table-cell-highlight')) {
          cell.removeClass('advanced-table-cell-highlight');
        }
      }
    });
  });
}

export function updateBodyAttributesAndCloseModal (editor, componentId) {
  let nRows = document.getElementById('nRows').value;
  let nColumns = document.getElementById('nColumns').value;
  let hasHeaders = document.getElementById('hasHeaders').checked

  if (nRows && nColumns && nColumns > 0) {
    let tableModel = getAllComponents(editor.getWrapper()).find(model => model.cid == componentId);
    tableModel.props().nRows = nRows;
    tableModel.props().nColumns = nColumns;
    tableModel.props().hasHeaders = hasHeaders
    tableModel.addRowsAndCells()
  } else {
    let tableModel = getAllComponents(editor.getWrapper()).find(model => model.cid == componentId);
    alert('Missing number of columns.');
    tableModel.remove();
  }
  editor.Modal.close();
}

// Advanced Table Row Helpers

export const getAdvancedTableRowToolbar = (component) => {
  const commandId = "advanced-table-show-row-operations"
  const toolbarIcon = '<svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMin slice"><path fill="currentColor" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>'
  const toolbar = component.get("toolbar")
  const commandExists = toolbar.some((item) => item.command === commandId)

  if (!commandExists && component.get("tagName") !== "body") {
    let tool = { label: toolbarIcon, attributes: { class: 'advanced-table-row-operations', title: 'Add cell operations' }, command: commandId }
    toolbar.splice(-4, 0, tool)
  }

  return toolbar;
}

export function updateAdvancedTableRowToolbarSubmenu (editor, tableCell) {
  let selected = editor.getSelected();
  const htmlString = `
    <ul id="toolbar-submenu-advanced-table-row" class="advanced-table-toolbar-submenu ` + (getPosition(query('.gjs-toolbar')).left > 150 ? 'advanced-table-toolbar-submenu-right' : '') + `" style="display: none;">
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-cells" ><i class="fa fa-plus" aria-hidden="true"></i> Add cells...</li>
    </ul>
  `
  query('.advanced-table-row-operations').parentElement.insertAdjacentHTML('beforeend', htmlString)
  slideDown(query('ul#toolbar-submenu-advanced-table-row'))
}

export function insertCell(tableComponent, addAtIndex, componentCell, updateProps = false){
  tableComponent.components().add({ type: componentCell }, {at: addAtIndex});
  if(updateProps){
    tableComponent.set({nCells: Number(tableComponent.props().nCells) + 1})
  }
}

export function removeCell(tableComponent, removeAtIndex, updateProps = false) {
  tableComponent.components().at(removeAtIndex).remove();
  if(updateProps){
    tableComponent.set({nColumns: Number(tableComponent.props().nCells) - 1})
  }
}

export function updateRowAttributesAndCloseModal (editor, componentId) { // Added editor here
  let nCells = document.getElementById('nCells').value;
  let isHeaderCells = document.getElementById('isHeaderCells').checked

  if (nCells && nCells > 0) {
    let tableModel = getAllComponents(editor.getWrapper()).find(model => model.cid == componentId);
    tableModel.props().nCells = nCells;
    tableModel.props().isHeaderCells = isHeaderCells
    tableModel.addCells()
  } else {
    let tableModel = getAllComponents(editor.getWrapper()).find(model => model.cid == componentId);
    alert('Missing number of cells.');
    tableModel.remove();
  }
  editor.Modal.close();
}


// Advanced Table Cell Toolbar

export const getAdvancedTableCellToolbar = (component) => { // Added editor her

  const addTextCommandId = "advanced-table-show-cell-operations"
  const toolbarIcon = '<svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMin slice"><path fill="currentColor" d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/></svg>'
  const toolbar = component.get("toolbar")
  console.log(toolbar)
  const commandExists = toolbar.some((item) => item.command === addTextCommandId)

  if (!commandExists && component.get("tagName") !== "body") {
    let tools = [
      { label: toolbarIcon, attributes: { class: 'advanced-table-cell-operations', title: 'Add text operations' }, command: addTextCommandId },
      { attributes: { class: 'advanced-table-column-actions advanced-table-columns-operations', title: 'Columns operations' }, command: 'advanced-table-show-columns-operations' },
      { attributes: { class: 'advanced-table-row-actions advanced-table-rows-operations', title: 'Rows operations' }, command: 'advanced-table-show-rows-operations' },
    ]
    toolbar.splice(-4, 0, ...tools)
    if (component.getAttributes()['colspan'] > 1 || component.getAttributes()['rowspan'] > 1) {
      toolbar.splice(-4, 0, ({ attributes: { class: 'fa fa fa-th-large', title: 'Unmerge cells' }, command: 'advanced-table-unmerge-cells' }))
    }
  }

  return toolbar;
}

// export const getAdvancedTableCellToolbar = (component) => { // Added editor here
//   let toolbar = [
//     { attributes: { class: 'advanced-table-column-actions advanced-table-columns-operations', title: 'Columns operations' }, command: 'advanced-table-show-columns-operations' },
//     { attributes: { class: 'advanced-table-row-actions advanced-table-rows-operations', title: 'Rows operations' }, command: 'advanced-table-show-rows-operations' },
//     { attributes: { class: 'fa fa-arrow-up', title: 'Select parent component' }, command: 'advanced-table-select' }
//   ]
//   if (component.getAttributes()['colspan'] > 1 || component.getAttributes()['rowspan'] > 1) {
//     toolbar.push({ attributes: { class: 'fa fa fa-th-large', title: 'Unmerge cells' }, command: 'advanced-table-unmerge-cells' })
//   }
//   return toolbar;
// }

// WHEN THIS IS USED, MAKE ALL REFERENCES UNIQUE AND USE REFERENCES FROM COMMANDS/ADVANCEDTABLE E.G. 'ADVANCED-TABLE-INSERT-ROW-ABOVE'

export function updateAdvancedTableCellToolbarSubmenu (editor, text, conditionalText) {
  let selected = editor.getSelected();
  const htmlString = `
    <ul id="toolbar-submenu-advanced-table-cell" class="advanced-table-toolbar-submenu ` + (getPosition(query('.gjs-toolbar')).left > 150 ? 'advanced-table-toolbar-submenu-right' : '') + `" style="display: none;">
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-text" ><i class="fa fa-plus" aria-hidden="true"></i> Add text</li>
      <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-add-conditional-text" ><i class="fa fa-plus" aria-hidden="true"></i> Add conditional text</li>
    </ul>
  `
  query('.advanced-table-cell-operations').parentElement.insertAdjacentHTML('beforeend', htmlString)
  slideDown(query('ul#toolbar-submenu-advanced-table-cell'))
}

export function addText(tableComponent, textComponent){
  console.log("cell: ", tableComponent.components().length)
  const newComponent = tableComponent.components().add({
    type: textComponent,
    content: "Text",
    style: { padding: "10px" }
  });
  return newComponent
}

export function addConditionalText(tableComponent, conditionalTextComponent){
  console.log("cells: ", tableComponent.components().length)
  const newComponent = tableComponent.components().add({
    type: conditionalTextComponent,
    style: { padding: '10px' },
  });
  return newComponent
}

export function updateAdvancedTableCellToolbarSubmenuRowsColumns (editor, submenuToShow, submenuToHide, componentCell, componentCellHeader) {
  let selected = editor.getSelected();
  let currentMenu = queryAll('ul#advanced-table-toolbar-submenu-'+submenuToShow)
  if(currentMenu.length > 0){
    slideUp(query('.advanced-table-toolbar-submenu'))
    slideDown(query('ul#advanced-table-toolbar-submenu-'+submenuToShow))
  } else {
    if (selected && (selected.is(componentCell) || selected.is(componentCellHeader))) {
      let rowComponent = selected.parent();
      if (queryAll('.advanced-table-' + submenuToHide + '-operations .advanced-table-toolbar-submenu').length > 0) {
        slideUp(queryAll('.advanced-table-' + submenuToHide + '-operations .advanced-table-toolbar-submenu'))
      }
      if (queryAll('.advanced-table-' + submenuToShow + '-operations .advanced-table-toolbar-submenu').length > 0) {
        if (getStyle(queryAll('.advanced-table-' + submenuToShow + '-operations .advanced-table-toolbar-submenu'), 'display') != 'none') {
          slideUp(queryAll('.advanced-table-' + submenuToShow + '-operations .advanced-table-toolbar-submenu'))
          return;
        }
        slideDown(query('.advanced-table-' + submenuToShow + '-operations .advanced-table-toolbar-submenu'))
      } else {
        let htmlString = '';
        if (submenuToShow === 'rows') {
          htmlString = `
          <ul id="advanced-table-toolbar-submenu-rows" class="advanced-table-toolbar-submenu ` + (getPosition(query('.gjs-toolbar')).left > 150 ? 'advanced-table-toolbar-submenu-right' : '') + `" style="display: none;">
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-insert-row-above" ` + (selected.is(componentCellHeader) ? 'style="display: none;"' : '') + `><i class="fa fa-chevron-up" aria-hidden="true"></i> Insert row above</li>
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-insert-row-below" ><i class="fa fa-chevron-down" aria-hidden="true"></i> Insert row below</li>
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-delete-row" `+ (selected.is(componentCellHeader) ? 'style="display: none;"' : '') +` ><i class="fa fa-trash" aria-hidden="true"></i> Delete Row</li>
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-toggle-header" `+ (selected.is(componentCell) ? 'style="display: none;"' : '') +`><i class="fa fa-trash" aria-hidden="true"></i> Remove Header</li>
            <li id="advanced-table-button-merge-cells-right" class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-merge-cells-right" ` + (selected.collection.indexOf(selected) + 1 == selected.parent().components().length ? 'style="display: none;"' : '') + `><i class="fa fa-arrows-h" aria-hidden="true"></i> Merge cell right</li>
          </ul>
          `
        } else {
          let rowspan = selected.getAttributes()['rowspan'] ? selected.getAttributes()['rowspan'] : 0;

          htmlString = `
          <ul id="advanced-table-toolbar-submenu-columns" class="advanced-table-toolbar-submenu ` + (getPosition(query('.gjs-toolbar')).left > 150 ? 'advanced-table-toolbar-submenu-right' : '') + `" style="display: none;">
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-insert-column-left" ><i class="fa fa-chevron-left" aria-hidden="true"></i> Insert column left</li>
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-insert-column-right" ><i class="fa fa-chevron-right" aria-hidden="true"></i> Insert column right</li>
            <li class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-delete-column" ><i class="fa fa-trash" aria-hidden="true"></i> Delete column</li>
            <li id="advanced-table-button-merge-cells-down" class="advanced-table-toolbar-submenu-run-command" data-command="advanced-table-merge-cells-down" ` + (rowComponent.collection.indexOf(rowComponent) + rowspan == rowComponent.parent().components().length || selected.is(componentCellHeader) ? 'style="display: none;"' : '') + `><i class="fa fa-arrows-v" aria-hidden="true"></i> Merge cell down</li>
          </ul>
          `
        }
        if (query('.advanced-table-toolbar-submenu')) {
          slideUp(query('.advanced-table-toolbar-submenu'))
        }
        query('.advanced-table-' + submenuToShow + '-operations').parentElement.insertAdjacentHTML('beforeend', htmlString)
        slideDown(query('ul#advanced-table-toolbar-submenu-'+submenuToShow))
      }
    }
  }
}

// Miscellaneous

function slideUp(el) {
  el.style.display = 'none'
}

function slideDown(el) {
  el.style.display = ''
}

function getStyle(el, specificStyle) {
  const win = el.ownerDocument.defaultView
  win.getComputedStyle(el, null)[specificStyle]
}

function getPosition(el) {
  return { left: el.offsetLeft, top: el.offsetTop }
}

export function getAllComponents (model, result = []) {
  result.push(model);
  model.components().each(mod => getAllComponents(mod, result))
  return result;
}
