<template>
  <div class="flex flex-col w-full h-screen">
    <div class="flex justify-between w-full h-10 p-0 panel__top">
      <span class="flex flex-row">
        <div class="mt-2 ml-3">
          <a href="https://wiresk.com" target="_blank">
            <img class="h-5" src="@/assets/logos/wiresk.png" />
          </a>
        </div>
        <div class="relative ml-1 panel__basic-actions"></div>
        <div class="flex items-center pl-4 place-items-center">
          <span v-if="templateLite" :key="templateLite.name" class="font-extralight">{{
            templateLite.name
          }}</span>
        </div>
      </span>
      <div class="relative panel__devices"></div>
      <div>
        <div class="relative panel__toolbar"></div>
        <div class="relative w-64 px-4 panel__switcher"></div>
      </div>
    </div>
    <div class="flex items-stretch justify-start flex-auto editor-row">
      <div class="flex-grow editor-canvas">
        <div id="gjs"></div>
      </div>
      <div class="relative w-64 top-10 h-[calc(100vh_-_40px)] overflow-scroll panel__right">
        <div class="layers-container"></div>
        <div class="space-y-3 divide-y divide-gray-700 styles-container">
          <div class="styles-traits-container"></div>
          <div class="styles-selector-container"></div>
          <div
            class="relative z-20 px-2 py-3 space-y-6 divide-y divide-gray-700 styles-conditional-container"
          >
            <variables-section
              :globalVariables="globalVariables"
              :localVariables="localVariables"
              @localVariableSelected="selectLocalVariable"
              @globalVariableSelected="selectGlobalVariable"
              :key="localVariables"
            ></variables-section>
            <conditionals-section
              v-if="selectedComponentIsConditional"
              :componentType="conditionalComponentType"
              :variables="localAndGlobalVariables"
              :showDialog="showConditionalsDialog"
              :ifControlBlock="getIfControlBlock"
              :selectedConditionIndex="selectedIfConditionIndex"
              :key="selectedComponent"
              @applyCondition="applyIfCondition"
              @selectCondition="selectIfCondition"
              @dialogClosed="conditionalsDialogClosed"
              @dialogCancelled="conditionalsDialogCancelled"
            ></conditionals-section>
            <for-each-section
              v-if="selectedComponentIsForEach"
              :data="forEachData"
              :localGroups="localForEachGroups"
              :componentGroups="componentForEachGroups"
              :showDialog="showForEachDialog"
              :key="selectedComponent"
              @applyCondition="applyForEachCondition"
              @dialogClosed="forEachDialogClosed"
              @dialogCancelled="forEachDialogCancelled"
            ></for-each-section>
            <render-dialog
              v-model="showRenderDialog"
              :docType="docType"
              :request="renderRequest"
            ></render-dialog>
            <flow-dialog v-model="showFlowDialog" @rowClicked="importDataFromFlow"></flow-dialog>
            <flow-to-form-action-dialog
              v-model="showAddFlowToFormActionDialog"
              @linkCreated="addLinkToFormAction"
            ></flow-to-form-action-dialog>
          </div>
          <div class="relative z-10 pt-3 styles-styles-container"></div>
        </div>
        <div class="blocks-container" id="blocks-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import grapesjs from "grapesjs"
import documentBuilderPlugins from "./documentbuilder/plugins"
import formBuilderPlugins from "./formbuilder/plugins"
import "grapesjs/dist/css/grapes.min.css"
import "grapesjs/dist/grapes.min.js"
import canvasCssString from "./shared/styles/canvas.js"

import storageManagerDefaults from "./shared/storageManager"
import devicesDefaults from "./shared/devices"
import panelsDefaults from "./shared/panels"
import {
  onComponentSelected,
  onComponentAdd,
  onComponentRemove,
  onLoad,
  onDisableRte,
} from "./shared/events"
import { addCustomStyleManagerProperties } from "./shared/styleManager"

import {
  openCreateBlankCommand,
  clearCanvasCommand,
  importTemplateCommand,
  showLayersCommand,
  showStylesCommand,
  showBlocksCommand,
  setDeviceDesktopCommand,
  setDeviceTabletCommand,
  setDeviceMobileCommand,
  convertToConditionalCommand,
  openConditionalsCommand,
  openforEachCommand,
} from "./shared/commands/index"

import VariablesSection from "./shared/sections/variables/VariablesSection.vue"
import ConditionalsSection from "./shared/sections/conditionals/ConditionalsSection.vue"
import ForEachSection from "./shared/sections/for_each/ForEachSection.vue"

import RenderDialog from "../renderers/RenderDialog.vue"
import RenderRequest from "@/models/render/RenderRequest"

import IfControlBlock from "./shared/sections/conditionals/models/IfControlBlock"

import FlowDialog from "../flow/FlowDialog.vue"
import FlowToFormActionDialog from "../flow/FlowToFormActionDialog.vue"

import { getLocalVariables, getVariables } from "./shared/methods/variables"
import {
  // setConditionalTextProperties,
  setConditionalComponentProperties,
  setConditionalTextDisplay,
  removeConditionalIfNew,
} from "./shared/methods/conditionals"
import {
  getLocalForEachGroups,
  setForEachProperties,
  removeForEachIfNew,
} from "./shared/methods/forEach"

import TemplateLite from "@/models/template/TemplateLite"

import { refreshEditorSelected } from "@/helpers/miscellaneousHelpers"

let editor = null // Have to declare outside of Vue instance to avoid crashing upon hovering over conditional text

export default {
  emits: ["change"],
  props: {
    new: Boolean,
    templateId: String,
    documentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      templateLite: null,
      id: this.templateId,
      docType: this.documentType,
      isNewDoc: this.new,

      data: null,
      selectedComponent: null,

      showConditionalsDialog: false,

      showForEachDialog: false,

      selectedGlobalVariable: null,
      localVariablesArray: null,
      selectedLocalVariable: null,
      selectedTextCase: null,

      renderId: null,
      renderRequest: null,
      showRenderDialog: false,

      showFlowDialog: false,
      showAddFlowToFormActionDialog: false,
    }
  },
  methods: {
    change() {
      this.$emit("change", editor.getHtml())
    },

    // TEMPLATELITE METHODS

    setThisTemplateLite(templateLite) {
      this.templateLite = templateLite
      this.setDocumentTitle(templateLite.name)
    },

    setThisTemplateLiteWithTemplate(template) {
      this.templateLite = TemplateLite.fromTemplate(template)
      this.setDocumentTitle(template.name)
    },

    resetThisTemplateLite() {
      this.templateLite = null
      this.setDocumentTitle("Untitled*")
    },

    setDocumentTitle(title) {
      document.title = title
    },

    // DATA METHODS

    setThisData(data) {
      this.data = data
    },

    // IMPORT FROM FLOW METHODS

    openFlowImport() {
      this.showFlowDialog = true
    },

    importDataFromFlow(data) {
      this.showFlowDialog = false
      const decodedJson = data ? JSON.parse(data) : null
      editor.Commands.get("gjs-open-set-sample-data").run(
        this.setThisData,
        decodedJson,
        this.openFlowImport
      )
    },

    addLinkToFormAction(link) {
      this.showAddFlowToFormActionDialog = false
      editor.getSelected().addAttributes({ action: link })
    },

    // VARIABLES METHODS

    selectLocalVariable(variable, textCase) {
      this.selectedLocalVariable = variable
      this.selectedTextCase = textCase
    },

    selectGlobalVariable(variable, textCase) {
      this.selectedGlobalVariable = variable
      this.selectedTextCase = textCase
    },

    // CONDITIONAL TEXT METHODS

    applyIfCondition(ifControlBlock) {
      setConditionalComponentProperties(editor.getSelected(), ifControlBlock)
    },

    selectIfCondition(selectedIndex) {
      setConditionalTextDisplay(editor.getSelected(), selectedIndex)
    },

    conditionalsDialogClosed() {
      removeConditionalIfNew(editor.getSelected())
    },

    conditionalsDialogCancelled() {
      removeConditionalIfNew(editor.getSelected())
    },

    // FOREACH METHODS

    applyForEachCondition(forEachGroups) {
      setForEachProperties(editor.getSelected(), forEachGroups)
      refreshEditorSelected(editor)
    },

    forEachDialogClosed() {
      removeForEachIfNew(editor.getSelected())
    },

    forEachDialogCancelled() {
      removeForEachIfNew(editor.getSelected())
    },

    // DOCUMENT RENDERER METHODS

    setRenderRequest(id, data, html, css) {
      this.renderRequest = RenderRequest.fromRawData(
        this.docType == "document",
        id,
        html,
        css,
        data
      )
      this.$nextTick(() => {
        this.showRenderDialog = true
      })
    },
  },

  computed: {
    // VARIABLES COMPUTED PROPERTIES

    globalVariables() {
      if (!this.data) return []
      return getVariables(this.data, "")
    },
    localVariables() {
      return getLocalVariables(this.data, this.selectedComponent)
    },

    localAndGlobalVariables() {
      return this.localVariables
        ? [...this.localVariables, ...this.globalVariables]
        : [...this.globalVariables]
    },

    // CONDITIONALS COMPUTED PROPERTIES

    selectedComponentIsConditional() {
      return (
        this.selectedComponent &&
        (this.selectedComponent.isInstanceOf("conditional-text") ||
          this.selectedComponent.isInstanceOf("advanced-table-conditional-base"))
      )
    },

    conditionalComponentType() {
      if (this.selectedComponent.isInstanceOf("conditional-text")) {
        return "conditional-text"
      } else if (this.selectedComponent.isInstanceOf("advanced-table-conditional-base")) {
        return "advanced-table-conditional-base"
      } else {
        return null
      }
    },

    getIfControlBlock() {
      const block = this.selectedComponent.get("ifControlBlock")
      return this.selectedComponent.get("isNew")
        ? IfControlBlock.create()
        : IfControlBlock.decode(block)
    },
    getConditionalLiquidOperators() {
      return this.selectedComponent.get("isNew")
        ? []
        : this.selectedComponent.get("liquidOperators")
    },
    selectedIfConditionIndex() {
      return this.selectedComponent.get("selectedConditionIndex")
    },

    // FOREACH COMPUTED PROPERTIES

    forEachData() {
      return this.data
    },

    componentForEachGroups() {
      if (this.selectedComponent.get("isNew")) return []
      return this.selectedComponent.get("forEachGroups")
    },

    localForEachGroups() {
      return getLocalForEachGroups(this.data, this.selectedComponent)
    },

    selectedComponentIsForEach() {
      return this.selectedComponent && this.selectedComponent.isInstanceOf("for-each-base")
    },
  },

  mounted() {
    const docType = this.documentType
    const docId = this.id
    this.$nextTick(() => {
      if (editor == null) {
        editor = grapesjs.init({
          container: "#gjs",
          fromElement: true,
          height: "100vh",
          width: "auto",
          canvasCss: canvasCssString, // Add outlines to advanced components
          modal: {
            backdrop: false,
          },
          storageManager: storageManagerDefaults,
          deviceManager: devicesDefaults,
          layerManager: {
            appendTo: ".layers-container",
          },
          panels: panelsDefaults,
          blockManager: {
            appendTo: ".blocks-container",
          },
          selectorManager: {
            componentFirst: true,
            appendTo: ".styles-selector-container",
          },
          styleManager: {
            appendTo: ".styles-styles-container",
          },
          traitManager: {
            appendTo: ".styles-traits-container",
          },
          commands: {
            defaults: [
              // File Commands
              openCreateBlankCommand(this.docType),
              clearCanvasCommand(),
              importTemplateCommand(),

              // N.B. No way to add the following commands to a separate file since this.data etc. are dynamic
              {
                id: "open-save-as",
                run: (editor, sender) => {
                  sender.set("active", false)
                  editor.Commands.get("gjs-open-save").run(docType, this.templateLite, this.data)
                },
              },
              {
                id: "open-open-template",
                run: (editor, sender) => {
                  sender.set("active", false)
                  editor.Commands.get("gjs-open-open").run(this.templateLite, this.docType)
                },
              },
              {
                id: "open-set-sample-data",
                run: (editor, sender) => {
                  sender.set("active", false)
                  editor.Commands.get("gjs-open-set-sample-data").run(
                    this.setThisData,
                    this.data,
                    this.openFlowImport
                  )
                },
              },

              {
                id: "open-add-flow-to-form-action",
                run: () => {
                  this.showAddFlowToFormActionDialog = true
                },
              },

              // Panel Switcher
              showLayersCommand(),
              showStylesCommand(),
              showBlocksCommand(),

              // Devices
              setDeviceDesktopCommand(),
              setDeviceTabletCommand(),
              setDeviceMobileCommand(),

              // Toolbar
              {
                id: "open-render",
                run: (editor, sender) => {
                  sender.set("active", false)
                  this.renderId = this.id
                  const html = editor.getHtml()
                  const css = editor.getCss()
                  this.renderRequest = RenderRequest.fromRawData(
                    docType == "document",
                    this.templateLite ? this.templateLite.id : null,
                    html,
                    css,
                    this.data,
                    this.templateLite ? this.templateLite.title : "Untitled*"
                  )
                  this.showRenderDialog = true
                },
              },
              // Selected Component Toolbar
              convertToConditionalCommand(),

              // Conditionals and For Each
              openConditionalsCommand(this),
              openforEachCommand(this),
            ],
          },
          plugins: this.documentType == "form" ? [formBuilderPlugins] : [documentBuilderPlugins],
          pluginsOpts:
            this.documentType == "form"
              ? { formBuilderPlugins: {} }
              : { documentBuilderPlugins: {} },
        })

        // ADD VARIABLES TO RICH TEXT EDITOR

        var rte = editor.RichTextEditor

        rte.add("insert-local-variable", {
          icon: "<b>LV</b>",
          attributes: { title: "Insert Local Variable" },
          result: (rte) => {
            if (this.selectedLocalVariable) {
              rte.exec("insertText", `{{ ${this.selectedLocalVariable} ${this.selectedTextCase} }}`) // Convert to liquid format
            }
          },
        })

        rte.add("insert-global-variable", {
          icon: "<b>GV</b>",
          attributes: { title: "Insert Global Variable" },
          result: (rte) => {
            if (this.selectedGlobalVariable) {
              rte.exec(
                "insertText",
                `{{ ${this.selectedGlobalVariable} ${this.selectedTextCase} }}`
              ) // Convert to liquid format
            }
          },
        })

        // EDITOR EVENTS METHODS

        editor.on("change", this.change)

        editor.on("component:add", (component) => {
          onComponentAdd(editor, component)
        })

        editor.on("component:remove", (component) => {
          onComponentRemove(editor, component)
        })

        editor.on("component:selected", (component) => {
          this.selectedComponent = component
          onComponentSelected(editor, component)
        })

        editor.on("component:deselected", () => {
          this.selectedComponent = null
        })

        editor.on("load", () => {
          onLoad(
            editor,
            this.isNewDoc,
            docId,
            docType,
            this.templateLite,
            this.resetThisTemplateLite,
            this.setThisData,
            this.setThisTemplateLiteWithTemplate
          )
        })

        editor.on("rte:disable", () => {
          onDisableRte(editor)
        })

        // ADD PROPERTIES TO STYLE MANAGER

        addCustomStyleManagerProperties(editor)
      }
    })
  },
  components: {
    VariablesSection,
    ConditionalsSection,
    ForEachSection,
    RenderDialog,
    FlowDialog,
    FlowToFormActionDialog,
  },
}
</script>

<style src="./shared/styles/index.css"></style>
