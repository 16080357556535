import client from "./_client"
import { useCookies } from "vue3-cookies"

const { cookies } = useCookies()

function setTokens(tokens) {
  const requestTokenExpiration = new Date(tokens.requestToken_Expiration * 1000)
  cookies.set("requestToken", tokens.requestToken, requestTokenExpiration)
  const renewTokenExpiration = new Date(tokens.renewToken_Expiration * 1000)
  cookies.set("renewToken", tokens.renewToken, renewTokenExpiration)
}

function removeTokens() {
  cookies.remove("requestToken")
  cookies.remove("renewToken")
}

async function renewTokens(renewToken) {
  try {
    return (await client.post("renewToken", { longToken: renewToken })).data
  } catch {
    return null
  }
}

async function getRequestToken() {
  const requestToken = cookies.get("impersonatedRequestToken") ?? cookies.get("requestToken")
  if (requestToken) {
    return requestToken
  }

  const renewToken = cookies.get("impersonatedRenewToken") ?? cookies.get("renewToken")
  if (!renewToken) {
    return null
  }

  const tokens = await renewTokens(renewToken)
  if (tokens) {
    setTokens(tokens)
    return tokens.requestToken
  } else {
    removeTokens()
    return null
  }
}

async function isAuthed() {
  return !!(await getRequestToken())
}

async function register(registrationData) {
  const data = {
    tenant_name: registrationData.tenantName,
    // address_line1: registrationData.address.line1,
    // address_line2: registrationData.address.line2,
    // address_postcode: registrationData.address.postcode,
    // address_country: registrationData.address.country,
    // address_city: registrationData.address.city,
    contact_email: registrationData.contactEmail,
    firstname: registrationData.firstName,
    lastname: registrationData.lastName,
    email: registrationData.email,
    password: registrationData.password,
  }
  console.log(data)
  await client.post("register", data)
  await login({
    email: data.email,
    password: data.password,
    mfaCode: null,
  })
}

async function login(loginData) {
  const data = {
    email: loginData.email,
    password: loginData.password,
    code: loginData.mfaCode,
  }
  const tokens = (await client.post("login", data)).data
  setTokens(tokens)
}

async function preEnableMfa() {
  const qrCode = (await client.post("preEnableMFA")).data
  return qrCode
}

async function enableMfa(code) {
  await client.post("enableMFA", { code })
}

async function requestResetPassword(email) {
  await client.post("resetPassword", { email })
}

async function resetPassword(params) {
  await client.post("changePasswordAfterReset", {
    email: params.email,
    passwordCode: params.passwordCode,
    newPassword: params.newPassword,
  })
}

function logout() {
  removeTokens()
}

export {
  isAuthed,
  register,
  login,
  getRequestToken,
  preEnableMfa,
  enableMfa,
  requestResetPassword,
  resetPassword,
  logout,
}
