enum CrossOriginMessage {
  saved = "saved",
  deleted = "deleted",
}

// e.g. Post message to tab that opened this one
function sendMessageToReferrer(documentType: string, message: string) {
  if (document.referrer) {
    window.opener?.postMessage(`${documentType}-template-${message}`, document.referrer)
  }
}

// e.g. Post message to other tabs (including main Wiresk app)
function sendMessageToOrigin(documentType: string, message: string, toOrigin: string) {
  window.opener?.postMessage(`${documentType}-template-${message}`, toOrigin)
}

// e.g. Listen to messages from tab that opened this one
function listenMessageFromReferrer(documentType: string, message: CrossOriginMessage, completion: VoidFunction) {
  window.addEventListener("message", (event) => {
    if (event.origin === document.referrer && event.data == `${documentType}-template-${message}`) {
      completion()
    }
  })
}

// e.g. Listen to messages from other tabs (including main Wiresk app)
function listenMessageFromOrigin(documentType: string, message: CrossOriginMessage, fromOrigin: string, completion: VoidFunction) {
  window.addEventListener("message", (event) => {
    if (event.origin === fromOrigin && event.data == `${documentType}-template-${message}`) {
      completion()
    }
  })
}

export { 
  CrossOriginMessage, 
  sendMessageToReferrer, 
  sendMessageToOrigin, 
  listenMessageFromReferrer, 
  listenMessageFromOrigin 
}
