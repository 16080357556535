<template>
  <div class="flex items-center text-sm text-center text-gray-400 before:flex-1 before:border-b after:flex-1 after:border-b" :class="classes">
    <div :class="textClasses">
      {{ text }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    dashed: Boolean,
    text: String,
    narrow: Boolean,
    thick: Boolean
  },
  computed: {
    classes() {
      return {
        "py-0": this.narrow,
        "py-4": !this.narrow,
        "before:border-gray-400 after:border-gray-400": !this.dashed,
        "before:border-dashed pt-2 after:border-dashed before:border-gray-500 after:border-gray-500": this.dashed,
        "before:border-b-2 after:border-b-2": this.thick
      }
    },
    textClasses() {
      return {
        "px-2": this.text
      }
    }
  }
}
</script>