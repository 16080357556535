function getValue(data, path) {
  if (!data || !path ) return null
  var pathComponents = path.split(".")
  return parse(data, pathComponents).flat()
}

function parse(data, [key, ...path]) {
  return (Array.isArray(data) ? data : [data])
    .reduce((acc, obj) => {
      if (path.length) {
        if (key.includes("[0]")) {
          acc.push(parse(obj[key.replace('[0]', '')][0], path))
        } else {
          acc.push(parse(obj[key], path))
        }
      } else if (obj[key]) {
        acc.push(obj[key])
      }
      return acc
    }, [])
    .flat()
}

export { getValue }
