export default (editor, options) => {
  const domComponents = editor.DomComponents
  const cellsResizable = options.cellsResizable;

  const name = options.nameAdvancedTableCellHeader
  const label = options.labelAdvancedTableCellHeader
  const extendName = options.nameAdvancedTableBase

  domComponents.addType(name, {
    extend: extendName,
    isComponent: el => el.tagName === 'ADVANCEDTABLECELLHEADER',
    model: {
      defaults: {
        name: label,
        tagName: 'th',
        style: { "text-align": "left" },
        resizable: cellsResizable,
        classes: [name],
        // draggable: false,
        // removable: false,
      }
    },
    view: {
      init() {
        const doc = editor.Canvas.getDocument()
        if (!doc) { return }

        const stl = doc?.querySelector(`style#${name}`);
        if (!stl) {
          doc.body.insertAdjacentHTML('beforeend', `
          <style id=${name}>
            .${name}:empty:after {
              content: "${label}";
              height: 100%;
              font-size: large;
              color: gray;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          </style>
          `);
          } 
      },
      events: {
        dblclick: "onDblClick",
      },

      onDblClick() {
        if (this.model.get('components').length === 0) {
          this.model.components().add({ type: 'text', content: 'Text', style: { padding: '10px' } });
        }
      },
    }
  });
};
