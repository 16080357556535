export function addCustomStyleManagerProperties(editor) {
  editor.StyleManager.addProperty("decorations", {
    name: "Border top",
    property: "border-top",
    type: "composite",
    properties: [
      {
        name: "Width",
        type: "integer",
        units: ["px", "em", "rem"],
        property: "border-top-width",
      },
      {
        name: "Style",
        type: "select",
        property: "border-top-style",
        options: [{ value: "none" }, { value: "solid" }, { value: "dotted" }, { value: "dashed" }, { value: "double" }, { value: "groove" }, { value: "ridge" }, { value: "inset" }, { value: "outset" }],
      },
      {
        name: "Color",
        type: "color",
        property: "border-top-color",
      },
    ],
  })

  editor.StyleManager.addProperty("decorations", {
    name: "Border right",
    property: "border-right",
    type: "composite",
    properties: [
      {
        name: "Width",
        type: "integer",
        units: ["px", "em", "rem"],
        property: "border-right-width",
      },
      {
        name: "Style",
        type: "select",
        property: "border-right-style",
        options: [{ value: "none" }, { value: "solid" }, { value: "dotted" }, { value: "dashed" }, { value: "double" }, { value: "groove" }, { value: "ridge" }, { value: "inset" }, { value: "outset" }],
      },
      {
        name: "Color",
        type: "color",
        property: "border-right-color",
      },
    ],
  })

  editor.StyleManager.addProperty("decorations", {
    name: "Border bottom",
    property: "border-bottom",
    type: "composite",
    properties: [
      {
        name: "Width",
        type: "integer",
        units: ["px", "em", "rem"],
        property: "border-bottom-width",
      },
      {
        name: "Style",
        type: "select",
        property: "border-bottom-style",
        options: [{ value: "none" }, { value: "solid" }, { value: "dotted" }, { value: "dashed" }, { value: "double" }, { value: "groove" }, { value: "ridge" }, { value: "inset" }, { value: "outset" }],
      },
      {
        name: "Color",
        type: "color",
        property: "border-bottom-color",
      },
    ],
  })

  editor.StyleManager.addProperty("decorations", {
    name: "Border left",
    property: "border-left",
    type: "composite",
    properties: [
      {
        name: "Width",
        type: "integer",
        units: ["px", "em", "rem"],
        property: "border-left-width",
      },
      {
        name: "Style",
        type: "select",
        property: "border-left-style",
        options: [{ value: "none" }, { value: "solid" }, { value: "dotted" }, { value: "dashed" }, { value: "double" }, { value: "groove" }, { value: "ridge" }, { value: "inset" }, { value: "outset" }],
      },
      {
        name: "Color",
        type: "color",
        property: "border-left-color",
      },
    ],
  })

  editor.StyleManager.addProperty("dimension", {
    name: "Vertical Align",
    property: "vertical-align",
    type: "select",
    default: "auto",
    list: [
      {
        value: "auto",
        name: "auto",
      },
      {
        value: "top !important",
        name: "top",
      },
      {
        value: "middle !important",
        name: "middle",
      },
      {
        value: "bottom !important",
        name: "bottom",
      },
    ],
  })
}
