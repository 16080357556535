import { getTemplate } from "@/api/template"

export default (
  editor
  // config
) => {

  var setData = function() {console.log("setData function hasn't been replaced")} // 1. Declared first here, then set inside `run()`
  var setTemplateLiteWithTemplate = function() {console.log("setTemplateLiteWithTemplate function hasn't been replaced")}

  async function fetchTemplate(id) {
    return await getTemplate(id)
  }

  // Add command
  return {
    run(id, setThisData, setThisTemplateLiteWithTemplate) {
      if (id) {
        setData = setThisData
        setTemplateLiteWithTemplate = setThisTemplateLiteWithTemplate
        editor.setComponents("Loading...") //  Make this look nicer
        fetchTemplate(id)
          .then((template) => {
            // const html = config.preHtml ?? "" + template.html ?? "" + config.postHtml ?? ""
            // const css = config.preCss ?? "" + template.css ?? "" + config.postCss ?? ""
            const components = JSON.parse(template.component)
            const style = JSON.parse(template.style)
            editor.setComponents(components)
            editor.setStyle(style)
            setTemplateLiteWithTemplate(template)
            setData(JSON.parse(template.exampleInput))
            editor.select()
          })
          .catch((error) => {
            console.log(error)
            editor.setComponents("Error loading template: " + error)
            editor.select()
          })
      }
    },
  }
}
