
import { defineComponent, PropType } from "vue"

import { DataTableHeader } from "./DataTableHeaderAndGroup"

export default defineComponent({
  emits: ["update:sort"],
  props: {
    headers: {
      type: Array as PropType<Array<DataTableHeader>>,
      required: true,
    },
    sort: Object as PropType<{ value: string; direction: "asc" | "desc" }>,
  },
  methods: {
    classes(header: DataTableHeader) {
      const classes: Record<string, any> = { "cursor-pointer": header.sortable }
      if (header.width) {
        classes[`w-${header.width}/12`] = true
      }
      return classes
    },
    clicked(header: DataTableHeader) {
      if (!header.sortable) {
        return
      }
      if (header.value != this.sort?.value) {
        this.$emit("update:sort", { value: header.value, direction: "asc" })
      } else {
        const direction = this.sort.direction == "asc" ? "desc" : "asc"
        this.$emit("update:sort", { value: header.value, direction })
      }
    },
  },
})
