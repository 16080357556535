import * as tblHelper from "@/helpers/advancedTableHelper"

export default (editor, options) => {
  const domComponents = editor.DomComponents

  const name = options.nameAdvancedTableTbody
  const label = options.labelAdvancedTableTbody
  const extendName = options.nameAdvancedTableBase

  domComponents.addType(name, {
    extend: extendName,
    isComponent: (el) => el.tagName === "ADVANCEDTABLETBODY",
    model: {
      defaults: {
        nRows: 4,
        nColumns: 3,
        hasHeaders: false,
        name: label,
        tagName: "tbody",
        // style: { height: "100px", width: "100%" },
        classes: [name],
        // droppable: options.componentCell + ',' + options.componentCellHeader,
        // draggable: false,
        // classes: [],
        traits: [
          {
            type: 'number',
            name: 'nColumns',
            label: 'Number of Columns',
            min: 1,
            changeProp: 1
          },
          {
            type: 'number',
            name: 'nRows',
            label: 'Number of Rows',
            min: 1,
            changeProp: 1
          },
          {
            type: 'checkbox',
            name: 'hasHeaders',
            label: 'Enable headers',
            changeProp: 1
          },
          {
            type: 'button',
            label: false,
            name: 'highlightCells',
            text: 'Toggle highlight cells with size',
            command: editor => tblHelper.highlightCellsWithSize(editor.getSelected()),
          },
          {
            type: 'button',
            label: false,
            name: 'highlightCellsRemove',
            text: 'Clear all cells width and height',
            command: editor => tblHelper.clearCellsWithSize(editor.getSelected()),
          },
        ]
      },
      init() {
        this.listenTo(this, 'change:nColumns', this.columnsChanged);
        this.listenTo(this, 'change:nRows', this.rowsChanged);
        this.listenTo(this, 'change:hasHeaders', this.headerChanged);
      },
      addRowsAndCells(){
        let calcWidth =  Number(this.props().nColumns) * 46
        let setWidth = calcWidth < 900? calcWidth : 900;
        let calcHeight = Number(this.props().nRows) * 22

        this.setStyle({width:  setWidth + 'px', height: calcHeight + 'px'})

        let cells = [];
        let header = this.props().hasHeaders;
        let headers = [];

        if (this.props().nRows > 0) {
          for (let index = 0; index < this.props().nColumns; index++) {
            cells.push({ type: options.nameAdvancedTableCell });
          }
          for (let index = 0; index < this.props().nRows; index++) {
            this.components().add({ type: options.nameAdvancedTableRow, components: cells }, { at: -1 });
          }
        }
        
        if(header) {
          for (let index = 0; index < this.props().nColumns; index++) {
            headers.push({ type: options.nameAdvancedTableCellHeader });
          }
          this.components().add({ type: options.nameAdvancedTableRow, components: headers }, { at: 0 });
        }
        this.getTrait('nColumns').set('value', this.props().nColumns)
        this.getTrait('nRows').set('value', this.props().nRows)
      },
      columnsChanged(selected, value, opts) {
        if(value == 0 || this.columnCount() === value)
          return

        const baseDifference = value - this.columnCount();
        const difference = Math.abs(value - this.columnCount())
        if(baseDifference<0){
          for(let i=0;i<difference; i++){
            tblHelper.removeColumn(this, this.getLastColumnIndex())
          }
        } else {
          for(let i=0;i<difference; i++){
            tblHelper.insertColumn(this, this.columnCount(), options.nameAdvancedTableCell, options.nameAdvancedTableCellHeader)
          }
        }
      },
      rowsChanged(selected, value, opts) {
        if(value == 0 || this.rowCount() === value)
          return

        const baseDifference = value - this.rowCount();
        const difference = Math.abs(value - this.rowCount())
        if(baseDifference<0){
          for(let i=0;i<difference; i++){
            tblHelper.removeRow(this, this.getLastRowIndex())
          }
        } else {
          for(let i=0;i<difference; i++){
            tblHelper.insertRow(this, this.rowCount(), options.nameAdvancedTableRow, options.nameAdvancedTableCell)
          }
        }
      },
      headerChanged(selected, value, opts) {
        if(this.checkHeaderExists() != this.props().hasHeaders) {
          tblHelper.toggleHeaderRow(this, options.nameAdvancedTableRow, options.nameAdvancedTableCellHeader)
        }
      },
      checkHeaderExists(){
        return this.components().at(0).components().at(0).is(options.nameAdvancedTableCellHeader)
      },
      hasChildren(){
        return this.components().length > 0
      },
      rowCount(){
        let rowCount = this.components().length
        if(this.components().at(0).components().at(0).is(options.nameAdvancedTableCellHeader))
          rowCount--
        return rowCount
      },
      columnCount(){
        return this.components().at(0).components().length
      },
      getLastRowIndex(){
        return this.rowCount() - 1
      },
      getLastColumnIndex(){
        return this.columnCount() - 1
      }
    },
    view: {
      init() {
        const doc = editor.Canvas.getDocument()
        if (!doc) { return }

        const stl = doc?.querySelector(`style#${name}`);
        if (!stl) {
          doc.body.insertAdjacentHTML('beforeend', `
          <style id=${name}>
            .${name}:empty:after {
              content: "${label}";
              height: 100%;
              font-size: large;
              color: gray;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          </style>
          `);
        } 
      },  
    },
  })
};
