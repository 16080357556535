import ConditionalText from "./conditionals/ConditionalText"

import ForEachBase from "./for-each/ForEachBase"
import ForEachContainer from "./for-each/ForEachContainer"

import AdvancedTableBase from "./advancedTable/AdvancedTableBase"
import AdvancedTableConditionalBase from "./advancedTable/AdvancedTableConditionalBase"
import AdvancedTable from "./advancedTable/AdvancedTable"
import AdvancedConditionalTable from "./advancedTable/AdvancedConditionalTable"
import AdvancedForEachTable from "./advancedTable/AdvancedForEachTable"
import AdvancedTableThead from "./advancedTable/AdvancedTableThead"
import AdvancedTableTbody from "./advancedTable/AdvancedTableTbody"
import AdvancedTableConditionalTbody from "./advancedTable/AdvancedTableConditionalTbody"
import AdvancedTableForEachTbody from "./advancedTable/AdvancedTableForEachTbody"
import AdvancedTableTfoot from "./advancedTable/AdvancedTableTfoot"
import AdvancedTableRow from "./advancedTable/AdvancedTableRow"
import AdvancedTableConditionalRow from "./advancedTable/AdvancedTableConditionalRow"
import AdvancedTableForEachRow from "./advancedTable/AdvancedTableForEachRow"
import AdvancedTableCell from "./advancedTable/AdvancedTableCell"
import AdvancedTableCellHeader from "./advancedTable/AdvancedTableCellHeader"


export default (editor, options = {}) => {

  const optionsDefault = {
    tblResizable: true,
    cellsResizable: true,
    forEachContainerResizable: true,

    labelConditionalText: 'Conditional Text', nameConditionalText: 'conditional-text',

    labelForEachBase: 'For-each Base', nameForEachBase: 'for-each-base',
    labelForEachContainer: 'For-each Container', nameForEachContainer: 'for-each-container',

    labelAdvancedTableBase: 'Advanced Table Base', nameAdvancedTableBase: 'advanced-table-base',
    labelAdvancedTableConditionalBase: 'Advanced Table Conditional Base', nameAdvancedTableConditionalBase: 'advanced-table-conditional-base',
    labelAdvancedTable: 'Advanced Table', nameAdvancedTable: 'advanced-table',
    labelAdvancedConditionalTable: 'Advanced Conditional Table', nameAdvancedConditionalTable: 'advanced-conditional-table',
    labelAdvancedForEachTable: 'Advanced For-each Table', nameAdvancedForEachTable: 'advanced-for-each-table',
    labelAdvancedTableThead: 'Advanced Table Header', nameAdvancedTableThead: 'advanced-table-thead',
    labelAdvancedTableTbody: 'Advanced Table Body', nameAdvancedTableTbody: 'advanced-table-tbody',
    labelAdvancedTableConditionalTbody: 'Advanced Table Conditional Body', nameAdvancedTableConditionalTbody: 'advanced-table-conditional-tbody',
    labelAdvancedTableForEachTbody: 'Advanced Table For Each Body', nameAdvancedTableForEachTbody: 'advanced-table-for-each-tbody',
    labelAdvancedTableTfoot: 'Advanced Table Footer', nameAdvancedTableTfoot: 'advanced-table-tfoot',
    labelAdvancedTableRow: 'Advanced Table Row', nameAdvancedTableRow: 'advanced-table-row',
    labelAdvancedTableConditionalRow: 'Advanced Table Conditional Row', nameAdvancedTableConditionalRow: 'advanced-table-conditional-row',
    labelAdvancedTableForEachRow: 'Advanced Table For Each Row', nameAdvancedTableForEachRow: 'advanced-table-for-each-row',
    labelAdvancedTableCellHeader: 'Advanced Table Cell Header', nameAdvancedTableCellHeader: 'advanced-table-cell-header',
    labelAdvancedTableCell: 'Advanced Table Cell', nameAdvancedTableCell: 'advanced-table-cell'
  }

  const optionsUpdated = {
    ...optionsDefault,
    ...options
  }

  ConditionalText(editor, optionsUpdated)

  ForEachBase(editor, optionsUpdated)
  ForEachContainer(editor, optionsUpdated)

  AdvancedTableBase(editor, optionsUpdated)
  AdvancedTableConditionalBase(editor, optionsUpdated)
  AdvancedTable(editor, optionsUpdated)
  AdvancedConditionalTable(editor, optionsUpdated)  
  AdvancedForEachTable(editor, optionsUpdated)
  AdvancedTableThead(editor, optionsUpdated)
  AdvancedTableTbody(editor, optionsUpdated)
  AdvancedTableConditionalTbody(editor, optionsUpdated)
  AdvancedTableForEachTbody(editor, optionsUpdated)
  AdvancedTableTfoot(editor, optionsUpdated)
  AdvancedTableRow(editor, optionsUpdated)
  AdvancedTableForEachRow(editor, optionsUpdated)
  AdvancedTableConditionalRow(editor, optionsUpdated)
  AdvancedTableCellHeader(editor, optionsUpdated)
  AdvancedTableCell(editor, optionsUpdated)
  
}
