import { renderSlot as _renderSlot, toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-10" }
const _hoisted_2 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_input_wrapper = _resolveComponent("w-input-wrapper")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createBlock(_component_v_date_picker, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
    mode: "dateTime"
  }, {
    default: _withCtx(({ inputValue, inputEvents }) => [
      _createVNode(_component_w_input_wrapper, {
        id: _ctx._id,
        label: _ctx.label,
        disabled: _ctx.disabled,
        optional: _ctx.optional,
        plain: _ctx.plain,
        errorMessage: _ctx.errorMessage,
        hint: _ctx.hint
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "leading", {
              focus: () => _ctx.$refs.theInput.focus()
            }),
            _createElementVNode("input", _mergeProps({
              class: "px-3 bg-transparent border-none text-primaryGrapesJs-100 grow focus:border-none focus:ring-0",
              ref: "theInput",
              id: _ctx._id,
              readonly: "",
              value: inputValue
            }, _toHandlers(inputEvents, true)), null, 16, _hoisted_2),
            _renderSlot(_ctx.$slots, "trailing", {
              focus: () => _ctx.$refs.theInput.focus()
            })
          ])
        ]),
        _: 2
      }, 1032, ["id", "label", "disabled", "optional", "plain", "errorMessage", "hint"])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}