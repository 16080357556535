
import { defineComponent } from "@vue/runtime-core"
import { renderForm, submitForm } from "@/api/formRenderer"
import { FormRenderer } from "@/models/render/FormRenderer"
import WSpinner from "@/components/bases/misc/WSpinner.vue"
import { LooseObject } from "@/models/LooseObject"
import WAlert from "@/components/bases/alerts/WAlert.vue"
import { WDialog } from "@/components/bases/modals"
import { WButton } from "@/components/bases/buttons"

export default defineComponent({
  props: {
    isNewDoc: Boolean,
    templateId: String,
  },
  data() {
    return {
      formId: null as null | string,
      formQuery: null as null | any,
      submittingBody: {} as LooseObject,

      isLoading: false,
      isSubmitting: false,
      formRenderer: null as null | FormRenderer,
      rendererWrapperNode: null as any,
      formNode: null as any,

      showDialog: false,

      errorMessage: "" as string,
      errorTitle: "" as string,
      errorIcon: "" as string,
      errorMode: "" as string,
    }
  },
  methods: {
    parseRoutingParams() {
      this.formId = (this.$route?.params?.id as string) ?? ""
      this.formQuery = this.$route?.query ?? {}
    },
    async renderForm(data: any) {
      if (this.formId) {
        try {
          this.isLoading = true
          this.formRenderer = await renderForm(this.formId, this.formQuery)
          setTimeout(() => {
            document.getElementsByTagName("title")[0].innerHTML =
              this.formRenderer?.title ?? "Untitled Form"
            this.queryRendererWrapper()
            this.addButtonsEventListener()
          }, 300)
        } catch (e: any) {
          this.showDialog = true
          this.formId = null
          this.errorIcon = "cancel"
          this.errorTitle = "Error!"
          this.errorMode = "error"
          this.errorMessage =
            e.message +
            "<br/><br/> Kindly reach out to the person who sent you the form. They will be able to provide you with the necessary guidance and support."
        } finally {
          this.isLoading = false
        }
      }
    },
    async submitForm() {
      if (this.formId) {
        try {
          this.isSubmitting = true
          await submitForm(this.formId, this.submittingBody)
          this.errorIcon = "check_circle"
          this.errorTitle = "Success!"
          this.errorMode = "success"
          this.errorMessage = "The form has been submitted successfully."

          if (this.formNode) {
            for (let i = 0; i < this.formNode.elements.length; i++) {
              this.formNode.elements[i].disabled = true
            }
          }
        } catch (e: any) {
          this.errorIcon = "cancel"
          this.errorTitle = "Error!"
          this.errorMode = "error"
          this.errorMessage =
            e.message +
            "<br/><br/> Kindly reach out to the person who sent you the form. They will be able to provide you with the necessary guidance and support."
        } finally {
          this.showDialog = true
          this.isSubmitting = false
        }
      }
    },
    queryRendererWrapper() {
      this.rendererWrapperNode = document?.querySelector("iframe")?.contentDocument?.body
      this.formNode = document
        ?.querySelector("iframe")
        ?.contentDocument?.body.getElementsByTagName("form")[0]

      if (this.formNode) {
        // const node = document.createElement("div")
        // node.id = "error-message"
        // this.formNode.appendChild(node)
        // const errorTextNode = document
        //   ?.querySelector("iframe")
        //   ?.contentWindow?.document.getElementById("error-message")
        // errorTextNode!.innerHTML = ``
        // ?.contentDocument?.body.getElementById("test")
        //   this.formNode.action = "https://google.com"
      }
    },
    addButtonsEventListener() {
      if (this.rendererWrapperNode) {
        this.rendererWrapperNode.addEventListener("click", (event: any) => {
          const isButton = event.target.nodeName === "BUTTON"
          if (!isButton) {
            return
          }
          const buttonId = event.target.id
          if (this.formQuery != null) {
            this.submittingBody = this.formQuery
          }
          this.submittingBody["Wiresk@Trigger"] = buttonId

          this.parseformNodesValue()

          this.submitForm()
        })
      }
    },
    parseformNodesValue() {
      if (this.formNode) {
        for (let i = 0; i < this.formNode.elements.length; i++) {
          if (this.formNode.elements[i].nodeName != "BUTTON") {
            const elementId = this.formNode.elements[i].id
            this.submittingBody[elementId] = this.formNode.elements[i].value
          }
        }
      }
    },
  },
  async mounted() {
    this.parseRoutingParams()
    await this.renderForm({})
  },
  components: { WSpinner, WDialog, WButton },
})
