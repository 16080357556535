<template>
  <w-section title="'For each' conditions:">
    <div class="space-y-2">
      <w-button class="w-full" @click="openConditionsDialog" :key="finalComponentGroups">{{ buttonText }}</w-button>
    </div>
  </w-section>
  <div v-show="showConditionsDialog" class="fixed inset-0 bg-black bg-opacity-60"/>
  <w-dialog 
    class="bg-primaryGrapesJs-900" 
    v-model="showConditionsDialog" 
    title="'For Each' Conditions:" 
    showCloseButton
    persistent
    @closed="dialogNeedsClose"
  >
    <for-each-form 
      :data="forEachData"
      :localGroups="localForEachGroups"
      :componentGroups="temporaryComponentGroups" 
      @applyForEachCondition="applyForEachCondition" 
      @cancel="cancelForEach"
    ></for-each-form>
  </w-dialog>
</template>

<script>
import WSection from "@/components/bases/WSection.vue"
import ForEachForm from "./ForEachForm.vue"
import { WDialog } from "@/components/bases/modals"
import { WButton } from "@/components/bases/buttons"

export default {
  emits: ["applyCondition", "selectCondition", "dialogCancelled", "dialogClosed"],

  props: {
    data: {
      type: Object
    },
    localGroups: {
      type: Array
    },
    componentGroups: {
      type: Array
    },
    showDialog: {
      type: Boolean
    }
  },

  watch: {
    data: function() {
      this.forEachData = this.data
    },
    localGroups: function() {
      this.localForEachGroups = this.localGroups
    },
    showDialog: function() {
      this.openConditionsDialog()
    }
  },

  data() {
    return {
      forEachData: this.data,
      localForEachGroups: this.localGroups,

      temporaryComponentGroups: [],
      finalComponentGroups: this.componentGroups,
      showConditionsDialog: false,

    }
  },

  methods: {
    openConditionsDialog() {
      this.temporaryComponentGroups = this.finalComponentGroups ? this.finalComponentGroups.slice() : []
      this.showConditionsDialog = true
    },
    applyForEachCondition(forEachGroups) {
      this.finalComponentGroups = forEachGroups.slice()
      this.$emit("applyCondition", this.finalComponentGroups)
      this.showConditionsDialog = false
    },
    cancelForEach() {
      this.showConditionsDialog = false
      this.$emit("dialogCancelled")
    },
    dialogNeedsClose() {
      this.$emit("dialogClosed")
    }
  },

  computed: {
    buttonText() {
      const groups = this.finalComponentGroups
      return (!groups || groups.length < 1) 
      ? "Open Conditions" 
      : "For " + groups[groups.length - 1].variableText + " in " + groups[groups.length - 1].selectedGroup.displayName
    }
  },

  components: {
    WSection,
    ForEachForm,
    WDialog,
    WButton,
  },
}
</script>
