<template>
  <div class="space-y-2">
    <w-section 
      v-if="(!localVariableOptions && !globalVariableOptions) 
      || (localVariableOptions.length < 1 && globalVariableOptions.length < 1)"
      title="Variables:" 
    >
      <div class="text-xs italic">No sample data provided</div>
    </w-section>
    <w-section
      v-if="localVariableOptions.length > 0"
      title="Select local variable:"
     >
      <div class="flex items-center mt-1 space-x-2 text-sm">
        <w-selector 
          v-model="selectedLocalVariable"
          returnKey 
          class="w-full" 
          :items="localVariableOptions" 
          itemKey="path" 
          itemText="displayName"
        ></w-selector>
      </div>
    </w-section>
    <w-section 
      v-if="globalVariableOptions.length > 0"
      title="Select global variable:"
    >
      <div class="flex items-center mt-1 space-x-2 text-sm">
        <w-selector 
          v-model="selectedGlobalVariable"
          returnKey 
          class="w-full" 
          :items="globalVariableOptions" 
          itemKey="path" 
          itemText="displayName"
        ></w-selector>
      </div>
    </w-section>
    <w-section 
      v-if="globalVariableOptions.length > 0"
      title="Select text case:"
    >
      <div class="flex flex-wrap gap-2">
        <text-case-buttons @caseSelected="caseButtonClicked"></text-case-buttons>
      </div>
    </w-section>
  </div>
</template>

<script>
import { WSelector } from "@/components/bases/form"
import WSection from "@/components/bases/WSection.vue"
import TextCaseButtons from "../text_cases/TextCaseButtons.vue"

export default {
  emits: ["localVariableSelected", "globalVariableSelected"],
  props: {
    globalVariables: {
      type: Array
    },
    localVariables: {
      type: Array
    },
  },
  data() {
    return {
      selectedLocalVariable: {},
      localVariableOptions: this.localVariables,

      selectedGlobalVariable: {},
      globalVariableOptions: this.globalVariables,

      selectedCaseText: ""
    }
  },

  watch: {
    selectedLocalVariable: function() {
      this.$emit("localVariableSelected", this.selectedLocalVariable, this.selectedCaseText)
    },
    selectedGlobalVariable: function() {
      this.$emit("globalVariableSelected", this.selectedGlobalVariable, this.selectedCaseText)
    }
  },

  methods: {
    caseButtonClicked(textCase) {
      this.selectedCaseText = textCase
      if (JSON.stringify(this.selectedLocalVariable) !== "{}") {
        this.$emit("localVariableSelected", this.selectedLocalVariable, textCase)
      }
      if (JSON.stringify(this.selectedGlobalVariable) !== "{}") {
        this.$emit("globalVariableSelected", this.selectedGlobalVariable, textCase)
      }
    }
  },

  components: {
    WSection,
    WSelector,
    TextCaseButtons
  },
}
</script>
