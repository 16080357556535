<template>
  <div class="w-16 py-4 pl-2 text-sm text-gray-400">{{ text }}</div>
  <!-- <div class="flex items-center text-center text-gray-400 before:flex-1 before:border-b after:flex-1 after:border-b" :class="classes">
    <div :class="textClasses">
      {{ text }}
    </div>
  </div> -->
</template>

<script>

export default {
  props: {
    hasLine: Boolean,
    text: String,
    // narrow: Boolean
  },
  computed: {
    classes() {
      return {
        // "py-0": this.narrow,
        // "py-4": !this.narrow,
        // "before:border-gray-400 after:border-gray-400": this.hasLine,
        // "before:border-transparent after:border-transparent": !this.hasLine,
      }
    },
    // textClasses() {
    //   return {
    //     "px-2": this.text
    //   }
    // }
  }
}
</script>