import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_w_text_skeleton = _resolveComponent("w-text-skeleton")!

  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widths, (width, i) => {
          return (_openBlock(), _createElementBlock("th", {
            key: i,
            class: _normalizeClass(["px-4 text-left border-b", width])
          }, [
            _createVNode(_component_w_text_skeleton, { short: "" })
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
        return _createElementVNode("tr", {
          key: i,
          class: "h-14"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widths, (width, i) => {
            return (_openBlock(), _createElementBlock("td", {
              key: i,
              class: _normalizeClass(["px-4 text-left border-b", width])
            }, [
              _createVNode(_component_w_text_skeleton)
            ], 2))
          }), 128))
        ])
      }), 64))
    ])
  ]))
}