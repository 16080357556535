import client from "./_client"
import RenderDocument from "../models/render/RenderDocument"

async function render(request) {
  try {
    const response = await client.post("renderTemplateAndSetupEmailTracking", request.oDataJson())
    const body = response.data
    return RenderDocument.fromResponse(body)
  } catch (error) {
    console.log(error)
  }
}

export { render }
