import { getValue } from './data'
import { unique } from '../../../../helpers/miscellaneousHelpers'

function getLocalVariables(data, component) {
  if (!data || !component) {
    return []
  }
  if (!component) return getAllVariables(data)

  let variables = []

  if (component?.attributes?.forEachGroups) {
    variables = component.attributes.forEachGroups.map((forEachGroup) => {
      return getAllVariables(data, forEachGroup.selectedGroup.fullPath, forEachGroup.variableText)
    }).reverse().flat()
  }

  if (component.isChildOf('for-each-base')) {
    if (component.isInstanceOf('for-each-base') && component.attributes.isNew) return variables
    const forEachContainers = component.parents().filter((parent) => 
      parent.isInstanceOf('for-each-base')
    )

    const allParentVariables = forEachContainers.map((container) => {
      const variables = container.attributes.forEachGroups.map((forEachGroup) => {
        return getAllVariables(data, forEachGroup.selectedGroup.fullPath, forEachGroup.variableText)
      })
      return variables.reverse().flat()
    })
    const allVariables = [...variables, ...allParentVariables].flat()
    return unique(allVariables)

  } else {
    return variables
  }
}

function getAllVariables(data, path, singleVariableText) {
  const firstInstance = getValue(data, path)[0]
  const variableText = singleVariableText ? singleVariableText : "item"
  return getVariables(firstInstance, variableText)
}

function getVariables(data, prefix) {
  var arr = Object.entries(data).reduce((acc, [key, value]) => {
    const temp_key = prefix ? `${prefix}.${key}` : key
    if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
      acc.push({ displayName: temp_key, path: temp_key })
    } else if (!Array.isArray(value)) {
      acc.push(getVariables(value, temp_key))
    }
    return acc.flat()
  }, [])
  return arr
}

export { getLocalVariables, getVariables }