export default class Template {
  _id
  get id(){
    return this._id
  }

  groupId
  themeId
  scriptId
  name
  description
  title
  status
  html
  css
  js
  component
  style
  type
  exampleInput

  constructor(params) {
    this._id = null
    this.groupId = params.idgroup
    this.themeId = params.idtheme
    this.scriptId = params.idscript
    this.name = params.name
    this.description = params.description
    this.title = params.title
    this.status = params.status ?? true
    this.html = params.html
    this.css = params.css
    this.js = params.js
    this.component = params.component
    this.style = params.style
    this.type = params.type
    this.exampleInput = params.exampleInput
  }

  static fromResponse(data) {
    const template = new Template({
      groupId: data.idgroup,
      themeId: data.idtheme,
      scriptId: data.idscript,
      name: data.name,
      description: data.description,
      title: data.title,
      status: data.status == 1,
      html: data.html,
      css: data.css,
      js: data.js,
      component: data.component,
      style: data.style,
      type: data.type,
      exampleInput: data.example_input
    })
    template._id = data.idtemplate
    return template
  }

  oDataJson() {
    return {
      idgroup: this.groupId,
      idtheme: this.themeId,
      idscript: this.scriptId,
      name: this.name,
      description: this.description,
      title: this.title,
      status: this.status ? 1 : 0,
      html: this.html,
      css: this.css,
      js: this.js,
      component: this.component,
      style: this.style,
      type: this.type,
      example_input: this.exampleInput
    }
  }
}