export default class RenderRequest {
  renderPdf: boolean
  templateId: string | undefined
  data: string | null
  html: string | undefined 
  css: string | undefined 
  title: string | undefined

  constructor(params: New<RenderRequest>) {
    this.renderPdf = params.renderPdf!
    this.templateId = params.templateId
    this.data = params.data!
    this.html = params.html
    this.css = params.css
    this.title = params.title
  }

  static fromTemplate(isPdf: boolean, id: string): RenderRequest {
    const request = new RenderRequest({
      renderPdf: isPdf,
      templateId: id
    })
    return request
  }

  static fromRawData(
    isPdf: boolean, id: string | undefined, html: string | undefined, css: string | undefined, 
    data: string | null, title: string | undefined
    ): RenderRequest {
    const request = new RenderRequest({
      renderPdf: isPdf,
      templateId: id,
      html: html,
      css: css,
      data: data ?? null,
      title: title
    })
    return request
  }

  oDataJson(): any {
    return {
      render: true,
      renderPdf: this.renderPdf,
      track: false,
      template: {
        idtemplate: this.templateId,
        html: this.html,
        css: this.css,
        title: this.title
      },
      data: this.data
    }
  }
}