// File commands

export function openCreateBlankCommand(documentType) {
  return {
    id: "open-create-blank",
    run: (editor, sender) => {
      sender.set("active", false)
      editor.Commands.get("gjs-open-create-blank").run(documentType)
    },
  }
}

export function clearCanvasCommand() {
  return {
    id: "clear-canvas",
    run: function (editor, sender) {
      sender.set("active", false)
      if (confirm("Are you sure you want to clean the canvas?")) {
        editor.DomComponents.clear()
      }
    },
  }
}

export function importTemplateCommand() {
  return {
    id: "import-template",
    run: function (editor, sender) {
      sender.set("active", false)
      editor.Commands.get("gjs-open-import").run(editor)
    },
  }
}

// Panel Switcher
export function showLayersCommand() {
  return {
    id: "show-layers",
    run: function (editor) {
      const element = editor.getContainer().closest(".editor-row").querySelector(".layers-container")
      element.style.display = ""
    },
    stop: function (editor) {
      const element = editor.getContainer().closest(".editor-row").querySelector(".layers-container")
      element.style.display = "none"
    },
  }
}

export function showStylesCommand() {
  return {
    id: "show-styles",
    run: function (editor) {
      const lmEl = editor.getContainer().closest(".editor-row").querySelector(".styles-container")
      lmEl.style.display = ""
    },
    stop: function (editor) {
      const lmEl = editor.getContainer().closest(".editor-row").querySelector(".styles-container")
      lmEl.style.display = "none"
    },
  }
}

export function showBlocksCommand() {
  return {
    id: "show-blocks",
    run: function (editor) {
      const lmEl = editor.getContainer().closest(".editor-row").querySelector(".blocks-container")
      lmEl.style.display = ""
    },
    stop: function (editor) {
      const lmEl = editor.getContainer().closest(".editor-row").querySelector(".blocks-container")
      lmEl.style.display = "none"
    },
  }
}

// Devices

export function setDeviceDesktopCommand() {
  return {
    id: "set-device-desktop",
    run: function (editor) {
      editor.setDevice("Desktop")
    },
  }
}

export function setDeviceTabletCommand() {
  return {
    id: "set-device-tablet",
    run: function (editor) {
      editor.setDevice("Tablet")
    },
  }
}

export function setDeviceMobileCommand() {
  return {
    id: "set-device-mobile",
    run: function (editor) {
      editor.setDevice("Mobile")
    },
  }
}

// Selected Component Toolbar

export function convertToConditionalCommand() {
  return {
    id: "convert-to-conditional",
    run: (editor) => {
      if (confirm("Are you sure you want to convert the selected component to a 'conditional text' component?")) {
        const component = editor.getSelected();
        const revertComponent = component
        var styles = component.getStyle() || {}; 
        const coll = component.collection;
        const at = coll.indexOf(component);
        coll.remove(component);
        
        const newComponent = coll.add({
          type: "conditional-text"
        }, { at });
        
        newComponent.set('tagName', 'span')
        newComponent.set('revertComponent', revertComponent)
        newComponent.setStyle(styles);
        editor.select(newComponent)                    
      }
    },
  }
}

// Conditionals and For Each

export function openConditionalsCommand(documentBuilder) {
  return {
    id: "open-conditionals",
    run: () => {
      documentBuilder.showConditionalsDialog = true
      documentBuilder.$nextTick(() => {documentBuilder.showConditionalsDialog = false})
      // confirm("Please select 'Open Conditionals' from the styles pane to edit this component")
    },
  }
}

export function openforEachCommand(documentBuilder) {
  return {
    id: "open-for-each",
    run: () => {
      documentBuilder.showForEachDialog = true
      documentBuilder.$nextTick(() => {documentBuilder.showForEachDialog = false})
      // confirm("Please select 'Open Conditionals' from the styles pane to edit this component")
    },
  }
}

    





