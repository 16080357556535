import { createTemplate, updateTemplate, getTemplateLites } from "@/api/template"
import { CrossOriginMessage, sendMessageToReferrer } from "@/communication/crossOriginCommunication"

export default (editor, config) => {
  async function createNewTemplate(params) {
    return await createTemplate(params)
  }

  async function updateExistingTemplate(id, templateChanges) {
    return await updateTemplate(id, templateChanges)
  }

  async function fetchTemplateLites() {
    return await getTemplateLites()
  }

  function resetAndCloseModal() {
    nameTextField.value = ""
    descriptionTextArea.value = ""
    subjectTextField.value = ""

    sendMessageToReferrer(typeOfDocument, CrossOriginMessage.saved)

    editor.Modal.close()
  }

  var currentTemplateLite
  var templateLites
  var typeOfDocument
  var exampleInput

  const stylePrefix = editor.getConfig("stylePrefix")
  const modal = editor.Modal
  const container = document.createElement("div")
  container.className = "flex flex-col space-y-4"

  // Init text field
  const nameContainer = document.createElement("div")
  nameContainer.className = "flex flex-col"
  const nameTextField = document.createElement("input")
  nameTextField.setAttribute("type", "text")
  nameTextField.className = "text-sm text-black"
  const nameLabel = document.createElement("label")
  nameLabel.setAttribute("for", nameTextField)
  nameLabel.innerHTML = "Title"

  // Init text area
  const descriptionContainer = document.createElement("div")
  descriptionContainer.className = "flex flex-col"
  const descriptionTextArea = document.createElement("textarea")
  descriptionTextArea.className = "text-sm text-black"
  const descriptionLabel = document.createElement("label")
  descriptionLabel.setAttribute("for", descriptionTextArea)
  descriptionLabel.innerHTML = "Description"

  // Init subject text field
  const subjectContainer = document.createElement("div")
  subjectContainer.className = "flex flex-col"
  const subjectTextField = document.createElement("input")
  subjectTextField.setAttribute("type", "text")
  subjectTextField.className = "text-sm text-black"
  const subjectLabel = document.createElement("label")
  subjectLabel.setAttribute("for", subjectTextField)
  subjectLabel.innerHTML = "Email Subject"

  async function goToTemplateUrl(templateLite) {
    const currentUrl = window.location.pathname.replace(/\/$/, '')
    const lastSegment = currentUrl.substring(currentUrl.lastIndexOf('/') + 1)
    let url = `${process.env.VUE_APP_BUILDER_URL}/${templateLite.type}-builder/${templateLite.id}`
    
    switch (lastSegment) {
      case `${templateLite.id}`:
        break;
      case `new`:
        window.open(url, '_self');
        break;
      default:
        window.open(url, '_self');
        break;
    }
  }

  // Init save button
  const saveButton = document.createElement("button")
  saveButton.innerHTML = "Save"
  saveButton.className = stylePrefix + "btn-prim " + stylePrefix + "btn-export"
  saveButton.onclick = () => {
    if (nameTextField.value.trim() == "") {
      return confirm("Please enter a title for the template")
    }
    if (typeOfDocument == "email" && subjectTextField.value.trim() == "") {
      return confirm("Please enter an email subject for the template")
    }
    const html = config.preHtml ?? "" + editor.getHtml() ?? "" + config.postHtml ?? ""
    const css = config.preCss ?? "" + editor.getCss() ?? "" + config.postCss ?? ""
    const componentsString = JSON.stringify(editor.getComponents())
    const styleString = JSON.stringify(editor.getStyle())
    const exampleInputString = exampleInput ? JSON.stringify(exampleInput) : null

    const matchingTemplate = templateLites.find((template) => template.name === nameTextField.value.trim())

    if (matchingTemplate) {
      // Overwrite template
      if (confirm("Are you sure you want to overwrite this template?")) {
        updateExistingTemplate(matchingTemplate.id, {
          description: descriptionTextArea.value,
          title: typeOfDocument == "email" ? subjectTextField.value : nameTextField.value,
          html: html,
          css: css,
          component: componentsString,
          style: styleString,
          example_input: exampleInputString
        })
          .then((templateLite) => {
            goToTemplateUrl(templateLite)
          })
          .then(() => resetAndCloseModal())
          .catch((error) => console.log(error))
        return
      }
      return
    }
    // Create new template
    createNewTemplate({
      name: nameTextField.value,
      description: descriptionTextArea.value,
      title: typeOfDocument == "email" ? subjectTextField.value : nameTextField.value,
      html: html,
      css: css,
      component: componentsString,
      style: styleString,
      // js: "Test JS",
      type: typeOfDocument,
      exampleInput: exampleInputString
    })
      .then((templateLite) => {
        goToTemplateUrl(templateLite)
      })
      .then(() => resetAndCloseModal())
      .catch((error) => console.log(error))
    return
  }

  // Add command
  return {
    run(documentType, thisTemplateLite, data) {
      const components = editor.getComponents()
      if (components.length <= 0) {
        return confirm("Please add one or more components to the template to enable saving.")
      }
      typeOfDocument = documentType
      exampleInput = data

      currentTemplateLite = thisTemplateLite

      fetchTemplateLites().then((result) => {
        templateLites = result
      })

      nameTextField.value = currentTemplateLite ? currentTemplateLite.name : ""
      descriptionTextArea.value = currentTemplateLite ? currentTemplateLite.description : ""

      nameContainer.appendChild(nameLabel)
      nameContainer.appendChild(nameTextField)

      descriptionContainer.appendChild(descriptionLabel)
      descriptionContainer.appendChild(descriptionTextArea)

      container.appendChild(nameContainer)
      container.appendChild(descriptionContainer)

      if (documentType == "email") {
        subjectTextField.value = currentTemplateLite ? currentTemplateLite.title : ""
        subjectContainer.appendChild(subjectLabel)
        subjectContainer.appendChild(subjectTextField)
        container.appendChild(subjectContainer)
      }

      container.appendChild(saveButton)

      modal.setTitle("Save Template As")
      modal.setContent(container)
      modal.open().getModel()
    },
  }
}
