<template>
  <template-builder 
    :new="isNewDoc"
    :templateId="templateId"
    documentType="form" 
    @change="formBuilderChanged"
  >
  </template-builder>
</template>

<script>
import TemplateBuilder from '@/components/builders/TemplateBuilder.vue'

export default {
  props: {
    isNewDoc: Boolean,
    templateId: String
  },
  /* eslint-disable */
  methods: {
    formBuilderChanged(html) {
      // console.log(html)
    },
  },
  /* eslint-enable */
  components: {
    TemplateBuilder,
  },
}
</script>
